// src/services/CoachAdminService.js

import http from "./httpService";

class CoachAdminService {
  async getAll(params) {
    const url = http.getUrlWithParams("/admin/dashboard/coaches", params);
    return await http.get(url);
  }

  async updateStatus(id, status) {
    return await http.put(`/admin/dashboard/coaches/${id}/update-status`, {status});
  }  

  async delete(id) {
    return await http.delete(`/admin/dashboard/coaches/${id}`);
  }  
}

export default new CoachAdminService();
