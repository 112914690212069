import http from './httpService';

class UserAdminService {
  // Metode yang ada tetap sama
  async getAll(param) {
    const url = http.getUrlWithParams("/admin/users", param);
    return await http.get(url);
  }

  async getById(id) {
    const url = `/admin/users/${id}`;
    return await http.get(url);
  }

  async create(element) {
    const url = "/admin/users";
    return await http.post(url, element, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }

  async update(id, element) {
    const url = `/admin/users/${id}`;
    return await http.put(url, element, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }

  async remove(id) {
    const url = `/admin/users/${id}`;
    return await http.delete(url);
  }

  async inviteTeacher(data) {
    return await http.post('/admin/dashboard/teachers/invite', data);
  }

  async bulkInviteTeachers(formData) {
    return await http.post('/admin/dashboard/teachers/bulk-invite', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': '10cbe6e33'
      }
    });
  }

  // Metode baru untuk membuat akun dari undangan
  async createAccountFromInvitation(data) {
    return await http.post('/auth/create-account-from-invitation', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': '10cbe6e33'
      }
    });
  }
}

export default new UserAdminService();
