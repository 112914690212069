<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator’s Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can’t wait to
        see what you’ll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/teacher-presenting.png" alt="Teacher Presenting">
      <div class="x-overlay-text">
        <h1>Purposeful Maker Mindset</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; width: 80%;">
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="x-content-card">
      <img src="/images/maker-cart-guide/growth-mindset.svg" width="45px" alt="Growth Mindset">
      <h2 class="color-primary margin-1b">Growth Mindset</h2>
      <p class="fs-12 margin-1b">A Maker is someone who believes they can learn to do anything.</p>
      <p class="fs-12 margin-1b">Having a Growth Mindset (as opposed to a fixed mindset), is a self belief and confidence that you can learn whatever you want to learn. When we encourage growth mindset, students view errors as an opportunity to learn and grow instead of being fixated on the ‘failure’. We are more concerned about “how they are learning” (the process) instead of “what they have learnt” (the outcome).</p>
    </div>

    <div class="x-content-card">
      <img src="/images/maker-cart-guide/origami.svg" width="45px" alt="Growth Mindset">
      <h2 class="color-primary margin-1b">Craftsmanship</h2>
      <p class="fs-12 margin-1b">A Maker is someone who has excellent work ethic and <strong>maintains the highest standards</strong> for themselves <strong>even when no one is looking</strong>.</p>
      <p class="fs-12 margin-1b">With a mindset of Craftsmanship, we believe that there are multiple pathways to success and success should be self-defined. When students ask “Is this good enough?”, we turn the question around with “Is this good enough for you?”.</p>
      <p class="fs-12 margin-1b">Having Craftsmanship also means that the students are constantly improving themselves (not because they are competing with other people). This means excellent work ethics, and could be seen through the quality of work and how they take initiative to keep their workstations clean.</p>
    </div>

    <div class="x-content-card">
      <img src="/images/maker-cart-guide/lotus.svg" width="45px" alt="Growth Mindset">
      <h2 class="color-primary margin-1b">Open Sharing</h2>
      <p class="fs-12 margin-1b">A Maker is someone who is willing to share their ideas and build upon the idea of others to achieve collective brilliance.</p>
      <p class="fs-12 margin-1b">We would like to make a distinction between a Maker and a DIY enthusiast. In our definition, a DIY enthusiast most often make for the sake of individualistic pursuit. On the other hand, a Maker is someone who believes in “winning together”.</p>
      <p class="fs-12 margin-1b">To create a conducive environment for open sharing, in the former case, the question is how the teacher step in when they observe “judging”, and how they can encourage everyone to “listen kindly”. In the latter, it is how we teach the students to give credit to the people who have assisted them and how we encourage them to build upon each other’s ideas such that they multiply in value.</p>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; width: 80%;">
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToNextPage = () => {
  router.push('/makercart-guide/2');
};
</script>
