<template>
    <div>
      <Line v-if="props.chartData && props.chartData.labels.length" :data="props.chartData" :options="props.chartOptions" />
      <div v-else>Loading chart...</div>
    </div>
  </template>
  
  <script setup>
  import { Line } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';
  import { defineProps } from 'vue';
  
  ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);
  
  const props = defineProps({
    chartData: {
      type: Object,
      required: true,
      default: () => ({
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [{
          label: 'Dummy Data',
          data: [10, 20, 30, 40, 50],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          fill: true,
          tension: 0.4
        }]
      })
    },
    chartOptions: {
      type: Object,
      required: true,
      default: () => ({
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      })
    }
  });
  </script>
  