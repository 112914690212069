<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-1000006404.png" alt="Rectangle 1000006404">
      <div class="x-overlay-text">
        <h1>Makercart Anatomy</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
   
    <div class="x-container">
      <!-- SECTION 1 -->
      <div class="x-image-text-container">
        <div class="x-image-wrapper">
          <img 
            :src="currentImage" 
            alt="Image" 
            class="x-image"
            :class="{ 'x-image--loading': loading }"
            @load="onImageLoad"
            @error="onImageError"
          >
          <div v-if="loading" class="spinner-container-2">
            <div class="spinner-2"></div>
          </div>
        </div>
      </div>

      <!-- SECTION 2 -->
      <!-- Jika dibawah 1250px maka di hide -->
      <div class="x-text-container hide-below-1250px">
        <div 
          class="x-card x-pink" 
          @click="changeImage('/images/maker-cart-guide/img-2.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-2.png' }"
        >
          <h3>Hooks for Easy Access:</h3>
          <p class="fs-12">There are hooks placed on the rack to hang items such as tape, scissors, and other essentials. This ensures quick and convenient access during maker activities.</p>
          
          <h3>Utilize Magnetic Pegboards:</h3>
          <p class="fs-12">The pegboards are magnetic. It can be used to place useful reminder or safety instructions when conducting maker activity.</p>
        </div>
        <div 
          class="x-card x-blue" 
          @click="changeImage('/images/maker-cart-guide/img-3.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-3.png' }"
        >
          <h3>Organized Item Boxes and Bins:</h3>
          <p class="fs-12">Boxes and bins that are arranged according to item types. Each box is labeled, providing a clear indication of the items stored inside.</p>
        </div>
        <div 
          class="x-card x-green" 
          @click="changeImage('/images/maker-cart-guide/img-4.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-4.png' }"
        >
          <h3>Activity-Specific Boxes:</h3>
          <p class="fs-12">Boxes cater to various activities, customized for your specific needs.</p>
        </div>
        <div 
          class="x-card x-yellow" 
          @click="changeImage('/images/maker-cart-guide/img-5.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-5.png' }"
        >
          <h3>Easy Mobility:</h3>
          <p class="fs-12">With sturdy wheels, the cart is easily movable, enabling convenient transportation across diverse learning spaces and classrooms.</p>
          
          <h3>Wheel Stopper:</h3>
          <p class="fs-12">There is one wheel stopper on the cart, on the right hand wheel same side with the handle bar.</p>
        </div>
      </div>  

      <!-- SECTION 2 -->
      <!-- Jika diatas 1250px maka di hide -->
      <div class="x-text-container hide-above-1250px">
        <div 
          class="x-card x-pink" 
          @click="changeImage('/images/maker-cart-guide/img-2.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-2.png' }"
        >
          <h3>Hooks for Easy Access.</h3>
          
          <h3>Utilize Magnetic Pegboards.</h3>
        </div>
        <div 
          class="x-card x-blue" 
          @click="changeImage('/images/maker-cart-guide/img-3.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-3.png' }"
        >
          <h3>Organized Item Boxes and Bins.</h3>
        </div>
        <div 
          class="x-card x-green" 
          @click="changeImage('/images/maker-cart-guide/img-4.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-4.png' }"
        >
          <h3>Activity-Specific Boxes.</h3>
        </div>
        <div 
          class="x-card x-yellow" 
          @click="changeImage('/images/maker-cart-guide/img-5.png')" 
          :class="{ 'image-markercart-active': currentImage === '/images/maker-cart-guide/img-5.png' }"
        >
          <h3>Easy Mobility.</h3>
          
          <h3>Wheel Stopper.</h3>
        </div>
      </div>  
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();
const currentImage = ref('/images/maker-cart-guide/img-1.png');
const loading = ref(false);
const isActive = ref(false);  // Menambah state untuk melacak aktivitas gambar

const imagePaths = [
  '/images/maker-cart-guide/img-1.png',
  '/images/maker-cart-guide/img-2.png',
  '/images/maker-cart-guide/img-3.png',
  '/images/maker-cart-guide/img-4.png',
  '/images/maker-cart-guide/img-5.png'
];

// Preload images
const preloadImages = () => {
  imagePaths.forEach((path) => {
    const img = new Image();
    img.src = path;
  });
};

onMounted(() => {
  preloadImages();
  adjustSectionHeight();
  window.addEventListener('resize', adjustSectionHeight);
  document.addEventListener('click', handleClickOutside);  // Menambah event listener untuk klik di luar
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustSectionHeight);
  document.removeEventListener('click', handleClickOutside);  // Membersihkan event listener saat komponen tidak digunakan
});

const adjustSectionHeight = () => {
  const section1 = document.querySelector('.x-image-text-container');
  const section2Above1250px = document.querySelector('.x-text-container.hide-below-1250px');
  const section2Below1250px = document.querySelector('.x-text-container.hide-above-1250px');

  let section2Height = 0;

  if (window.innerWidth >= 1250 && section2Above1250px) {
    section2Height = section2Above1250px.offsetHeight;
  } else if (window.innerWidth < 1250 && section2Below1250px) {
    section2Height = section2Below1250px.offsetHeight;
  }

  if (section1 && section2Height > 0) {
    section1.style.height = `${section2Height}px`;
  }

  const wrapperElement = document.querySelector('.x-image-wrapper');
  const imageElement = document.querySelector('.x-image');
  const wrapperWidth = wrapperElement.clientWidth;
  const imageNaturalWidth = imageElement.naturalWidth;

  if (imageNaturalWidth > wrapperWidth) {
    section1.style.height = `auto`;
  }
};

const changeImage = (newImage) => {
  if (currentImage.value === newImage) {
    // Jika gambar saat ini sama dengan gambar yang baru di-klik, reset ke gambar awal
    resetImage();
  } else {
    loading.value = true;
    isActive.value = true;  // Mengatur gambar ke state aktif
    
    // Load the new image
    const img = new Image();
    img.src = newImage;

    img.onload = () => {
      currentImage.value = newImage;
      loading.value = false;
    };

    img.onerror = () => {
      console.error('Gambar gagal dimuat.');
      loading.value = false;
    };
  }
};

const resetImage = () => {
  if (isActive.value) {
    currentImage.value = '/images/maker-cart-guide/img-1.png';
    isActive.value = false;  // Mengatur state aktif ke false
  }
};

const handleClickOutside = (event) => {
  if (!event.target.closest('.x-card') && isActive.value) {  
    resetImage();  // Mengatur gambar ke state awal jika diklik di luar elemen yang aktif
  }
};

const onImageLoad = () => {
  loading.value = false;
};

const onImageError = () => {
  loading.value = false;
};

const goToPreviousPage = () => {
  router.push('/makercart-guide/4');
};

const goToNextPage = () => {
  router.push('/makercart-guide/6');
};
</script>

<style>
.x-container {
  display: flex;
  align-items: stretch; /* Membuat elemen anak memiliki tinggi yang sama */
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
}

.x-image-text-container {
  flex: 1 1 45%;
  margin: 10px;
}

.x-text-container {
  flex: 1 1 45%;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.x-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover; /* Agar gambar tidak terdistorsi */
}

.x-image--loading {
  opacity: 0.5;
}

.spinner-container-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-2 {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #003D6A;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.x-text-container {
  flex: 1 1 45%;
  margin: 10px;
}

.x-card {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.image-markercart-active {
  background-color: #F2F3FB;
}

.x-pink {
  color: #E00069;
}

.x-blue {
  color: #4C4DDC;
}

.x-green {
  color: #00925E;
}

.x-yellow {
  color: #D29538;
}

@media (max-width: 540px) {
    .hero-section-3 {
        height: 500px;
        padding: 0.5em;
    }

    .responsive-image-cd {
        height: 250px;
    }

    .white-backdrop {
        font-size: 4em;
    }
}

/* Sembunyikan elemen jika di bawah 1250px */
@media (max-width: 1250px) {
  .hide-below-1250px {
    display: none;
  }
}

/* Sembunyikan elemen jika di atas 1250px */
@media (min-width: 1250px) {
  .hide-above-1250px {
    display: none;
  }
}

.x-image-wrapper {
  height: 100%; /* Membuat wrapper gambar mengikuti tinggi elemen .x-text-container */
  display: flex;
  align-items: center; /* Mengatur gambar agar selalu berada di tengah secara vertikal */
}
</style>