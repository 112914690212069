import http from './httpService';

class ChangeLogService {
  async getChangeLog() {
    return await http.get('/admin/change-log');
  }
  
  async updateChangeLog(data) {
    return await http.post('/admin/change-log', data);
  }

  async getChangeLogEnableStatus() {
    return await http.get('/admin/change-log/alert');
  }
  
  async toggleChangeLogEnableStatus() {
    return await http.get('/admin/change-log/alert/toggle');
  }  
}

export default new ChangeLogService();
