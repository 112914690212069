<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Changelog</h1>
    </div>
    <div class="border-container-unique37">
      <form @submit.prevent="validateForm" novalidate>
        <div class="admin-form-group mb-3">
          <label>Version</label>
          <input type="text" v-model="form.version" required />
          <span v-if="errors.version" class="error-message-123">{{ errors.version }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Content</label>
          <div class="input-container-333">
            <EditorContent :editor="editor" />
          </div>
          <div class="d-flex justify-start">
            <img 
              height="25px" 
              class="button-tiptap mt-2" 
              src="/images/tiptap-bar/unordered-list.svg" 
              alt="Bullet List" 
              @click="addBulletList"
            >
          </div>
          <span v-if="errors.content" class="error-message-123">{{ errors.content }}</span>
        </div>
        <div class="flex justify-content-end">
          <button type="submit" class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }">Save</button>
          <button type="button" @click="toggleChangeLogEnableStatus()" style="margin-left: 10px;" class="btn btn-save" :class="changeLogEnabled ? 'btn-discard' : 'btn-save' " >Turn {{ changeLogEnabled ? 'Off' : 'On' }} Changelog Pop Up</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import ChangeLogService from '@/services/changeLogService';

const router = useRouter();

const form = reactive({
  version: '',
  content: ''
});

const errors = reactive({
  version: '',
  content: ''
});

const isLoading = ref(false);
const isLoadingChangeLogEnableStatus = ref(false);
const changeLogEnabled = ref(false);

const editor = ref(null);

onMounted(() => {
  editor.value = new Editor({
    extensions: [StarterKit],
    content: '<p>Disini ada tiptap editor</p>', // Initial content for the editor
    onUpdate({ editor }) {
      form.content = editor.getHTML();
    }
  });
  fetch();
  getChangeLogEnableStatus();
});

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy();
  }
});

const fetch = async () => {
  isLoading.value = true;
  try {
    const response = await ChangeLogService.getChangeLog();
    if (response.status === 200) {
      const category = response.data.data;
      form.version = category.version;
      form.content = category.content;
      editor.value.commands.setContent(category.content);
    }
  } catch (error) {
    console.error(error.message);
    alert('Failed to fetch category data');
  } finally {
    isLoading.value = false;
  }
};

const getChangeLogEnableStatus = async () => {
  isLoadingChangeLogEnableStatus.value = true;
  try {
    const response = await ChangeLogService.getChangeLogEnableStatus();
    if (response.status === 200) {
      changeLogEnabled.value = response.data.data.isChangeLogAlertOn;
    }
  } catch (error) {
    alert('Failed to fetch Changelog Status data' + error);
  } finally {
    isLoadingChangeLogEnableStatus.value = false;
  }
};

const toggleChangeLogEnableStatus = async () => {
  isLoadingChangeLogEnableStatus.value = true;
  try {
    const response = await ChangeLogService.toggleChangeLogEnableStatus();
    if (response.status === 200) {
      changeLogEnabled.value = response.data.data.value == 1 ? true : false;
    }
  } catch (error) {
    alert('Failed to fetch Changelog Status data' + error);
  } finally {
    isLoadingChangeLogEnableStatus.value = false;
  }
};

const validateForm = async () => {
  // Reset errors
  Object.keys(errors).forEach(key => {
    errors[key] = '';
  });

  let isValid = true;

  // Validate required fields
  if (!form.version) {
    errors.version = 'Version is required';
    isValid = false;
  }
  if (!form.content) {
    errors.content = 'Content is required';
    isValid = false;
  }

  if (!isValid) {
    return;
  }

  isLoading.value = true;

  try {
    console.log('form', form);
    await ChangeLogService.updateChangeLog(form);
    alert('Change Log updated successfully!');
    router.push('/admin/change-log');
  } catch (error) {
    console.error(error.message);
    alert('Failed to submit form!');
  } finally {
    isLoading.value = false;
  }
};

const addBulletList = () => {
  if (editor.value) {
    editor.value.chain().focus().toggleBulletList().run();
  }
};
</script>

<style scoped>
.input-container-333 {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    width: 100%;
  }
</style>
