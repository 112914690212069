<template>
  <div class="form-group">
    <input
      type="text"
      v-model="searchQuery.title"
      @focus="showOptions = true"
      @blur="hideOptions"
      class="form-control"
      placeholder="Search..."
    />
    <ul v-if="showOptions" class="list-group">
      <li
        v-for="item in filteredItems"
        :key="item.id"
        class="list-group-item"
        @mousedown.prevent="selectItem(item)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, watch } from 'vue';

// Define the props
const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  modelValue: {
    type: [String, Number],
    default: ''
  }
});

// Define the emit function
const emit = defineEmits(['update:modelValue']);

const searchQuery = ref({ id: '', title: '' });
const showOptions = ref(false);

const filteredItems = computed(() => {
  return props.items.filter(item => item.title.toLowerCase().includes(searchQuery.value.title.toLowerCase()));
});

const hideOptions = () => {
  setTimeout(() => {
    showOptions.value = false;
  }, 200); // Delay to allow click event to register
};

const selectItem = (item) => {
  searchQuery.value = { id: item.id, title: item.title };
  showOptions.value = false;
  emit('update:modelValue', item.id); // Emit event to parent component
};

// Watch for changes in the modelValue prop and update searchQuery accordingly
watch(() => props.modelValue, (newValue) => {
  const selectedItem = props.items.find(item => item.id === newValue);
  if (selectedItem) {
    searchQuery.value = { id: selectedItem.id, title: selectedItem.title };
  } else {
    searchQuery.value = { id: '', title: '' };
  }
});
</script>

<style scoped>
.form-group {
  position: relative;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.list-group {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.list-group-item {
  padding: 8px;
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #f1f1f1;
}
</style>
