<!-- FloatingMenu.vue -->
<template>
  <div ref="floatingButton" class="floating-menu" @click="toggleSidebar">
    <img src="/images/maker-cart-guide/frame-1000006464.svg" height="85px" alt="Floating menu">
  </div>
  <div v-if="sidebarVisible" class="x-overlay" @click="toggleSidebar"></div>
  <div v-if="sidebarVisible" class="sidebar-menu">
    <div class="sidebar-content">
      <button class="close-btn" @click="toggleSidebar">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="25px" alt="MakerCart Educator's Guide Header">
      </button>
      <h2 style="color: #00925E;">Contents</h2>
      <div style="border-bottom: 1px solid rgb(224, 224, 224);margin-top: 10px;"></div>
      <ul>
        <li><a href="/makercart-guide/1" :class="{ menuActive: currentUrl === '/makercart-guide/1' }">Purposeful Maker Mindset</a></li>
        <li><a href="/makercart-guide/2" :class="{ menuActive: currentUrl === '/makercart-guide/2' }">Maker Approach</a></li>
        <li><a href="/makercart-guide/3" :class="{ menuActive: currentUrl === '/makercart-guide/3' }">About the Maker Cart</a></li>
        <li><a href="/makercart-guide/4" :class="{ menuActive: currentUrl === '/makercart-guide/4' }">Instructional Materials</a></li>
        <li><a href="/makercart-guide/5" :class="{ menuActive: currentUrl === '/makercart-guide/5' }">Maker Cart Anatomy</a></li>
        <li>
          <a href="/makercart-guide/6" :class="{ menuActive: currentUrl.startsWith('/makercart-guide/6') }">Conducting a Maker Activity</a>
          <ul>
            <li><a href="/makercart-guide/7" :class="{ menuActive: currentUrl === '/makercart-guide/7' }">Role 1: Designing Suitable Activities</a></li>
            <li><a href="/makercart-guide/8" :class="{ menuActive: currentUrl === '/makercart-guide/8' }">Role 2: Posing Questions</a></li>
            <li><a href="/makercart-guide/9" :class="{ menuActive: currentUrl === '/makercart-guide/9' }">Role 3: Use Appropriate Language</a></li>
          </ul>
        </li>
        <li><a href="/makercart-guide/10" :class="{ menuActive: currentUrl === '/makercart-guide/10' }">Facilitating a Maker Activity</a></li>
        <li><a href="/makercart-guide/11" :class="{ menuActive: currentUrl === '/makercart-guide/11' }">Material Management</a></li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

const sidebarVisible = ref(false);
const floatingButton = ref(null);
const route = useRoute();
const currentUrl = ref(route.path);

const toggleSidebar = () => {
  sidebarVisible.value = !sidebarVisible.value;
};

onMounted(() => {
  const updatePosition = () => {
    const overlaySection = document.querySelector('.x-image-overlay-section');
    if (overlaySection) {
      const rect = overlaySection.getBoundingClientRect();
      const yOffset = window.pageYOffset || document.documentElement.scrollTop;
      const yPosition = rect.top + yOffset + overlaySection.offsetHeight;
      floatingButton.value.style.top = `${yPosition + 5}px`;
    }
  };

  updatePosition(); // Initial position update
  const interval = setInterval(updatePosition, 1000); // Update every second

  onUnmounted(() => {
    clearInterval(interval); // Clear the interval when the component is unmounted
  });
});
</script>

<style scoped>
.floating-menu {
  position: absolute;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
}

.x-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  max-height: 100%;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  border-radius: 0px 20px 20px 0px;
}

.sidebar-content {
  padding: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  float: right;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu ul li {
  margin: 15px 0;
}

.sidebar-menu ul li a {
  text-decoration: none;
  color: #003D6A;
  font-size: 16px;
  display: block;
  padding-left: 10px;
  border-left: 3px solid transparent;
}

.sidebar-menu ul li a:hover,
.sidebar-menu ul li a.menuActive {
  border-left: 3px solid #003D6A;
  font-weight: 600;
}

.sidebar-menu ul ul {
  list-style: none;
  padding-left: 20px;
}

.sidebar-menu ul ul li {
  margin: 10px 0;
}

.sidebar-menu ul ul li a {
  font-size: 14px;
  color: #003D6A;
  padding-left: 10px;
  border-left: 3px solid transparent;
}

.sidebar-menu ul ul li a:hover,
.sidebar-menu ul ul li a.menuActive {
  border-left: 3px solid #003D6A;
}
</style>
