import http from "./httpService";

class StemStudioService {

  async getAll(param) {
    const url = http.getUrlWithParams('stem-studio/makercart', param);
    return await http.get(url);
  }

  filterMakercarts(page, limit, sortBy, searchQuery, filterParams, data) {
    // Retrieve makercarts data
    let filteredData = data;

    // Add search filter (searchQuery)
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.makercart_activity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.activity_description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Add filter based on grades
    if (filterParams.grades.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.grades.some(grade => item.grade_level.includes(grade))
      );
    }

    // Add filter based on hardware
    if (filterParams.hardware.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.hardware.some(hw => item.hardware_integration && item.hardware_integration.includes(hw))
      );
    }

    // Add filter based on prototypingMaterials
    if (filterParams.prototypingMaterials.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.prototypingMaterials.some(pm => item.prototyping_material_integration && item.prototyping_material_integration.includes(pm))
      );
    }

    // Add filter based on subjects
    if (filterParams.subjects.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.subjects.some(subject => item.subject.includes(subject))
      );
    }

    // Sort data based on sortBy
    if (sortBy === "newest") {
      filteredData.sort((a, b) => b.id - a.id);
    } else if (sortBy === "oldest") {
      filteredData.sort((a, b) => a.id - b.id);
    }

    // Calculate total pages
    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / limit);

    // Set pagination
    const start = (page - 1) * limit;
    const end = page * limit;
    const paginatedData = filteredData.slice(start, end);

    // Map data to add 'image' property
    const mappedData = paginatedData.map(item => ({
      ...item,
      image: item.image_thumbnail && item.image_thumbnail.length > 0 ? item.image_thumbnail[0] : ''
    }));

    return { filteredData: mappedData, totalPages, totalRows };
  }
  
  async getById(id) {
    return await http.get(`/stem-studio/makercart/${id}`);
  }

  async getByIdMakerCartID(id, number) {
    return await http.get(`/stem-studio/my-resources/${id}?order_id=${number}`);
  }

  async post(data) {
    try {
      const response = await http.post("/stem-studio/orders", data);
      return response;
    } catch (error) {
      if (error.response.status === 404) {
        return error.response;
      }
      throw error;
    }
  }

  async getMaterialById(id) {
    return await http.get(`/stem-studio/material-needed/${id}`);
  }

  async getMaterialByIdBulk(ids) {
    return await http.get(`/stem-studio/material-needed/bulk?ids=${ids}`);
  }
  
  async likeMakercart(id) {
    return await http.get(`/stem-studio/makercart/${id}/like`);
  }

  async getMakercartOrders(params = {}) {
    const url = http.getUrlWithParams('/stem-studio/my-resources', params);
    return await http.get(url);
  }
  
  async checkEducatorsGuide() {
    try {
      const response = await http.get(`/stem-studio/check-educators-guide`);
      return response;
    } catch (error) {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        console.log('Access forbidden: 403 error');
        return null;
      }
      throw error;
    }
  }

  async getMakerCartFilters() {
    return await http.get(`/stem-studio/makercart/filters`);
  }
}

export default new StemStudioService();
