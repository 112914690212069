<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Upload SCORM Package</h1>
    </div>
    <div class="border-container-unique37">
      <form @submit.prevent="validateForm" novalidate enctype="multipart/form-data">
        <div class="admin-form-group mb-3">
          <textarea v-model="form.title" class="title-input" placeholder="Title..." disabled></textarea>
          <span v-if="errors.title" class="error-message-123">{{ errors.title }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label for="file" class="custom-file-upload">Choose File</label>
          <input type="file" id="file" ref="fileInput" @change="handleFileChange" accept="application/zip" required />
          <button type="button" class="btn btn-red-zzz" v-if="isFileSelected" @click="removeFile">Remove File</button>
          <span v-if="errors.file" class="error-message-123">{{ errors.file }}</span>
        </div>
        <div class="flex justify-content-end">
          <button type="submit" class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }">Save</button>
        </div>
        <div v-if="msg" class="bg-green mb-[4.6rem] mt-10 text-white border-radius p-1 w-fit-content">
          {{ msg }}
        </div>
        <div v-if="nullFields.length" class="msg-wrapper mb-[4.6rem]">
          <div v-for="field in nullFields" :key="field" class="admin-message admin-message bg-pink mt-10 text-white border-radius p-1 w-fit-content">
            Warning: <strong>{{ field }}</strong> is empty
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import ScormFilesService from '@/services/scormFilesService';

const router = useRouter();

const form = reactive({
  title: '',
  file: null
});

const errors = reactive({
  title: '',
  file: ''
});

const msg = ref('');
const nullFields = ref([]);
const fileName = ref('');
const isFileSelected = ref(false);
const fileInput = ref(null);
const isLoading = ref(false);

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    form.file = file;
    form.title = file.name;
    fileName.value = file.name;
    isFileSelected.value = true;
  } else {
    form.file = null;
    form.title = '';
    fileName.value = '';
    isFileSelected.value = false;
  }
};

const removeFile = () => {
  form.file = null;
  form.title = '';
  fileName.value = '';
  isFileSelected.value = false;
  fileInput.value.value = '';
};

const validateForm = async () => {
  // Reset errors and messages
  Object.keys(errors).forEach(key => {
    errors[key] = '';
  });
  msg.value = '';
  nullFields.value = [];

  let isValid = true;

  // Validate required fields
  if (!form.title) {
    errors.title = 'Title is required';
    nullFields.value.push('Title');
    isValid = false;
  }
  if (!form.file) {
    errors.file = 'File is required';
    nullFields.value.push('File');
    isValid = false;
  }

  if (!isValid) {
    return;
  }

  isLoading.value = true;

  try {
    const response = await ScormFilesService.upload(form.file); // Assume you have a service for handling SCORM upload
    if (response.status === 200) {
      alert('SCORM package uploaded successfully!');
      router.push('/admin/scorm');  
    }
  } catch (error) {
    console.error(error.message);
    alert('Failed to upload SCORM package!');
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  margin-top: 10px;
  font-size: 25px;
}

input[type="file"] {
  display: none;
}

.btn-red-zzz {
  background-color: #E00069;
  color: #FFFFFF;
  border: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  font-size: 25px;
}

.title-input {
  font-size: 48px;
  font-weight: 550;
  border: none;
  padding: 0;
  resize: none;
  overflow: auto;
  width: 100%;
  min-height: 1.2em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.error-message-123 {
  color: red;
}
</style>
