<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>{{ isEditMode ? 'Edit User' : 'Add User' }}</h1>
    </div>
    <div class="border-container-unique37">
      <form class="admin-form-group-container" @submit.prevent="validateForm" novalidate>
        <div class="admin-form-group">
          <label>User Group</label>
          <div class="custom-select">
            <select v-model="form.user_group_id" required>
              <option value="" disabled>Select User Group</option>
              <option value="1">Admin</option>
              <option value="2">Teacher</option>
              <option value="5">Student</option>
              <!-- Add other user groups as needed -->
            </select>
            <img src="/images/arrow-bottom.svg" class="select-icon" />
          </div>
          <span v-if="errors.user_group_id" class="error-message-123">{{ errors.user_group_id }}</span>
        </div>
        <div class="admin-form-group">
          <label>First Name</label>
          <input type="text" v-model="form.firstname" required />
          <span v-if="errors.firstname" class="error-message-123">{{ errors.firstname }}</span>
        </div>
        <div class="admin-form-group">
          <label>Last Name</label>
          <input type="text" v-model="form.lastname" required />
          <span v-if="errors.lastname" class="error-message-123">{{ errors.lastname }}</span>
        </div>
        <div class="admin-form-group">
          <label>Email</label>
          <input type="email" v-model="form.email" @change="updateEmail" required />
          <span v-if="errors.email" class="error-message-123">{{ errors.email }}</span>
        </div>
        <div class="admin-form-group">
          <label>Password</label>
          <div class="password-container">
            <input :type="showPassword ? 'text' : 'password'" v-model="form.password" @input="validatePassword" :required="!isEditMode" />
            <button type="button" @click="toggleShowPassword">{{ showPassword ? 'Hide' : 'Show' }}</button>
          </div>
          <div v-if="errors.password.length > 0" class="error-message-123">
            <p v-for="error in errors.password" :key="error">{{ error }}</p>
          </div>
        </div>
        <div class="admin-form-group">
          <label>Confirm Password</label>
          <div class="password-container">
            <input :type="showConfirmPassword ? 'text' : 'password'" v-model="form.confirmPassword" @input="validatePassword" :required="isConfirmPasswordRequired" />
            <button type="button" @click="toggleShowConfirmPassword">{{ showConfirmPassword ? 'Hide' : 'Show' }}</button>
          </div>
          <span v-if="errors.confirmPassword" class="error-message-123">{{ errors.confirmPassword }}</span>
        </div>
        <div class="admin-form-group">
          <label>Status</label>
          <div class="custom-select">
            <select v-model="form.status" required>
              <option value="" disabled>Select Status</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <img src="/images/arrow-bottom.svg" class="select-icon" />
          </div>
          <span v-if="errors.status" class="error-message-123">{{ errors.status }}</span>
        </div>
        <div class="admin-form-group">
          <label>Approved</label>
          <div class="custom-select">
            <select v-model="form.approved" required>
              <option value="" disabled>Select Approval Status</option>
              <option value="1">Approve</option>
              <option value="0">Not Approved</option>
            </select>
            <img src="/images/arrow-bottom.svg" class="select-icon" />
          </div>
          <span v-if="errors.approved" class="error-message-123">{{ errors.approved }}</span>
        </div>
        <div class="admin-form-group">
          <label>LinkedIn URL</label>
          <input type="text" v-model="form.linkedin_url" />
          <span v-if="errors.linkedin_url" class="error-message-123">{{ errors.linkedin_url }}</span>
        </div>
        <div class="admin-form-group">
          <label>School</label>
          <div class="custom-select">
            <select v-model="form.school">
              <option value="" disabled>Select School</option>
              <option v-for="school in schoolOptions" :key="school.id" :value="school.id">{{ school.title }}</option>
            </select>
            <img src="/images/arrow-bottom.svg" class="select-icon" />
          </div>
          <span v-if="errors.school" class="error-message-123">{{ errors.school }}</span>
        </div>
        <div class="admin-form-group">
          <label>Image</label>
          <input type="file" @change="handleFileUpload" />
          <span v-if="errors.image" class="error-message-123">{{ errors.image }}</span>
          <div v-if="isEditMode && form.imageUrl & form.imageUrl!==''" class="current-image-xx">
            <p>Current Image:</p>
            <img :src="form.imageUrl" alt="Current Image" class="image-preview-xx" />
          </div>
        </div>
        <button type="button" class="auto-fill-btn" @click="autoFillForm">Auto Fill</button>
        <button type="submit" class="submit-btn">SUBMIT</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import UserAdminService from '@/services/userAdminService';
import TermAdminService from '@/services/termAdminService';

const router = useRouter();
const route = useRoute();

const form = reactive({
  user_group_id: '',
  username: '',
  firstname: '',
  lastname: '',
  country: '',
  city: '',
  school: '',
  timezone: '',
  email: '',
  password: '',
  confirmPassword: '',
  status: '',
  approved: '',
  subscribe: '',
  linkedin_url: '',
  image: null, // Use null initially for image
  imageUrl: '', // For displaying current image in edit mode
  referal_token: '',
  has_password: 0 // Set default value for has_password
});

const errors = reactive({
  user_group_id: '',
  username: '',
  firstname: '',
  lastname: '',
  country: '',
  city: '',
  school: '',
  timezone: '',
  email: '',
  password: [],
  confirmPassword: '',
  status: '',
  approved: '',
  subscribe: '',
  linkedin_url: '',
  image: '',
  referal_token: '',
  has_password: ''
});

const isEditMode = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const schoolOptions = ref([]);

const fetchUser = async (id) => {
  try {
    const response = await UserAdminService.getById(id);
    if (response.status === 200) {
      Object.assign(form, response.data.data);
      form.imageUrl = response.data.data.image ? `${response.data.data.image}` : ''; // Set imageUrl for preview
    }
  } catch (error) {
    console.error(error.message);
    alert('Failed to fetch user data');
  }
};

const getSchool = async () => {
  try {
    const response = await TermAdminService.getAllSchool();
    if (response.status === 200) {
      schoolOptions.value = response.data.data;
    }
  } catch (error) {
    console.error(error.message);
    alert('Failed to fetch school data');
  }
};

onMounted(() => {
  if (route.params.id) {
    isEditMode.value = true;
    fetchUser(route.params.id);
  }
  getSchool();
});

const updateEmail = () => {
  form.username = form.email;
};

const isConfirmPasswordRequired = computed(() => {
  return isEditMode.value && form.password !== '';
});

const validatePassword = () => {
  errors.password = [];
  const password = form.password;
  if (!/[a-zA-Z]/.test(password)) errors.password.push('Password must contain at least one letter');
  if (!/[A-Z]/.test(password)) errors.password.push('Password must contain at least one capital letter');
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.password.push('Password must contain at least one special character');
  if (password.length < 4 || password.length > 20) errors.password.push('Password must be between 4 and 20 characters');
  
  if (isEditMode.value && password && form.password !== form.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  } else {
    errors.confirmPassword = '';
  }
};

const validateForm = async () => {
  // Reset errors
  Object.keys(errors).forEach(key => {
    if (Array.isArray(errors[key])) {
      errors[key] = [];
    } else {
      errors[key] = '';
    }
  });

  let isValid = true;

  // Validate required fields
  const requiredFields = ['user_group_id', 'firstname', 'email'];
  for (const key of requiredFields) {
    if (!form[key]) {
      errors[key] = `${key} is required`;
      isValid = false;
    }
  }

  // Validate status and approved fields
  if (form.status === '' || form.status === null || form.status === undefined) {
    errors.status = 'Status is required';
    isValid = false;
  }

  if (form.approved === '' || form.approved === null || form.approved === undefined) {
    errors.approved = 'Approved is required';
    isValid = false;
  }

  if (isConfirmPasswordRequired.value) {
    validatePassword();
    if (errors.password.length > 0) {
      isValid = false;
    }

    if (form.password !== form.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }
  }

  if (!isValid) {
    alert('Please fill in all required fields.');
    return;
  }

  try {
    const formData = new FormData();
    for (const key in form) {
      if (form[key] !== null && form[key] !== undefined) {
        formData.append(key, form[key]);
      }
    }
    if (isEditMode.value) {
      await UserAdminService.update(route.params.id, formData);
      alert('User updated successfully!');
    } else {
      await UserAdminService.create(formData);
      alert('User created successfully!');
    }
    router.push('/admin/users');
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message) {
      alert(`Failed to submit form: ${error.response.data.message}`);
    } else {
      alert('Failed to submit form due to an unknown error!');
    }
  }
};

const handleFileUpload = (event) => {
  form.image = event.target.files[0]; // Set the uploaded file object as the image value
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const toggleShowConfirmPassword = () => {
  showConfirmPassword.value = !showConfirmPassword.value;
};

const autoFillForm = () => {
  form.user_group_id = 2;
  const email = 'john.doe@example.com';
  form.email = email;
  form.username = email;
  form.salt = '123456789';
  form.firstname = 'John';
  form.lastname = 'Doe';
  form.country = 'USA';
  form.city = 'New York';
  form.school = 1;
  form.timezone = 'America/New_York';
  form.password = 'Password123!';
  form.confirmPassword = 'Password123!';
  form.status = 1;
  form.approved = 1;
  form.subscribe = 1;
  form.linkedin_url = 'https://linkedin.com/in/johndoe';
  form.referal_token = 'token123';
  form.has_password = 0; // Ensure has_password is set
};
</script>

<style scoped>
.current-image-xx {
  margin-top: 10px;
}
.image-preview-xx {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
}
.password-container {
  display: flex;
  align-items: center;
}
.password-container input {
  flex: 1;
}
.password-container button {
  margin-left: 10px;
}
.error-message-123 p {
  margin: 0;
}
</style>
