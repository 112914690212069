<template>
  <div>
    <div class="flex-container">
      <div class="flex-item" style="padding: 0">
        <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3">
          <div class="label-cover" style="text-align: center;">
            <div class="blue-backdrop">Makercart Studio</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-container-maker-cart">
      <div class="search-bar-maker-cart">
        <span class="search-icon">
          <img src="/images/maker-cart/union.svg" alt="Search icon" />
        </span>
        <input
          class="input-main"
          type="text"
          v-model="searchQuery"
          @input="debouncedSearch"
          placeholder="Topic, hands-on activity, or any idea that you have for a Makercart activity!"
        />
        <span
          v-if="searchQuery"
          class="remove-icon"
          @click="clearSearch"
        >
          <img src="/images/close.svg" alt="Clear search" />
        </span>
      </div>
    </div>

    <!-- Use the Filter Component here -->
    <FilterComponent  
      :selectedFilters="selectedFilters" 
      @sortChanged="sortChanged" 
      @filtersChanged="filtersChanged" 
      @updateVisibilityModalUserCart="updateVisibilityModalUserCart" 
    />

    <div class="content-container-maker-cart">
      <main style="width: 100%">
        <div v-if="isLoading" class="loading-state">
          <div class="spinner-container">
            <div class="mt-5 spinner"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="error" class="error-message">
            <div class="text-center py-5">
              <div class="text-center color-red text-medium-bold">{{ error }}</div>
              <div class="text-center color-red" style="font-size: 0.8em;">Please try again later.</div>
            </div>
          </div>
          <div v-else class="item-list-maker-cart">
            <div v-if="items.length === 0" class="no-items-message">
              <div class="text-center py-5">
                <img src="/images/maker-cart/not-yet-blue.svg" alt="No Results" class="responsive-image-submission-list">
                <div class="text-center color-primary text-medium-bold">Oh no! We didn't find any results for {{ searchQuery }}.</div>
                <div class="text-center color-primary" style="font-size: 0.8em;">Try again by removing the filter and see what's the results are.</div>
              </div>
            </div>
            <div v-else class="item-grid-container">
              <ItemMakerCart 
                v-for="item in items" 
                :key="item.makercart_id" 
                :item="item" 
                :isLogged="isUserLogged"
                @update-liked-status="updateLikedStatus" 
                @item-clicked="updateVisibilityModalMakerCartDetail(true, item.makercart_id)" 
              />
            </div>
          </div>
        </div>
      </main>
    </div>

    <div v-if="totalPages > 0" class="pagination-controls mb-5">
      <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="changePage(currentPage - 1)">
      
      <!-- Loop hanya menampilkan halaman dalam range visiblePages -->
      <button v-for="page in visiblePages" :key="page" :class="{ 'page-active': page === currentPage }" @click="changePage(page)">
        {{ page }}
      </button>

      <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="changePage(currentPage + 1)">
    </div>

    <MakerCartDetail ref="makerCartDetail" type="detail"></MakerCartDetail>
    <UserCart 
      ref="userCart" 
      @update:confirmation="updateVisibilityModalConfirmation" 
      @refreshCart="updateCartCount"
      @triggerUpdateVisibilityModalSuccess="updateVisibilityModalSuccess"
      @triggerUpdateVisibilityModalConfirmation="updateVisibilityModalConfirmation"
    ></UserCart>
    <ConfirmationModal ref="confirmationModal" @confirm="handleConfirm" @cancel="handleCancel"></ConfirmationModal>
    <SuccessModal ref="successModal"></SuccessModal>
    <LoginModal ref="loginModal"></LoginModal>
    <MinimumRequestModal ref="minimumRequestModal"></MinimumRequestModal>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import ItemMakerCart from '@/components/maker-cart/ItemMakerCart.vue';
import MakerCartDetail from '@/components/maker-cart/MakerCartDetail.vue';
import UserCart from '@/components/maker-cart/UserCart.vue';
import ConfirmationModal from '@/components/maker-cart/ConfirmationModal.vue';
import MinimumRequestModal from '@/components/maker-cart/MinimumRequestModal.vue';
import SuccessModal from '@/components/maker-cart/SuccessModal.vue';
import LoginModal from '@/components/maker-cart/LoginModal.vue';
import StemStudioService from '@/services/stemStudioService';
import debounce from 'lodash.debounce';
import cookiesService from '@/services/cookiesService';
import FilterComponent from '@/views/MakerCart/FilterComponent.vue';

const searchQuery = ref('');
const selectedFilters = ref([]);
const selectedSort = ref('newest');
const items = ref([]);
const activeItems = ref([]);
const isLoading = ref(true);
const isUserLogged = ref(false);
const error = ref(null);

const makerCartDetail = ref(null); 
const userCart = ref(null);
const confirmationModal = ref(null);
const successModal = ref(null);
const loginModal = ref(null);
const minimumRequestModal = ref(null);

const currentPage = ref(1);
const totalItems = ref(0);
const totalPages = ref(0);

const cartCount = ref(0);

const maxVisiblePages = 5; // Menentukan jumlah maksimal halaman yang akan ditampilkan

const visiblePages = computed(() => {
  const half = Math.floor(maxVisiblePages / 2);
  let start = Math.max(currentPage.value - half, 1);
  let end = start + maxVisiblePages - 1;

  if (end > totalPages.value) {
    end = totalPages.value;
    start = Math.max(end - maxVisiblePages + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

function changePage(page) {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
}

const performSearch = () => {
  currentPage.value = 1;
  getAll({ search: searchQuery.value });
};

const debouncedSearch = debounce(performSearch, 500);

const getAll = async (params = {}) => {
  try {
    isLoading.value = true;
    error.value = null;
    const filters = {};

    if (activeItems.value.grades) {
      filters.grades = activeItems.value.grades.map(item => item.title);
    }

    if (activeItems.value.hardwareAssociated) {
      filters.hardwareAssociated = activeItems.value.hardwareAssociated.map(item => item.title);
    }

    if (activeItems.value.tags) {
      filters.tags = activeItems.value.tags.map(item => item.title);
    }

    if (activeItems.value.prototypingMaterials) {
      filters.prototypingMaterials = activeItems.value.prototypingMaterials.map(item => item.title);
    }

    if (activeItems.value.subjects) {
      filters.subjects = activeItems.value.subjects.map(item => item.title);
    }

    if (activeItems.value.tags) {
      filters.tags = activeItems.value.tags.map(item => item.title);
    }

    if (activeItems.value.focus) {
      filters.focus = activeItems.value.focus.map(item => item.title);
    }

    let sortParam;

    if (selectedSort.value === 'newest') {
      sortParam = {
        sort_dir: 'asc',
        sort_field: 'Created'
      };
    } else if (selectedSort.value === 'oldest') {
      sortParam = {
        sort_dir: 'desc',
        sort_field: 'Created'
      };
    } else if (selectedSort.value === 'a-z') {
      sortParam = {
        sort_dir: 'asc',
        sort_field: 'Makercart Activity'
      };
    } else if (selectedSort.value === 'z-a') {
      sortParam = {
        sort_dir: 'desc',
        sort_field: 'Makercart Activity'
      };
    }

    const response = await StemStudioService.getAll({
      page: currentPage.value,
      limit: 9,
      ...sortParam,
      ...filters,
      ...params
    });

    if (response.status === 200) {
      items.value = response.data.data.rows.map(item => ({
        ...item,
        image: item.image_thumbnail && item.image_thumbnail.length > 0 ? item.image_thumbnail[0] : ''
      }));

      totalItems.value = response.data.data.total_rows;
      totalPages.value = response.data.data.total_pages;
    }
  } catch (err) {
    console.log(err.message);
    error.value = 'Failed to fetch items. Please try again later.';
  } finally {
    isLoading.value = false;
  }
};

watch([currentPage], () => getAll({ search: searchQuery.value }));

const sortChanged = (sortParam) => {
  selectedSort.value = sortParam;
  currentPage.value = 1;

  getAll();
};

const updateLikedStatus = ({ id, liked }) => {
  const index = items.value.findIndex(item => item.makercart_id === id);
  if (index !== -1) {
    items.value[index] = { ...items.value[index], liked };
  }
};

onMounted(() => {
  adjustHeight();
  setBackgroundImage();
  getAll();
  updateCartCount();

  const interval = setInterval(updateCartCount, 1000);

  onBeforeUnmount(() => {
    clearInterval(interval);
  });
});

async function postCartData() {
  try {
    const storedCart = localStorage.getItem('cartItems');
    
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);

      const data = {
        "items": cartItems.map(item => ({
          "stem_makercart_id": item.id,
          "qty": 1,
          "grade": item.grade,
          "focus": item.focus,
          "number_of_students": item.numberOfStudents,
        }))
      };
      
      const response = await StemStudioService.post(data);
      confirmationModal.value.disableButtons();
      if (response.status === 200) {
        localStorage.removeItem("cartItems");
        successModal.value.updateVisibilityModal(true);
        confirmationModal.value.enableButtons();
        closeAllPopup();
      } else if (response.status === 401){
        confirmationModal.value.enableButtons();
        closeAllPopup();
        updateVisibilityModalLogin(true);
      }
    } else {
      console.log('No items in the cart.');
    }
  } catch (err) {
    console.error('Error posting cart data:', err.message);
    error.value = 'Failed to post cart data. Please try again later.';
  }
}

function adjustHeight() {
  const width = window.innerWidth;
  const height = width <= 768 ? width * (1397 / 1440) : width * (295 / 1440);
  const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');

  if (dynamicHeightDiv) {
    dynamicHeightDiv.style.height = `${height}px`;
    dynamicHeightDiv.style.borderRadius = width <= 768 ? '0px 0px 35px 0px' : '0px 0px 35px 35px';
  }
}

function setBackgroundImage() {
  const width = window.innerWidth;
  const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');

  if (dynamicHeightDiv) {
    dynamicHeightDiv.style.backgroundImage = width <= 768 ? "url('/images/maker-cart/hero-banner-mobile.svg')" : "url('/images/maker-cart/hero-banner.svg')";
    
    if (width <= 768) {
      const discussionInputContainer = document.getElementById('discussion-input-container');
      if (discussionInputContainer) {
        discussionInputContainer.style.bottom = '-50px';
      }
    }
  }
}

function updateVisibilityModalMakerCartDetail(visible, id) {
  makerCartDetail.value.updateVisibilityModal(visible, id);
}

function updateVisibilityModalUserCart(visible) {
  userCart.value.updateVisibilityModal(visible);
}

function updateVisibilityModalConfirmation(visible) {
  const storedCart = localStorage.getItem('cartItems');
  
  if (storedCart) {
    const cartItems = JSON.parse(storedCart);
    
    if (cartItems.length < 4) {
      minimumRequestModal.value.updateVisibilityModal(true);
      return;
    }
  }
  
  confirmationModal.value.updateVisibilityModal(visible);
}

function updateVisibilityModalSuccess() {
  postCartData();
}

function closeAllPopup() {
  userCart.value.updateVisibilityModal(false);
  confirmationModal.value.updateVisibilityModal(false);
}

function updateVisibilityModalLogin(visible) {
  loginModal.value.updateVisibilityModal(visible);
}

function updateCartCount() {
  const storedCart = localStorage.getItem('cartItems');
  cartCount.value = storedCart ? JSON.parse(storedCart).length : 0;
}

function clearSearch() {
  searchQuery.value = '';
  performSearch();
}

function handleConfirm() {
  updateVisibilityModalSuccess(true);
}

function handleCancel() {
  console.log('Modal canceled');
}

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
      isUserLogged.value = true;
      
      const processAuto = localStorage.getItem('processAuto');
      if (processAuto) {
        localStorage.removeItem('processAuto');
        userCart.value.updateVisibilityModal(true);
        confirmationModal.value.updateVisibilityModal(true);
        confirmationModal.value.triggerConfirm();
      }
  
      clearInterval(intervalId);
    }
  } catch (err) {
    console.error(err);
    error.value = 'Failed to check auth token. Please try again later.';
  }
};

checkAuthToken();

const intervalId = setInterval(checkAuthToken, 500);

const filtersChanged = (dataFilter) => {
  activeItems.value = dataFilter;
  currentPage.value = 1;

  getAll({ search: searchQuery.value });
};

</script>

<style>
.content-container-maker-cart {
  display: flex;
}

.search-bar-maker-cart {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  position: relative;
  width: 100%;
}

.search-bar-maker-cart input::placeholder {
  margin-top: 6.4px; /* Converted from 0.4em */
}

.item-list-maker-cart {
  flex-wrap: wrap;
  padding: 20px 0px;
  justify-content: center;
}

.item-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.item-grid > * {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  box-sizing: border-box;
  height: auto; /* Pastikan tinggi auto sehingga tidak terpengaruh oleh gaya lainnya */
}

@media (max-width: 768px) {
  .item-grid > * {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .item-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .item-grid-container {
    grid-template-columns: 1fr;
  }
}

.no-items-message {
  text-align: center;
  width: 100%;
  padding: 20px;
}

.no-search-message {
  text-align: center;
  width: 100%;
  padding: 20px;
}

.content-container-maker-cart {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .content-container-maker-cart {
    width: 90%;
    margin: 0 auto;
  }
}

.search-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #888;
  display: flex;
  justify-content: center;
}

.search-bar-maker-cart input {
  flex: 1;
  padding: 5px 10px 5px 30px;
  background-color: #F2F3FB;
  border-radius: 10px;
}

.remove-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.search-bar-maker-cart {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  position: relative;
  width: 100%;
}

.search-bar-maker-cart input::placeholder {
  margin-top: 6.4px; /* Converted from 0.4em */
}

.search-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #888;
  display: flex;
  justify-content: center;
}

.search-bar-maker-cart input {
  flex: 1;
  padding: 5px 10px 5px 30px;
  background-color: #F2F3FB;
  border-radius: 10px;
}

.remove-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-icon img {
  width: 18px;
  height: 18px;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Atur tinggi sesuai kebutuhan */
}

.error-message {
  text-align: center;
  width: 100%;
  padding: 20px;
  color: red;
}

.search-bar-maker-cart input {
  padding: 9px 10px 5px 30px !important;
}

</style>
