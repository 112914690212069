// src/services/CoachAdminService.js

import http from "./httpService";

class MakerCartAdminService {
  async getAll(params) {
    const url = http.getUrlWithParams("/admin/orders", params);
    return await http.get(url);
  }

  async getOrderItems(id) {
    return await http.get(`/admin/orders/${id}/items`);
  }

  async updateOrderItemStatus(id, makercartId, status) {
    return await http.put(`/admin/order-items/${id}/${makercartId}/status`, {status});
  }
}

export default new MakerCartAdminService();
