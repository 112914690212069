<template>
    <div class="admin-container">
        <h1 v-if="user" class="dashboard-title"> Welcome back, {{ user.firstname + ' ' + user.lastname }}</h1>
        <PlatformTrafficComponent />
        <VisitorsComponent />
        <RegistrationComponent />
        <ActiveUsersComponent />
        <ResourceViewComponent />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import PlatformTrafficComponent from '@/components/admin/dashboard/PlatformTrafficComponent.vue';
import VisitorsComponent from '@/components/admin/dashboard/VisitorsComponent.vue';
import RegistrationComponent from '@/components/admin/dashboard/RegistrationComponent.vue';
import ActiveUsersComponent from '@/components/admin/dashboard/ActiveUsersComponent.vue';
import ResourceViewComponent from '@/components/admin/dashboard/ResourceViewComponent.vue';
import cookiesService from '@/services/cookiesService';

const user = ref(null);

onMounted(() => {
  checkAuthToken();
});

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
      user.value = authToken;
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<style>
.admin-stats-container {
    gap: 10px;
}

@media (max-width: 768px) {
  .admin-stats-container {
    flex-direction: column;
    align-items: center;
  }

  .admin-table-container {
    width: 100% !important;
    margin-top: 20px;
  }
}

.admin-stat {
    border: 1px solid #00925E;
    padding: 20px 25px;
    border-radius: 17px;
    background: #ffffff;
    width: 50%;
    box-sizing: border-box;
}

.admin-stat-mins {
    font-size: 2em;
    font-weight: 700;
    color: #003D6A;
}

.country-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Menambahkan gap antar kolom */
}

.country-column {
    flex: 0 0 calc(33.3333% - 10px);
    /* Menyesuaikan ukuran kolom dengan gap */
    max-width: calc(33.3333% - 10px);
    /* Menyesuaikan ukuran kolom dengan gap */
    box-sizing: border-box;
}

.loading-state {
    text-align: center;
    font-size: 1.2em;
    color: #003D6A;
}

.error-state {
    text-align: center;
    font-size: 1.2em;
    color: red;
}

.btn-retry {
    background-color: #00925E;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-retry:hover {
    background-color: #007a4e;
}

@media (max-width: 991px) {
    .country-column {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
</style>
