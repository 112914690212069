<template>
  <div class="item-maker-cart" @click="$emit('item-clicked')">
    <div class="item-content" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <div class="image-container">
        <img 
          :src="props.item.image" 
          alt="Item image" 
          class="item-image" 
          :id="'item-image-' + props.item.id" 
          @error="handleImageError"
        />
        <div class="overlay" :id="'overlay-' + props.item.id"></div> <!-- Lapisan gelap -->
      </div>
      <div class="item-info-container" ref="infoContainer">
        <div class="item-info" ref="itemInfo">
          <div style="text-align: start;">
            {{ displayText }}
          </div>
          <img v-if="props.item.liked && props.isLogged !== false" height="30" src="/images/liked-01.svg" class="like-icon" @click.stop="handleLikeClick(props.item.makercart_id)" />
          <img v-else-if="!props.item.liked && props.isLogged !== false" height="30" src="/images/like-01.svg" class="like-icon" @click.stop="handleLikeClick(props.item.makercart_id)" />
          <img v-else height="30" src="/images/like-brown.svg" class="like-icon" style="cursor: not-allowed;"/>
        </div>
        <div class="description" ref="description">
          {{ props.item.activity_description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits } from 'vue';
import StemStudioService from '@/services/stemStudioService';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isLogged: {
    type: Boolean,
    default: true, // Default value set to true (as boolean)
  },
});

const emit = defineEmits(['update-liked-status']); // Define the emitted events

const infoContainer = ref(null);
const itemInfo = ref(null);
const description = ref(null);

const isHovered = ref(false);

const maxLength = 27; // Ganti sesuai kebutuhan

const truncatedText = computed(() => {
  const activity = props.item.makercart_activity;
  if (activity.length > maxLength && !isHovered.value) {
    return activity.slice(0, maxLength) + '...';
  }
  return activity;
});

const displayText = computed(() => {
  return truncatedText.value;
});

const expandInfo = () => {
  const infoHeight = itemInfo.value.offsetHeight;
  const descriptionHeight = description.value.offsetHeight;
  infoContainer.value.style.height = `${infoHeight + descriptionHeight}px`;
};

const collapseInfo = () => {
  infoContainer.value.style.height = '60px'; // Kembali ke tinggi awal
};

const handleMouseOver = () => {
  isHovered.value = true;
  expandInfo();
  addDarkOverlay();
};

const handleMouseLeave = () => {
  isHovered.value = false;
  collapseInfo();
  removeDarkOverlay();
};

const addDarkOverlay = () => {
  const imageElement = document.getElementById('item-image-' + props.item.id);
  const overlayElement = document.getElementById('overlay-' + props.item.id);
  if (imageElement && overlayElement) {
    imageElement.classList.add('dark-overlay');
    overlayElement.classList.add('dark-overlay');
  }
};

const removeDarkOverlay = () => {
  const imageElement = document.getElementById('item-image-' + props.item.id);
  const overlayElement = document.getElementById('overlay-' + props.item.id);
  if (imageElement && overlayElement) {
    imageElement.classList.remove('dark-overlay');
    overlayElement.classList.remove('dark-overlay');
  }
};

const handleLikeClick = async (id) => {
  try {
    const response = await StemStudioService.likeMakercart(id);
    if (response.status === 200) {
      // Emit event ke parent component
      emit('update-liked-status', { id, liked: response.data.data.liked });
    }
  } catch (err) {
    alert('Error posting cart data:', err.message);
  }
};

// Fallback untuk gambar yang gagal dimuat
const handleImageError = (event) => {
  event.target.src = './images/content-placeholder.svg'; // Ganti dengan placeholder image
};
</script>

<style scoped>
.item-maker-cart {
  text-align: center;
  background-color: #f2f3fb;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.image-container {
  width: 100%;
  position: relative;
  padding-bottom: 75%; /* Aspek rasio 4:3 */
  overflow: hidden;
}

.item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.item-image.dark-overlay {
  filter: brightness(0.7); /* Efek gelap */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  pointer-events: none; /* Pastikan overlay tidak menghalangi klik */
}

.overlay.dark-overlay {
  background-color: rgba(0, 0, 0, 0.3); /* Efek gelap */
}

.item-info-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: rgba(242, 243, 251, 1);
}

.item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  z-index: 2;
  font-weight: 600;
  font-size: 1.2em;
}

.description {
  display: block;
  text-align: left;
  padding: 0px 20px 20px 20px;
}

.like-icon {
  margin-left: 10px;
  z-index: 3; /* Pastikan icon berada di atas overlay */
  cursor: pointer;
}

.like-icon:hover {
  filter: brightness(0.9);
}
</style>
