<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <div class="modal-content modal-content-1" style="padding: 0px;">
                <div class="header-modal">
                    <div style="height: 18px;"></div>
                </div>

                <div class="container-modal-scroll scrollbar" id="style-4">
                    <div v-if="loading" class="spinner-container">
                        <div class="mt-5 spinner"></div>
                    </div>
                    <div v-else style="margin-right: 10px;">
                        <div v-if="props.mode === 'detail'">
                            <div v-if="makerCart.status === 'CONFIRMED'" class="filter-primary mb-3"
                                style="display: flex; align-items: center;">
                                <img src="/images/icon/cart.svg" alt="maker-cart" style="margin-right: 8px;">
                                <div class="margin-label">Purchase Confirmed</div>
                            </div>
                            <div v-else class="filter-grey mb-3" style="display: flex; align-items: center;">
                                <img src="/images/icon/cart.svg" alt="maker-cart" style="margin-right: 8px;">
                                <div class="margin-label">Quote Submitted</div>
                            </div>
                        </div>
                        <div>
                            <div class="image-gallery-container">
                                <img src="/images/chinaus/arrow-left.svg" class="arrow arrow-left"
                                    @click="scroll('left')" alt="Left Arrow">
                                <div class="image-gallery">
                                    <div class="image-thumbnails">
                                        <img v-for="(option, index) in makerCart.image_thumbnail" :key="index"
                                            :src="option" height="180" alt="">
                                    </div>
                                </div>
                                <img src="/images/chinaus/arrow-left.svg" class="arrow arrow-right"
                                    @click="scroll('right')" alt="Right Arrow">
                            </div>
                        </div>

                        <h1 style="font-weight: 550;" class="color-primary mt-4">{{ makerCart.makercart_activity }}</h1>
                        <p class="color-primary">{{ makerCart.activity_description }}</p>

                        <div class="container-azz">
                            <span class="mr-4 subject-item-2" style="font-size: 14px;font-weight: 400"
                                v-for="(option, index) in makerCart.tags" :key="index"
                                @click="toggleSelection(option, selectedGrades, false)">
                                {{ option }}
                            </span>
                        </div>

                        <div class="border-line mb-1"></div>

                        <div class="container-azz">
                            <div class="row-azz">
                                <!-- Material Provided -->
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/material-provided.svg" alt="">
                                        <div>Material Provided</div>
                                    </div>
                                    <div v-if="loadingMaterials" class="spinner-container">
                                        <div class="spinner"></div>
                                    </div>
                                    <div style="display: flex; flex-wrap: wrap;" v-else>
                                        <div class="mr-4" style="font-size: 15px;"
                                            v-for="(option, index) in makerCart.material_needed" :key="index">
                                            {{ option }}
                                        </div>
                                    </div>
                                </div>
                                <!-- You’ll need to provide... -->
                                <div class="box-azz" style="position: relative;">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img class="filter-green" src="/images/maker-cart/material-provided.svg" alt="">
                                        <div>You’ll need to provide...</div>
                                        <img src="/images/maker-cart/info-circle.svg"
                                            style="height: 15px !important;margin-bottom: 3px;" alt=""
                                            @mouseover="showInfo2 = true" @mouseleave="showInfo2 = false">
                                    </div>
                                    <div v-if="loadingMaterials" class="spinner-container">
                                        <div class="spinner"></div>
                                    </div>
                                    <div style="display: flex; flex-wrap: wrap;" v-else>
                                        <div class="mr-4" style="font-size: 15px;"
                                            v-for="(option, index) in makerCart.material_needed_provide" :key="index">
                                            {{ option }}
                                        </div>
                                    </div>
                                    <div v-if="showInfo2" class="info-box2">
                                        These items are not provided as part of the Makercart order.
                                    </div>
                                </div>
                                <!-- Subject -->
                                <div class="box-azz" style="position: relative;">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/subject.svg" alt="" style="height: 16px;">
                                        Subject
                                        <img src="/images/maker-cart/info-circle.svg"
                                            style="height: 15px !important;margin-bottom: 3px;" alt=""
                                            @mouseover="showInfo = true" @mouseleave="showInfo = false">
                                    </div>
                                    <span v-if="Array.isArray(makerCart.subject)">
                                        <span class="mr-4" style="font-size: 15px;"
                                            v-for="(option, index) in makerCart.subject" :key="index">
                                            {{ option }}
                                        </span>
                                        <span v-if="makerCart.subject.length === 0">N/A</span>
                                    </span>
                                    <span class="mr-4" v-else>
                                        <span v-if="makerCart.subject === ''">N/A</span>
                                        <span v-else>{{ makerCart.subject }}</span>
                                    </span>
                                    <div v-if="showInfo" class="info-box2">
                                        The core academic subject that this activity is best aligned to.
                                    </div>
                                </div>
                                <!-- Prototyping Material Integration -->
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img class="filter-grey"
                                            src="/images/maker-cart/prototyping-material-integration.svg" alt="">
                                        <div class="color-grey">Prototyping Material Integration</div>
                                    </div>
                                    <div>
                                        <span class="mr-4" style="font-size: 15px;">
                                            N/A
                                        </span>
                                    </div>
                                </div>
                                <!-- Standards -->
                                <div class="box-azz" style="position: relative;">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img class="filter-green" src="/images/maker-cart/standarts.svg" alt=""
                                            style="height: 16px;">
                                        Standards
                                    </div>
                                    <span>
                                        <span v-if="makerCart.standards" class="mr-4" style="font-size: 15px;">
                                            {{ makerCart.standards }}
                                        </span>
                                        <span v-else class="mr-4" style="font-size: 15px;">
                                            N/A
                                        </span>
                                    </span>
                                </div>
                                <!-- Associated Hardware -->
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img class="filter-green" src="/images/maker-cart/associate-hardware.svg"
                                            alt="">
                                        <div>Associated Hardware</div>
                                    </div>
                                    <div>
                                        <span v-if="Array.isArray(makerCart.hardware_associated)">
                                            <span class="mr-4" style="font-size: 15px;"
                                                v-for="(option, index) in makerCart.hardware_associated" :key="index">
                                                {{ option }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="border-line my-1"></div>

                        <div v-if="props.mode === 'select'" class="container-azz">
                            <div class="row-azz">
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/grade.svg" alt="">
                                        <div>
                                            Grade
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            v-for="(option, index) in makerCart.grade_level" :key="index"
                                            :class="{ 'subject-item-selected': isSelected(option, selectedGrades) }"
                                            @click="toggleSelection(option, selectedGrades, false)">
                                            {{ option }}
                                        </span>
                                    </div>
                                </div>
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/people.svg" alt="">
                                        <div>
                                            Number of Students
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <div class="select-wrapper">
                                            <select v-model="selectedNumberOfStudents" class="mr-4 subject-item"
                                                style="font-size: 14px;font-weight: 400">
                                                <option v-for="option in numberOfStudentsOptions" :key="option"
                                                    :value="option">{{ option }}</option>
                                            </select>
                                            <img src="/images/arrow-bottom.svg" height="15" alt="Collapsed Icon"
                                                class="clickable-image">
                                        </div>
                                    </div>
                                </div>
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/people.svg" alt="">
                                        <div>
                                            Focus
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Sustainability', selectedFocus), 'subject-item-disabled': !isFocusSustainabilityEnabled }"
                                            @click="toggleSelection('Sustainability', selectedFocus, false)"
                                            :disabled="!isFocusSustainabilityEnabled">
                                            Sustainability
                                        </span>
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Engineering Design', selectedFocus), 'subject-item-disabled': !isFocusEngineeringDesignEnabled }"
                                            @click="toggleSelection('Engineering Design', selectedFocus, false)"
                                            :disabled="!isFocusEngineeringDesignEnabled">
                                            Engineering Design
                                        </span>
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Classic Maker', selectedFocus) }"
                                            @click="toggleSelection('Classic Maker', selectedFocus, false)">
                                            Classic Maker
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="props.mode === 'detail'" class="container-azz">
                            <div class="row-azz">
                                <div class="row-azz">
                                    <div class="box-azz">
                                        <div class="title-maker-cart-detail mb-2">
                                            <img src="/images/maker-cart/grade.svg" alt="">
                                            <div>
                                                Grade
                                            </div>
                                        </div>
                                        <div class="subject-container">
                                            {{ makerCart.selectedGrade }}
                                        </div>
                                    </div>
                                    <div class="box-azz">
                                        <div class="title-maker-cart-detail mb-2">
                                            <img src="/images/maker-cart/people.svg" alt="">
                                            <div>
                                                Number of Students
                                            </div>
                                        </div>
                                        <div class="subject-container">
                                            {{ makerCart.number_of_students }}
                                        </div>
                                    </div>
                                    <div class="box-azz">
                                        <div class="title-maker-cart-detail mb-2">
                                            <img src="/images/maker-cart/people.svg" alt="">
                                            <div>
                                                Focus
                                            </div>
                                        </div>
                                        <div class="subject-container">
                                            {{ makerCart.selectedFocus }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="props.mode === 'select'" class="modal-footer">
                    <button class="button-email disabled">
                        See Instructional Lesson
                    </button>
                    <button @click="addToCart" class="button-email"
                        :class="{ 'disabled': loadingMaterials }" :disabled="loadingMaterials"
                        style="text-decoration: none;">
                        <img src="/images/maker-cart/cart.svg" alt="Add to Cart">
                        Add to Cart
                    </button>
                </div>
                <div v-if="props.mode === 'detail'" class="modal-footer">
                    <button @click="addToCart" class="button-email disabled" :disabled="loadingMaterials"
                        style="text-decoration: none;">
                        View Resources
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose, onMounted, defineProps } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import StemStudioService from '@/services/stemStudioService';

// eslint-disable-next-line
const props = defineProps({
    mode: {
        type: String,
        default: 'select'  // Default mode is select
    }
});

const showModal = ref(false);
const showInfo = ref(false);
const showInfo2 = ref(false);
const loading = ref(false);
const loadingMaterials = ref(false);
const makerCart = ref({});

const selectedGrades = ref([]);
const selectedFocus = ref([]);
const selectedNumberOfStudents = ref(80);

const numberOfStudentsOptions = [80, 90, 100, 110, 120, 130, 140, 150, 160];

const isFocusSustainabilityEnabled = ref(false);
const isFocusEngineeringDesignEnabled = ref(false);

onMounted(() => {
    selectedGrades.value = [];
    selectedFocus.value = [];
});

async function getById(id) {
    loading.value = true;
    loadingMaterials.value = true;

    const response = await StemStudioService.getById(id);
    if (response.status === 200) {
        makerCart.value = response.data.data;
        loading.value = false;

        const { focus, material_needed } = makerCart.value;

        if (focus) {
            isFocusSustainabilityEnabled.value = focus.includes("Sustainability");
            isFocusEngineeringDesignEnabled.value = focus.includes("Engineering Design");
        }

        let materialData = null;

        if (material_needed?.length) {
            const materialNeededString = material_needed.join(',');
            materialData = await getMakerCartByIds(materialNeededString);
        }

        // Reset material_needed and material_needed_provide
        makerCart.value.material_needed = [];
        makerCart.value.material_needed_provide = [];

        if (materialData) {
            materialData.forEach(item => {
                if (item.includedInMC) {
                    makerCart.value.material_needed.push(item.name);
                } else {
                    makerCart.value.material_needed_provide.push(item.name);
                }
            });
        }
    }

    loadingMaterials.value = false;
}

// eslint-disable-next-line
async function getByIdMakerCartID(id, number) {
    loading.value = true;
    loadingMaterials.value = true;

    const response = await StemStudioService.getByIdMakerCartID(id, number);
    if (response.status === 200) {
        console.log('response', response);
        makerCart.value = response.data.data;
        loading.value = false;

        const { focus, material_needed } = makerCart.value;

        if (focus) {
            isFocusSustainabilityEnabled.value = focus.includes("Sustainability");
            isFocusEngineeringDesignEnabled.value = focus.includes("Engineering Design");
        }

        let materialData = null;

        if (material_needed?.length) {
            const materialNeededString = material_needed.join(',');
            materialData = await getMakerCartByIds(materialNeededString);
        }

        // Reset material_needed and material_needed_provide
        makerCart.value.material_needed = [];
        makerCart.value.material_needed_provide = [];

        if (materialData) {
            materialData.forEach(item => {
                if (item.includedInMC) {
                    makerCart.value.material_needed.push(item.name);
                } else {
                    makerCart.value.material_needed_provide.push(item.name);
                }
            });
        }
    }

    loadingMaterials.value = false;
}

async function getMakerCartByIds(ids) {
    const response = await StemStudioService.getMaterialByIdBulk(ids);
    if (response.status === 200) {
        return response.data.data;
    }
    return [];
}

// eslint-disable-next-line
function updateVisibilityModal(visible, id, stem_makercart_order_id = null) {
    selectedGrades.value = [];
    selectedFocus.value = [];

    showModal.value = visible;

    if(showModal.value===true) {
        if (props.mode === 'detail') {
            getByIdMakerCartID(id, stem_makercart_order_id);
        } else {
            if (id) {
                getById(id);
            }
        }
    }
}

function toggleSelection(item, list, isMultiple) {
    if (isDisabled(item, 'focus')) return;

    if (isMultiple) {
        const index = list.indexOf(item);
        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(item);
        }
    } else {
        if (list.includes(item)) {
            list.length = 0;
        } else {
            list.length = 0;
            list.push(item);
        }
    }
}

function isSelected(item, list) {
    return list.includes(item);
}

function isDisabled(item, type) {
    if (type === 'focus') {
        if (item === 'Sustainability') return !isFocusSustainabilityEnabled.value;
        if (item === 'Engineering Design') return !isFocusEngineeringDesignEnabled.value;
        if (item === 'Classic Maker') return false;
    }
    return loadingMaterials.value;
}

function addToCart() {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const itemExists = cartItems.some(item => item.makercart_id === makerCart.value.makercart_id);

    const grade = selectedGrades.value.length === 0 ? "" : selectedGrades.value[0];
    const focus = selectedFocus.value.length === 0 ? "" : selectedFocus.value[0];

    if (itemExists) {
        alert('The item is already in the cart');
        return;
    }

    if (grade === "" || focus === "") {
        alert('Please select both grade and focus before adding to cart.');
        return;
    }

    cartItems.push({
        id: makerCart.value.makercart_id,
        makercart_id: makerCart.value.makercart_id,
        title: makerCart.value.makercart_activity,
        image: makerCart.value.image_thumbnail && makerCart.value.image_thumbnail.length > 0 ? makerCart.value.image_thumbnail[0] : '',
        description: makerCart.value.activity_description,
        numberOfStudents: selectedNumberOfStudents.value,
        subject: makerCart.value.subject,
        grade: grade,
        focus: focus,
    });

    selectedGrades.value = [];
    selectedFocus.value = [];

    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    showModal.value = false;
    const event = new Event('refreshCart');
    window.dispatchEvent(event);
}

function scroll(direction) {
    const container = document.querySelector('.image-thumbnails');
    if (direction === 'left') {
        container.scrollBy({ left: -200, behavior: 'smooth' });
    } else {
        container.scrollBy({ left: 200, behavior: 'smooth' });
    }
}

defineExpose({
    updateVisibilityModal
});
</script>

<style>
.subject-item-2 {
    padding-top: 10px;
    display: inline-block;
    padding: 4px 15px;
    border: 1px solid #D8DAE5;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 2px;
    margin-top: 2px;
    font-style: italic;
}

.container-azz {
    display: flex;
    flex-wrap: wrap;
}

.row-azz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.box-azz {
    width: calc(50% - 10px);
    margin-bottom: 15px;
    position: relative;
}

.title-maker-cart-detail {
    font-weight: 500;
    color: #00925E;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 1;
}

.title-maker-cart-detail img {
    height: 20px;
    margin: auto 0;
}

.subject-item-selected {
    background-color: #d1e7dd;
    border-radius: 5px;
    padding: 5px;
}

.subject-item-disabled {
    cursor: default !important;
    color: #CCCCCC !important;
}

.subject-item-disabled:hover {
    background-color: #FFFFFF !important;
    cursor: default;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 19px 5px 10px;
}

.select-wrapper .clickable-image {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%);
    pointer-events: none;
}

.container-modal-large {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

@media (max-width: 600px) {
    .box-azz {
        width: calc(100% - 10px);
    }
}

@media (max-width: 991px) {
    .row-azz {
        flex-direction: column;
    }

    .box-azz {
        width: 100%;
        margin: 5px 0;
    }
}

.image-gallery-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.image-gallery {
    width: 90%;
    overflow: hidden;
}

.image-thumbnails {
    display: inline-flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    gap: 20px;
}

.arrow {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
}

.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
    transform: rotate(180deg);
}

@media (max-width: 600px) {
    .image-gallery {
        width: 100%;
    }
}

.info-box2 {
    position: absolute;
    background: #F2F3FB;
    border: 1px solid #D8DAE5;
    padding: 10px;
    z-index: 1000;
    top: 30px;
    left: 0;
    border-radius: 10px;
    min-width: 280px;
}

.box-azz:hover .info-box2 {
    display: block;
}

.back-link img {
    height: 1em;
    width: 1em;
}

.project-title {
    font-size: 1.75em;
    font-weight: 400;
}
</style>
