class EncryptionService {
    constructor(secretKey, secretTag) {
      this.secretKey = secretKey;
      this.secretTag = secretTag;
    }
  
    encrypt(message) {
      const middleIndex = Math.floor(message.length / 2);
      const messageWithTag = 
        message.slice(0, middleIndex) +
        this.secretTag +
        message.slice(middleIndex);
        
      let result = "";
      for (let i = 0; i < messageWithTag.length; i++) {
        result += String.fromCharCode(
          messageWithTag.charCodeAt(i) ^ this.secretKey.charCodeAt(i % this.secretKey.length)
        );
      }
      return btoa(result); // Encode to base64 for URL safety
    }
  
    decrypt(ciphertext) {
      try {
        const decoded = atob(ciphertext); // Decode from base64
        let result = "";
        for (let i = 0; i < decoded.length; i++) {
          result += String.fromCharCode(
            decoded.charCodeAt(i) ^ this.secretKey.charCodeAt(i % this.secretKey.length)
          );
        }
  
        // Search for the secret tag in the decrypted text
        const tagIndex = result.indexOf(this.secretTag);
        if (tagIndex !== -1) {
          // Remove the secret tag and return the original message
          return result.replace(this.secretTag, "");
        } else {
          console.warn("Decrypted message does not contain the secret tag.");
          return null;
        }
      } catch (error) {
        console.error("Failed to decrypt:", error);
        return null;
      }
    }
  
    isValidEncryptedText(ciphertext) {
      const decryptedText = this.decrypt(ciphertext);
      return decryptedText !== null;
    }
  }
  
  export default new EncryptionService("your-secret-key-here", "your-secret-tag");
  