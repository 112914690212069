<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-1000006697.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Facilitating a Maker Activity</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Before Activity</h2>
        <ul>
          <li class="fs-12">Before conducting the Maker Cart Activity, we highly recommended that the facilitator is familiar and informed with the activity. An alternative would be to participate in the maker activity and explore with the student.</li>
          <li class="fs-12">You may place the Activity Poster in a visible location to be easily viewed by the students from different parts of the class.</li>
        </ul>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">During Activity</h2>
        <ul>
          <li class="fs-12">Advise the students to consider and plan ahead. Encourage them to have a clear idea of the materials and quantities needed before taking the materials, but do not restrict them from using other materials than the suggested ones. (Use the budget sheet to manage the material used)</li>
          <li class="fs-12">Ensure that the students keep their surroundings clean during the prototyping process.</li>
          <li class="fs-12">Advise caution when sharp tools like scissors or cardboard knife.</li>
          <li class="fs-12">Observe the student's ideation and prototyping process, do not physically help the students to complete the prototype.</li>
        </ul>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">After Activity</h2>
        <ul>
          <li class="fs-12">Clean up the surrounding workspace, ensure students store unused materials neatly back on the Maker Cart.</li>
          <li class="fs-12">Allow the students to reflect on the making process and encourage them to consider possible improvements even after the activity has ended.</li>
          <li class="fs-12">Encourage the students to visit the space often to improve and develop their prototypes.</li>
        </ul>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/9');
};

const goToNextPage = () => {
  router.push('/makercart-guide/11');
};
</script>