<template>
    <div class="border-container-unique38 mb-4">
        <div class="admin-content-layout">
            <div class="admin-main-content" style="padding-top: 0px;max-width: 100%;padding-right: 0px;overflow-x: scroll;">
                <div class="d-flex justify-space-between">
                    <div class="admin-header">
                        <h1> <img src="/images/icon/graph.svg" class="filter-primary" height="20" alt=""> Platform Traffic</h1>
                    </div>
                    <button v-if="errorPlatformTraffic !== null" @click="getPlatformTrafficData" class="btn btn-retry">Retry</button>
                </div>
                <div v-if="loadingPlatformTraffic" class="loading-state">Loading...</div>
                <div v-else-if="errorPlatformTraffic" class="error-state">Error: {{ errorPlatformTraffic }}</div>
                <div v-else>
                    <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p>
                    <div class="d-flex justify-space-around admin-stats-container">
                        <div class="admin-table-container admin-stat">
                            <div class="admin-table" style="margin-bottom: 0px;">
                                <div class="admin-table td" style="margin-bottom: 0px;">
                                    <div class="admin-list-title color-primary">Visitors</div>
                                    <div>
                                        <div class="d-flex justify-start fs-08 my-2 gc-2">
                                            <img src="/images/icon/increase.svg" alt="">
                                            {{ platformTrafficData.avgSessionDurationChange.toFixed(2) }}% than last 30 days
                                        </div>
                                        <p class="admin-stat-mins">{{ platformTrafficData.avgSessionDurationThisMonth.toFixed(2) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="admin-table-container admin-stat">
                            <div class="admin-table" style="margin-bottom: 0px;">
                                <div class="admin-table td" style="margin-bottom: 0px;">
                                    <div class="admin-list-title color-primary">Average Time Spend</div>
                                    <div>
                                        <div class="d-flex justify-start fs-08 my-2 gc-2">
                                            <img src="/images/icon/increase.svg" alt="">
                                            {{ platformTrafficData.sessionsChange.toFixed(2) }}% than last 30 days
                                        </div>
                                        <p class="admin-stat-mins">{{ platformTrafficData.sessionsThisMonth.toFixed(2) }} mins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import DashboardService from '@/services/dashboardService.js';
import { format, subMonths } from 'date-fns';

const platformTrafficData = ref({
    sessionsThisMonth: 0,
    avgSessionDurationThisMonth: 0,
    sessionsChange: 0,
    avgSessionDurationChange: 0
});
const loadingPlatformTraffic = ref(true);
const errorPlatformTraffic = ref(null);

const getPlatformTrafficData = async () => {
    loadingPlatformTraffic.value = true;
    errorPlatformTraffic.value = null;

    try {
        const response = await DashboardService.getPlatformTrafficData();
        console.log('response', response);
        if (response.status === 200) {
            const data = response.data.data;
            platformTrafficData.value.sessionsThisMonth = data.sessionsThisMonth;
            platformTrafficData.value.avgSessionDurationThisMonth = data.avgSessionDurationThisMonth;
            platformTrafficData.value.sessionsChange = data.sessionsChange;
            platformTrafficData.value.avgSessionDurationChange = data.avgSessionDurationChange;
        } else {
            errorPlatformTraffic.value = response.message;
        }
    } catch (error) {
        errorPlatformTraffic.value = error.message;
    } finally {
        loadingPlatformTraffic.value = false;
    }
};

const formattedStartDate = format(subMonths(new Date(), 1), 'dd MMMM yyyy');
const formattedEndDate = format(new Date(), 'dd MMMM yyyy');

onMounted(() => {
    getPlatformTrafficData();
});
</script>
