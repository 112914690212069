import http from "./httpService";

class DashboardService {
  async getReturningChart() {
    const response = await http.get("/admin/dashboard/getReturningChart");
    return response;
  }

  async getActiveUsers() {
    const response = await http.get("/admin/dashboard/getActiveUsers");
    return response;
  }

  async getPlatformTrafficData() {
    const response = await http.get(`/admin/dashboard/getThisMonthData`);
    return response;
  }

  async getRegisteredUsersByMonth() {
    const response = await http.get(
      "/admin/dashboard/getRegisteredUsersByMonth"
    );
    return response;
  }

  async getTopViews(param) {
    const url = http.getUrlWithParams(`/admin/dashboard/getTopViews`, param);
    return await http.get(url);
  }

  async getDashboardStats() {
    const response = await http.get(
      "/admin/dashboard/getDashboardStats"
    );
    return response;
  }  
}

export default new DashboardService();
