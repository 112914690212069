<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator’s Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can’t wait to
        see what you’ll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-1000006402.png" alt="Frame 1000006402">
      <div class="x-overlay-text">
        <h1>Maker Approach</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-image-container">
        <img src="/images/maker-cart-guide/tinker-to-discover.svg" height="180px" alt="Tinker to Discover">
      </div> 
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Tinker to Discover</h2>
        <p class="fs-12 margin-10"><span class="green-bold">Open-ended exploration</span> allows learners to direct their own discovery, promoting curiosity, experimentation, and autonomous learning.</p>
        <p class="fs-12"><span class="green-bold">Learners’ autonomy and learner-driven activities</span> promote self-directed tinkering, so learners have the freedom and control over their learning with their own interest and initiatives.</p>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-image-container">
        <img src="/images/maker-cart-guide/make-to-learn.svg" height="100px" alt="Make to Learn">
      </div> 
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Make to Learn</h2>
      <p class="fs-12 margin-10"><span class="green-bold">Defined path and tasks</span> provide structure for hands-on learning, encouraging creativity and problem-solving within the framework.</p>
      <p class="fs-12"><span class="green-bold">More scaffolded guidance</span> supports learners as they build new skills and explore solutions. Learners can have the access to examples and coaches while tackling challenges that are just beyond their abilities.</p>
      </div>
    </div>    

    <div class="x-content-card x-content-flex">
      <div class="x-image-container">
        <img src="/images/maker-cart-guide/application-project.svg" height="100px" alt="Application Project">
      </div> 
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Application Project</h2>
        <p class="fs-12 margin-10"><span class="green-bold">Stimulating and challenging for learners</span>, stretching their abilities to learn and reflect, and allowing them to apply their knowledge to real-world projects.</p>
        <p class="fs-12"><span class="green-bold">Create an artefact demonstrating their learning</span>, providing evidence of learners’ understanding and gives them a tangible product to showcase their learning experiences.</p>
      </div>
    </div>  

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/1');
};

const goToNextPage = () => {
  router.push('/makercart-guide/3');
};
</script>

