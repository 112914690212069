<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-1000006403.png" alt="Frame 1000006403">
      <div class="x-overlay-text">
        <h1>About the Maker Cart</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="margin-10" style="width: 80%;">
      <img src="/images/maker-cart-guide/top-comma.svg" alt="Top Comma">
      <p class="fs-12" style="color: #003D6A;font-size: 22px;">The Makercart Activities are designed for students to explore their ideas; to prototype, iterate, and test, and, in the process, adopt the Maker Mindset. The activities are designed to follow a “Low Floor, High Ceiling, Wide Wall” principle, with starting activities ('low floor') accessible to all learners, and can be differentiated for high-ability learners ('high ceiling'), and able to support the diverse learning needs of our students ('wide walls'). The Makercart comes with Activity Guides, Instructional Material for each activity, and the Makercart itself.</p>
    </div>

    <div style="display: flex; gap: 10px; width: 80%;">
      <div class="x-header color-primary">
        <h1>What resources are provided with the Makercart?</h1>
      </div>
    </div>

    <div style="background-color: #E00069;color: white;" class="x-content-card x-content-flex">
      <div class="x-content-text">
        <div style="margin-top: 0px;" class="x-header">
          <img src="/images/maker-cart-guide/document-text.svg" height="45px" alt="MakerCart Educator's Guide Header">
          <h1>MakerCart Educator's Guide</h1>
        </div>
        <p class="fs-12 margin-10">The Activity Guides for each Makercart Activity provide detailed notes for teachers about how to implement the activity in their classroom. Recommended materials - and quantities per student - are provided, alongside suggestions on how each section of the activity can be carried out. Standards are also provided for each activity, for clear alignment to academic concepts.</p>
      </div>
    </div>

    <div style="background-color: #4C4DDC;color: white;" class="x-content-card x-content-flex">
      <div class="x-content-text">
        <div style="margin-top: 0px;" class="x-header">
          <img src="/images/maker-cart-guide/poster-2.svg" height="45px" alt="MakerCart Educator's Guide Header">
          <h1>Instructional Material</h1>
        </div>
        <p class="fs-12 margin-10">A slide deck, along with student handouts, are provided for each activity. Teachers should use the slides to introduce the student to the activity, and engage the entire class. Thereafter, students will refer to their individual handouts to complete the Maker Activity. This fosters self-directed learning. Finally, teachers should use the slides to reengage students in sharing their creations and reflecting about the activity.</p>
      </div>
    </div> 
    
    <div style="background-color: #003D6A;color: white;" class="x-content-card x-content-flex">
      <div class="x-content-text">
        <div style="margin-top: 0px;" class="x-header">
          <img src="/images/maker-cart-guide/layer-1.svg" height="45px" alt="MakerCart Educator's Guide Header">
          <h1>Makercart</h1>
        </div>
        <p class="fs-12 margin-10">The Maker Cart is a materials cart where materials are visible to the students to be creative in building their prototypes. Almost all materials that are needed for your chosen Maker Activities are provided, and can be stored within the cart. Additionally, the storage boxes and side panels are colour-coded for ease of giving instructions to students.</p>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/2');
};

const goToNextPage = () => {
  router.push('/makercart-guide/4');
};
</script>

