<template>
  <div class="admin-container">
    <h1 v-if="user" class="dashboard-title"> Welcome back, {{ user.firstname + ' ' + user.lastname }}</h1>

    <!-- Leaderboard Section -->
    <div class="border-container-unique38 mb-4">
      <div style="width: 100%;">
        <div>
          <div class="d-flex justify-space-between">
            <div class="admin-header">
              <div class="d-flex">
                <img src="/images/icon/single-person.svg" height="25" class="icon-margin">
                <h1>Leaderboard</h1>
              </div>
            </div>
          </div>
          <div>
            <!-- <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p> -->
            <div class="d-flex align-start ysa-stats-container">
              <div class="left-panel">
                <div class="d-flex justify-space-around gap-10">
                  <div v-if="leaderboardLoading" class="spinner-container">
                    <div class="spinner"></div>
                  </div>
                  <div v-else class="ysa-card-rank" v-for="(school, index) in leaderboard.slice(0, 3)" :key="school.school_id">
                    <div class="d-flex align-items-center flex-column">
                      <img :src="`/images/admin/${index + 1}${getOrdinalSuffix(index + 1)}-place.svg`" class="ysa-images" alt="Trophy">
                      <div class="ysa-school-name">{{ school.school_name }}</div>
                      <div class="ysa-school-point">{{ school.total_points }} points</div>
                      <div class="ysa-school-rank">{{ index + 1 }}{{ getOrdinalSuffix(index + 1) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-panel">
                <ul class="leaderboard-list">
                  <li v-if="leaderboardLoading" class="spinner-container">
                    <div class="spinner"></div>
                  </li>
                  <li v-else v-for="(school) in leaderboard.slice(3)" :key="school.school_id" class="leaderboard-item">
                    <div class="leaderboard-content">
                      <p class="leaderboard-rank">{{ school.rank }}</p>
                      <p class="leaderboard-school" :title="school.school_name">{{ school.school_name.substring(0, 23) }}{{ school.school_name.length > 23 ? '...' : '' }}</p>
                    </div>
                    <p class="leaderboard-points">{{ school.total_points }} Points</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Resources Section -->
    <div class="border-container-unique38 mb-4">
      <div style="width: 100%;">
        <div class="resources-container">
          <div class="d-flex justify-space-between">
            <div class="admin-header">
              <div class="d-flex">
                <img src="/images/icon/single-person.svg" height="25" class="icon-margin">
                <h1>Resources</h1>
              </div>
            </div>
          </div>
          <div>
            <!-- <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p> -->
            <div class="d-flex align-start admin-stats-container">
              <div v-if="resourcesLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="d-flex mb-3 flex-1">
                <div style="width: 100%;" class="admin-stat">
                  <div class="admin-table">
                    <div class="admin-table-td">
                      <div class="admin-list-title">Total Resources</div>
                      <div>
                        <p class="admin-stat-mins">{{ totalResources }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="resourcesLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="d-flex mb-3 flex-1">
                <div style="width: 100%;" class="admin-stat">
                  <div class="admin-table">
                    <div class="admin-table-td">
                      <div class="admin-list-title">Total Self Directed Learning</div>
                      <div>
                        <p class="admin-stat-mins">{{ totalSelfDirectedLearning }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="resourcesLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="d-flex mb-3 flex-1">
                <div style="width: 100%;" class="admin-stat">
                  <div class="admin-table">
                    <div class="admin-table-td">
                      <div class="admin-list-title">Total Lesson Package</div>
                      <div>
                        <p class="admin-stat-mins">{{ totalLessonPackage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                  
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Students and Teachers Section -->
    <div class="d-flex flex-wrap gap-10">
      <div class="border-container-unique38 mb-4 flex-1">
        <div style="width: 100%;">
          <div class="d-flex justify-space-between">
            <div class="admin-header">
              <div class="d-flex">
                <img src="/images/icon/single-person.svg" height="25" class="icon-margin">
                <h1>Students</h1>
              </div>
            </div>
          </div>
          <div>
            <!-- <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p> -->
            <div class="d-flex align-start admin-stats-container">
              <div v-if="studentsLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="admin-stat">
                <div class="admin-table">
                  <div class="admin-table-td">
                    <div class="admin-list-title">Total Students</div>
                    <div>
                      <p class="admin-stat-mins">{{ totalStudents }}</p>
                    </div>
                  </div>
                </div>
              </div>               
            </div>
          </div>
        </div>
      </div>

      <div class="border-container-unique38 mb-4 flex-1">
        <div style="width: 100%;">
          <div class="d-flex justify-space-between">
            <div class="admin-header">
              <div class="d-flex">
                <img src="/images/icon/single-person.svg" height="25" class="icon-margin">
                <h1>Teachers</h1>
              </div>
            </div>
          </div>
          <div>
            <!-- <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p> -->
            <div class="d-flex align-start admin-stats-container">
              <div v-if="teachersLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="admin-stat">
                <div class="admin-table">
                  <div class="admin-table-td">
                    <div class="admin-list-title">Teacher Registered</div>
                    <div>
                      <p class="admin-stat-mins">{{ totalTeachers }} Teachers</p>
                    </div>
                  </div>
                </div>
              </div>                  
            </div>
          </div>
        </div>
      </div>

      <div class="border-container-unique38 mb-4 flex-1">
        <div style="width: 100%;">
          <div class="d-flex justify-space-between">
            <div class="admin-header">
              <div class="d-flex">
                <img src="/images/icon/single-person.svg" height="25" class="icon-margin">
                <h1>Schools</h1>
              </div>
            </div>
          </div>
          <div>
            <!-- <p class="fs-08">from {{ formattedStartDate }} to {{ formattedEndDate }}</p> -->
            <div class="d-flex align-start admin-stats-container">
              <div v-if="schoolsLoading" class="spinner-container">
                <div class="spinner"></div>
              </div>
              <div v-else style="width: 100%;" class="admin-stat">
                <div class="admin-table">
                  <div class="admin-table-td">
                    <div class="admin-list-title">School Registered</div>
                    <div>
                      <p class="admin-stat-mins">{{ totalSchools }} Schools</p>
                    </div>
                  </div>
                </div>
              </div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import cookiesService from '@/services/cookiesService';
import DashboardService from '@/services/dashboardService.js';

const user = ref(null);
const leaderboard = ref([]);
const totalResources = ref(0);
const totalSelfDirectedLearning = ref(0);
const totalLessonPackage = ref(0);
const totalStudents = ref(0);
const totalTeachers = ref(0);
const totalSchools = ref(0);

const leaderboardLoading = ref(true);
const resourcesLoading = ref(true);
const studentsLoading = ref(true);
const teachersLoading = ref(true);
const schoolsLoading = ref(true);

onMounted(() => {
  checkAuthToken();
  getAll();
});

const getAll = async () => {
  try {
    const response = await DashboardService.getDashboardStats();
    if (response.status === 200) {
      const data = response.data.data;
      leaderboard.value = data.leaderboard;
      totalResources.value = data.totalLessonPackage;
      totalSelfDirectedLearning.value = data.totalSelfDirectedLearning;
      totalLessonPackage.value = data.totalLessonPackage;
      totalStudents.value = data.totalStudent;
      totalTeachers.value = data.totalTeacher;
      totalSchools.value = data.totalSchool;

      leaderboardLoading.value = false;
      resourcesLoading.value = false;
      studentsLoading.value = false;
      teachersLoading.value = false;
      schoolsLoading.value = false;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
      user.value = authToken;
    }
  } catch (error) {
    console.error(error);
  }
};

const getOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};
</script>

<style scoped>
.icon-margin {
  margin-top: -5px;
  margin-right: 5px;
}

.left-panel,
.right-panel {
  padding: 20px;
  text-align: center;
}

.left-panel {
  width: 60%;
}

.right-panel {
  width: 40%;
}

@media (max-width: 1323px) {
  .left-panel,
  .right-panel {
    width: 100% !important;
  }

  .ysa-stats-container {
    flex-direction: column;
  }
}

.ysa-card-rank {
  flex: 1;
  background-color: #00925E;
  padding: 20px; 
  border-radius: 20px; 
  color: white; 
  font-family: Arial, sans-serif; 
  text-align: center;
}

.ysa-images {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.ysa-school-name {
  font-size: 18px; 
  font-weight: bold; 
  padding-bottom: 5px;
  border-bottom: 1px solid #00734A;
  min-height: 125px;
}

.ysa-school-point {
  font-size: 18px;
  font-weight: bold;
  padding-top: 5px;
  margin-bottom: 10px;
}

.ysa-school-rank {
  background-color: white;
  color: #00925E;
  font-size: 20px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;
}

.leaderboard-list {
  list-style-type: none; 
  padding: 0; 
  margin: 0;
}

.leaderboard-item {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  font-size: 20px; 
  font-weight: 600; 
  background-color: #F3F4F8; 
  padding: 10px 20px; 
  border-radius: 20px; 
  margin-bottom: 10px;
}

.leaderboard-content {
  display: flex; 
  gap: 10px;
}

.leaderboard-school {
  display: inline-block;
  max-width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.leaderboard-rank, .leaderboard-school, .leaderboard-points {
  margin: 0;
}

.admin-stat-mins {
  font-size: 24px;
  font-weight: bold;
}

.gap-10 {
  gap: 10px;
}
</style>
