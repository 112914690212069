<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/rectangle-4689.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Conducting a Maker Activity</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Role 1: Designing Suitable Activities</h2>
        <p class="fs-12 margin-10">
          Designing engaging and effective maker activities is paramount to successful maker education.  This section will explore key considerations for crafting activities that are both ambitious and attainable, while providing a framework for varying learner control and fostering deep thinking through the use of thinking routines.
        </p>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Role 2: Posing Questions</h2>
        <p class="fs-12 margin-10">
          Effective questioning is the cornerstone of meaningful learning. This section explores how to craft powerful questions that spark curiosity, ignite imagination, and guide learners towards deeper understanding during maker activities. We will examine the power of open-ended questions, explore different question types, and discuss strategies for facilitating productive discussions and inquiry.
        </p>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Role 3: Use Appropriate Language</h2>
        <p class="fs-12 margin-10">
          The language we use can significantly impact learner engagement and understanding. This section delves into the importance of clear, concise, and accessible language for maker educators. This is especially important when the maker educator notices a teachable moment, and respond with questions or prompts that promote deeper thought as well as socio-emotional learning (SEL) competencies.  
        </p>
      </div>
    </div>
    

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/5');
};

const goToNextPage = () => {
  router.push('/makercart-guide/7');
};
</script>

