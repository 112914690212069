<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Form Submissions</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title">List of Form Submissions</div>
      <div>You can view all of Form Submissions from this page.</div>

      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery" @input="debouncedgetAll">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>
      </div>
      <div class="admin-list-tool-container">
      </div>

      <div class="mb-3" style="display: flex;gap: 10px">
      </div>

      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th @click="toggleSortOrder('created_at')" class="sortable-column">
                <div class="sortable-header">
                  <span>Date</span>
                  <span>{{ sortOrderArrow('created_at') }}</span>
                </div>
              </th>
              <th @click="toggleSortOrder('name')" class="sortable-column">
                <div class="sortable-header">
                  <span>Name</span>
                  <span>{{ sortOrderArrow('name') }}</span>
                </div>
              </th>
              <th @click="toggleSortOrder('email')" class="sortable-column">
                <div class="sortable-header">
                  <span>Email</span>
                  <span>{{ sortOrderArrow('email') }}</span>
                </div>
              </th>
              <th>Telephone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="!tableLoading && data.data.rows.length > 0">
            <template v-for="(item, index) in data.data.rows" :key="item.id">
              <tr>
                <td style="max-width: 180px;word-break: break-all;">{{ formatDate(item.created_at) }}</td>
                <td style="max-width: 180px;word-break: break-all;">{{ item.name }}</td>
                <td style="max-width: 180px;word-break: break-all;">{{ item.email }}</td>
                <td style="max-width: 180px;word-break: break-all;">{{ item.telephone }}</td>
                <td style="max-width: 100px;word-break: break-all;cursor: pointer;">
                  <details @click="toggleExpand(index)">
                    <summary>View Message</summary>
                  </details>
                </td>
              </tr>
              <tr v-if="expandedIndex === index">
                <td colspan="5" style="padding: 0;">
                  <div style="padding: 10px; background-color: #f9f9f9;">{{ item.message }}</div>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else-if="!tableLoading && data.data.rows.length === 0">
            <tr>
              <td colspan="5">
                <div class="no-data-message">No data available</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.data.total_pages > 0" class="pagination-controls">
        <img @click="prevPage" src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img @click="nextPage" src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalDelete" @update:visible="updateVisibilityModalDelete">
    <div class="container-modal">
      <div class="modal-title">Remove Category?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1.4em;margin: 15px 0px;">Are you sure want to remove this category?</div>
      <div class="modal-button">
        <button class="btn btn-discard" @click="updateVisibilityModalDelete">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }" @click="remove(itemId)">Confirm</button>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
/* eslint-disable */ 
import { onMounted, ref, computed } from 'vue';
import FormSubmissionAdminService from '@/services/formSubmissionAdminService';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';

const showModalDelete = ref(false);
const isLoading = ref(false);
const itemId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");
const currentStatus = ref("");
const buttons = ref([
  { label: "All", clicked: true, status: "" },
  { label: "Edit", clicked: false, status: "edit" },
  { label: "Delete", clicked: false, status: "delete" },
  { label: "Create", clicked: false, status: "delete" },
  { label: "Copy", clicked: false, status: "copy" }
]);

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const currentPage = ref(1);
const expandedIndex = ref(null);
const sortOrder = ref('desc');
const sortKey = ref('created_at');

onMounted(async () => {
  await getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  window.addEventListener('resize', updateLayout);
  updateLayout();
});

const getAll = async (page, search, key, sort_by, order) => {
  try {
    tableLoading.value = true;

    const param = {page, search, key, sort_by, order}

    const response = await FormSubmissionAdminService.getAll(param);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const debouncedgetAll = debounce(() => {
  currentPage.value = 1;
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    changePage(currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < data.value.data.total_pages) {
    changePage(currentPage.value + 1);
  }
};

const visiblePages = computed(() => {
  const total = data.value.data.total_pages;
  const current = currentPage.value;
  const start = Math.max(1, current - 2);
  const end = Math.min(total, current + 2);

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

const openModal = (id) => {
  itemId.value = id; // Set itemId
  showModalDelete.value = true;
};

const updateVisibilityModalDelete = () => {
  showModalDelete.value = !showModalDelete.value;
};

const remove = async (id) => {
  isLoading.value = true;
  try {
    await CategoryAdminService.remove(id);
    isLoading.value = false;
    showModalDelete.value = false;
    getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  } catch (error) {
    isLoading.value = false;
  }
};

// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector('.admin-flex-container');
  const toolContainer = document.querySelector('.admin-list-tool-container');
  const parentContainer = document.querySelector('.border-container-unique37');
  const width = window.innerWidth;
  
  if (width <= 991) {
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};

const toggleExpand = (index) => {
  expandedIndex.value = expandedIndex.value === index ? null : index;
};

const toggleSortOrder = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortKey.value = key;
    sortOrder.value = 'asc';
  }
  
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
};

const sortOrderArrow = (key) => {
  if (sortKey.value !== key) return '';
  return sortOrder.value === 'asc' ? '▲' : '▼';
};

function formatDate(dateTimeString) {
    // Create a new Date object from the date-time string
    const date = new Date(dateTimeString);

    // Get the year, month, and day from the Date object
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getUTCDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
}
</script>