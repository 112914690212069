<template>
  <div>
    <div class="tabs">
      <span class="tab" :class="{ active: activeTab === 'stem-activities' }"
        @click="setActiveTab('stem-activities')">Makercart Activities</span>
      <span class="tab" :class="{ active: activeTab === 'user-generated-resources' }"
        @click="setActiveTab('user-generated-resources')">EdTech Studio Lessons</span>
      <span class="tab" :class="{ active: activeTab === 'favorite-resources' }"
        @click="setActiveTab('favorite-resources')">Favorite Resources</span>
    </div>

    <div class="content">
      <!-- STEM Activities Tab -->
      <div v-if="activeTab === 'stem-activities'">
        <div v-if="isLoading" class="loading-state">
          <p>Loading STEM Activities...</p>
        </div>
        <div v-else>
          <div v-if="stemActivities && stemActivities.length === 0">
            <p>No STEM Activities available.</p>
          </div>
          <div v-else>
            <STEMActivityCard v-for="(activity, index) in stemActivities" :key="index" :activity="activity"  @updateVisibilityModalMakerCartDetail="updateVisibilityModalMakerCartDetail"/>
          </div>
          <!-- Pagination Controls -->
          <div v-if="totalPages > 0" class="pagination-controls mb-5">
            <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="setCurrentPage(currentPage - 1)"
              :disabled="currentPage === 1">
            <button v-for="page in pagesToShow" :key="page" @click="setCurrentPage(page)"
              :class="{ 'page-active': page === currentPage }">
              {{ page }}
            </button>
            <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="setCurrentPage(currentPage + 1)"
              :disabled="currentPage === totalPages">
          </div>
        </div>
      </div>

      <!-- User Generated Resources Tab -->
      <div v-if="activeTab === 'user-generated-resources'">
        <div v-if="isLoading" class="loading-state">
          <p>Loading User Generated Resources...</p>
        </div>
        <div v-else>
          <div v-if="userGeneratedResources && userGeneratedResources.length === 0">
            <p>No User Generated Resources available.</p>
          </div>
          <div v-else>
            <UserGeneratedResourceCard v-for="(resource, index) in userGeneratedResources" :key="index"
              :resource="resource" @like="likeLessonPackage"/>
            <!-- Pagination Controls -->
            <div v-if="totalPages > 0" class="pagination-controls mb-5">
              <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="setCurrentPage(currentPage - 1)"
                :disabled="currentPage === 1">
              <button v-for="page in pagesToShow" :key="page" @click="setCurrentPage(page)"
                :class="{ 'page-active': page === currentPage }">
                {{ page }}
              </button>
              <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="setCurrentPage(currentPage + 1)"
                :disabled="currentPage === totalPages">
            </div>
          </div>
        </div>
      </div>

      <!-- Favorite Resources Tab -->
      <div v-if="activeTab === 'favorite-resources'">
        <div v-if="isLoading" class="loading-state">
          <p>Loading Favorite Resources...</p>
        </div>
        <div v-else>
          <div v-if="userGeneratedResources && userGeneratedResources.length === 0">
            <p>No Favorite Resources available.</p>
          </div>
          <div v-else>
            <FavoriteResourceCard v-for="(favorite, index) in userGeneratedResources" :key="index"
              :favorite="favorite" @like="likeLessonPackage" @like-stem-studio="likeStemStudio" @updateVisibilityModalMakerCartDetail="updateVisibilityModalMakerCartDetail"/>
            <!-- Pagination Controls -->
            <div v-if="totalPages > 0" class="pagination-controls mb-5">
              <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="setCurrentPage(currentPage - 1)"
                :disabled="currentPage === 1">
              <button v-for="page in pagesToShow" :key="page" @click="setCurrentPage(page)"
                :class="{ 'page-active': page === currentPage }">
                {{ page }}
              </button>
              <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="setCurrentPage(currentPage + 1)"
                :disabled="currentPage === totalPages">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MakerCartDetail ref="makerCartDetail" mode="detail"></MakerCartDetail>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import UserService from '@/services/userService';
import cookiesService from '@/services/cookiesService';
import StemStudioService from '@/services/stemStudioService'; // Import service untuk STEM activities
import STEMActivityCard from './STEMActivityCard.vue'; // Import STEM Activity Card
import UserGeneratedResourceCard from './UserGeneratedResourceCard.vue'; // Import User Generated Resource Card
import FavoriteResourceCard from './FavoriteResourceCard.vue'; // Import Favorite Resource Card
import MakerCartDetail from '@/components/maker-cart/MakerCartDetail.vue';

const activeTab = ref('user-generated-resources');
const isLoading = ref(false);
const stemActivities = ref([]);
const userGeneratedResources = ref([]);
const currentPage = ref(1); // Set current page
const totalPages = ref(0);
const makerCartDetail = ref(null); 

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get('tab');

  // Check for the 'tab' parameter and navigate to the corresponding tab
  if (tab === 'stem-activities') {
    setActiveTab('stem-activities');
  } else {
    setActiveTab('user-generated-resources');
  }
});

// Function to fetch STEM activities
const getStemActivities = async (page = 1, limit = 5) => {
  try {
    isLoading.value = true;
    const response = await StemStudioService.getMakercartOrders({ page, limit });
    if (response.status === 200) {
      stemActivities.value = response.data.data.rows;
      totalPages.value = response.data.data.total_pages;
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

// Function to fetch profile lesson packages with pagination
const getProfileLessonPackages = async (page = 1, limit = 5, favorite = false) => {
  try {
    isLoading.value = true;
    const authToken = await cookiesService.getCookie("authToken");
    if (!authToken) {
      throw new Error("Auth token is not available");
    }

    const response = await UserService.getProfileLessonPackages(authToken.token, page, limit, favorite);
    if (response.status === 200) {
      userGeneratedResources.value = response.data.data.rows;
      totalPages.value = response.data.data.total_pages;
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

// Function to set the active tab and reset pagination
const setActiveTab = (tabName) => {
  activeTab.value = tabName;
  currentPage.value = 1; // Reset the page to 1 when switching tabs
  if (tabName === 'user-generated-resources') {
    getProfileLessonPackages(1, 5, false); // Fetch user-generated resources
  } else if (tabName === 'favorite-resources') {
    getProfileLessonPackages(1, 5, true); // Fetch favorite resources
  } else if (tabName === 'stem-activities') {
    getStemActivities(1, 5); // Fetch STEM activities
  }
};

// Function to set the current page and reload data
const setCurrentPage = (page) => {
  if (page !== currentPage.value && page > 0 && page <= totalPages.value) {
    currentPage.value = page;
    if (activeTab.value === 'user-generated-resources') {
      getProfileLessonPackages(page, 5, false);
    } else if (activeTab.value === 'favorite-resources') {
      getProfileLessonPackages(page, 5, true);
    } else if (activeTab.value === 'stem-activities') {
      getStemActivities(page, 5);
    }
  }
};

// Computed property to generate pagination buttons
const pagesToShow = computed(() => {
  const pages = [];
  for (let i = 1; i <= totalPages.value; i++) {
    pages.push(i);
  }
  return pages;
});

// eslint-disable-next-line
const likeLessonPackage = async (id) => {
  try {
    isLoading.value = true; // Set isLoading menjadi true ketika mulai request
    const authToken = await cookiesService.getCookie("authToken");
    if (!authToken) {
      throw new Error("Auth token is not available");
    }

    const response = await UserService.likeLessonPackage(authToken.token, id);
    if (response.status === 200) {
      setActiveTab('favorite-resources');
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false; // Set isLoading menjadi false setelah request selesai
  }
};

const likeStemStudio = async (id) => {
  try {
    const response = await StemStudioService.likeMakercart(id);
    if (response.status === 200) {
      if (response.status === 200) {
        setActiveTab('favorite-resources');
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }
  } catch (err) {
    alert('Error posting cart data:', err.message);
  }
};

function updateVisibilityModalMakerCartDetail({visible, id, stem_makercart_order_id}) {
  makerCartDetail.value.updateVisibilityModal(visible, id, stem_makercart_order_id);
}
</script>

<style scoped>
.content {
  margin-top: 16px;
  font-size: 16px;
  color: #333;
}

.loading-state {
  text-align: center;
}

.activity-card,
.resource-card,
.favorite-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.activity-card h3,
.resource-card h3,
.favorite-card h3 {
  margin-bottom: 8px;
}
</style>
