<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/rectangle-4689.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Conducting a Maker Activity</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="margin-10" style="width: 80%;">
        <h2 class="color-primary margin-10">Role 1: Designing Suitable Activities</h2>
        <p class="fs-12 margin-10">
          Designing engaging and effective maker activities is paramount to successful maker education.  This section will explore key considerations for crafting activities that are both ambitious and attainable, while providing a framework for varying learner control and fostering deep thinking through the use of thinking routines.
        </p>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <div class="x-title-container">
          <h2 v-if="currentCardIndex === 0" class="color-primary margin-10">Set Activity to be Ambitious Yet Attainable, Scaffold</h2>
          <h2 v-if="currentCardIndex === 1" class="color-primary margin-10">Give Students Enough Time to Communicate, Fail, Iterate, and Come Out with the Final Product</h2>
          <h2 v-if="currentCardIndex === 2" class="color-primary margin-10">Vary the Learner Control</h2>
          <h2 v-if="currentCardIndex === 3" class="color-primary margin-10">Use Thinking Routines</h2>
          <div class="x-arrows">
            <button id="previous-button" @click="goToPreviousCard" :disabled="currentCardIndex === 0">
              <img class="x-arrow x-arrow-left" :class="{ 'x-arrow-disabled': currentCardIndex === 0 }" src="/images/icon/arrow-square-up.svg" alt="Previous">
            </button>
            <button id="next-button" @click="goToNextCard" :disabled="currentCardIndex === 3">
              <img class="x-arrow x-arrow-right" :class="{ 'x-arrow-disabled': currentCardIndex === 3 }" src="/images/icon/arrow-square-up.svg" alt="Next">
            </button>
          </div>
        </div>
        <div class="x-underline"></div>

        <!-- Card 1 Content -->
        <div v-if="currentCardIndex === 0">
          <p class="fs-12 margin-1b">
            A rule of thumb while designing Maker Projects could be “Low Floor, High Ceiling, Wide Wall”. Starter activities should be easy for beginners, but should also create opportunities for students to be involved in increasingly complex projects over time. Maker projects also need to support the diverse interests and learning styles of our students by providing platforms for them to engage with the material in different ways, so that it is engaging and meaningful for all.
          </p>
          <p class="fs-12 margin-1b">
            Multi-week programs could have students start off with small activities during which there is more structure, instructions to help introduce a skill or concept, before moving on to prompted challenges, during which students are expected to explore the design process to propose solutions to different problems. Educators can then provide a more open-ended environment in which they can drive their own projects they are more interested in.
          </p>
        </div>

        <!-- Card 2 Content -->
        <div v-if="currentCardIndex === 1">
          <p class="fs-12 margin-1b">
            Time management is crucial in classroom activity. Many teachers we work with tell us they have limited time to implement Maker programs in their schools, except as enrichment programs or very brief recess time activities. Even so, there is a need to make sure the students succeed on their own merits (versus feeling that they only succeeded because the teacher helped them) so that they can feel the sense of satisfaction and joy of learning. Hence, the struggle and failures in between are very critical.
          </p>
        </div>

        <!-- Card 3 Content -->
        <div v-if="currentCardIndex === 2">
          
          <ol class="margin-1b">
            <li class="sm-font">Who has control of the question or problem being addressed</li>
            <li class="sm-font">Who has control over the procedure or aspects of the procedure (for example, what materials to use, the way in which data are recorded)?</li>
            <li class="sm-font">Who has control over the outcome? (Is it an open-ended problem with many possible solutions, or is there only one right answer?)</li>
          </ol>

          <div class="margin-1b">
            <p class="fs-12">As learners become more self-directed, how does the teacher's role change? It's important for participants to realize that less control by the teacher does not mean giving up responsibility for guiding learning.</p> 
            <p class="fs-12">As the teacher turns over more control to the learner, the teacher's role changes but doesn't diminish. The teacher continues to play a very active role, determining what kinds of experiences to provide, the topic of study, the prompts to get learners started, what materials will be used, and so on.</p>
          </div>

          <div class="margin-1b">
            <p class="fs-12">Most importantly, the teacher still provides facilitation to students during the activity.</p>
          </div>
            
        </div>

        <!-- Card 4 Content -->
        <div v-if="currentCardIndex === 3">

          <div class="margin-1b">
            <p class="fs-12">Routines exist in all classrooms; Classrooms have routines that serve to manage student behavior and interactions, to organizing the work of learning, and to establish rules for communication and discourse. Classrooms also have routines that structure the way students go about the process of learning.</p> 
          </div>

          <div class="margin-1b">
            <p class="fs-12">The following routines are designed to assist teachers or leaders in implementing an environment of learning in which learners and teachers make their thinking 'visible' or explicit.</p>
          </div>

          <div class="margin-1b">
            <p class="fs-12">These are adapted from <strong>Project Zero</strong> at the Harvard Graduate School of Education. (Detailed descriptions of each follows.)</p>
          </div>

          <div class="tabs">
              <span class="tab" :class="{  'tab-chinaus-active-primary' : activeTab === 'synthesizing-and-organizing-ideas' }" @click="setActiveTab('synthesizing-and-organizing-ideas')" >Synthesizing and Organizing Ideas</span>
              <span class="tab" :class="{  'tab-chinaus-active-primary' : activeTab === 'digging-deeper-into-ideas' }" @click="setActiveTab('digging-deeper-into-ideas')" >Digging Deeper into Ideas</span>
              <span class="tab" :class="{  'tab-chinaus-active-primary' : activeTab === 'developing-sensitivity-to-design' }" @click="setActiveTab('developing-sensitivity-to-design')" >Developing Sensitivity to Design</span>
              <span class="tab" :class="{  'tab-chinaus-active-primary' : activeTab === 'introducing-ideas' }" @click="setActiveTab('introducing-ideas')" >Introducing ideas</span>
          </div>

          <div v-if="activeTab === 'synthesizing-and-organizing-ideas'" style="padding: 0.5rem 0;">
            <div class="admin-table-container">
              <table class="admin-table">
                <thead>
                  <tr>
                    <th class="text-center" colspan="3">Developing Sensitivity to Design</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Routines</td>
                    <td>Type of thinking involved</td>
                    <td>Steps</td>
                  </tr>
                  <tr>
                    <td>CSI: Color, Symbol, Image</td>
                    <td>Capturing the 'heart' of an idea or concept through metaphors and visual connections.</td>
                    <td>
                      <ol>
                        <li class="fs-08">As you are reading/listening/watching, make note of things that you find interesting, important, or insightful. When you finish, choose 3 of these items that most stand out for you.</li>
                        <ul>
                          <li class="fs-08">For one of these, choose a colour that you feel best represents or captures the essence of that idea.</li>
                          <li class="fs-08">For another one, build a symbol that you feel best represents or captures the essence of that idea.</li>
                          <li class="fs-08">For the other one, choose an image that you feel best represents or captures the essence of that idea.</li>
                        </ul>
                        <li class="fs-08">With a partner or group first share your colour and then share the item from your reading that it represents. Tell why you choose that colour as a representation of that idea. Repeat the sharing process until every member of the group has shared his or her Colour, Symbol, and Image.</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Micro-Lab Protocol</td>
                    <td>Individual and group exercise to focus attention, reflect on and analyze a topic.</td>
                    <td>
                      <ol>
                        <li class="fs-08">Identify a topic or question for discussion.</li>
                        <li class="fs-08">Students build a model to represent their thoughts.</li>
                        <li class="fs-08">Divide the students into groups of 3 or 4. Each group counts off.</li>
                        <li class="fs-08">All of the #1's in each group share ideas for 1-2 minutes.</li>
                        <li class="fs-08">Call for silence for 20-30 seconds for everyone to think about what was said.</li>
                        <li class="fs-08">Repeat for each person in the groups.</li>
                        <li class="fs-08">Allow 5-10 minutes for whole group discussion.</li>
                        <li class="fs-08">Each group shares with the entire class.</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>I Used to Think...; Now I Think...</td>
                    <td>Reflect on how one's thinking has changed</td>
                    <td>
                      <ol>
                        <li class="fs-08">Have students write a response (or build a model) using each of the sentence stems:</li>
                        <ul>
                          <li class="fs-08">I used to think...</li>
                          <li class="fs-08">But now, I think...</li>
                        </ul>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="activeTab === 'digging-deeper-into-ideas'" style="padding: 0.5rem 0;">
            <div class="admin-table-container">
              <table class="admin-table">
                <thead>
                  <tr>
                    <th class="text-center" colspan="3">Developing Sensitivity to Design</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Routines</td>
                    <td>Type of thinking involved</td>
                    <td>Steps</td>
                  </tr>
                  <tr>
                    <td>Tug of War</td>
                    <td>Perspective taking, reasoning, identifying complexities</td>
                    <td>
                      <ol>
                        <li class="fs-08">First, present students with a fairness dilemma. Present an actual rope to illustrate the controversy being discussed.</li>
                        <li class="fs-08">Second, identify the controversy, which factors are “pulling” at either side of the dilemma.</li>
                        <li class="fs-08">Third, engage students by asking them to think about why each factor is “tugging” at one side or the other of the dilemma, identifying the strongest arguments. Perhaps ask students to decide which side they would most likely choose themselves.</li>
                        <li class="fs-08">Lastly, push students further by asking “what if” questions, encouraging them to explore the topic more completely.</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Step Inside</td>
                    <td>Practicing taking the perspective of another point of view.</td>
                    <td>
                      <p class="fs-12">Build a model that represents:</p>
                      <ul>
                        <li class="fs-08">What can the person or thing perceive?</li>
                        <li class="fs-08">What might the person or thing know about or believe?</li>
                        <li class="fs-08">What might the person or thing care about?</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Chalk Talk</td>
                    <td>Brainstorming ideas, questions or plans regarding a topic or problem.</td>
                    <td>
                      <ol>
                        <li class="fs-08">What ideas come to mind when you think about this idea, question, or topic? Build a model that represents that.</li>
                        <li class="fs-08">What connections can you make to others’ thoughts?</li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="activeTab === 'developing-sensitivity-to-design'" style="padding: 0.5rem 0;">
            <div class="admin-table-container">
              <table class="admin-table">
                <thead>
                  <tr>
                    <th class="text-center" colspan="3">Developing Sensitivity to Design</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Routines</td>
                    <td>Type of thinking involved</td>
                    <td>Steps</td>
                  </tr>
                  <tr>
                    <td>Parts, Purposes, Complexities</td>
                    <td>Make careful and detailed observations</td>
                    <td>
                      <p class="fs-12">Choose an object or system and ask:</p>
                      <ul>
                        <li class="fs-08">What are its parts? What are its various pieces or components?</li>
                        <li class="fs-08">What are its purposes? What are the purposes for each of these parts?</li>
                        <li class="fs-08">What are its complexities? How is it complicated in its parts and purposes, the relationship between the two, or in other ways?</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Parts, People, Interactions</td>
                    <td>Stimulate curiosity, raises questions, surfaces areas for further inquiry, and introduces systems thinking.</td>
                    <td>
                      <p class="fs-12">Identify a system and ask:</p>
                      <ul>
                        <li class="fs-08">What are the parts of the system?</li>
                        <li class="fs-08">Who are the people connected to the system?</li>
                        <li class="fs-08">How do the people in the system interact with each other and with the parts of the system?</li>
                        <li class="fs-08">How does a change in one element of the system affect the various parts and people connected to the system?</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Parts, Perspectives, Me</td>
                    <td>Consider its various viewpoints, users, and stakeholders, and reflecting on their own connections and involvement with it</td>
                    <td>
                      <p class="fs-12">Choose an object or system and ask:</p>
                      <ul>
                        <li class="fs-08">What are its parts? What are its various pieces or components?</li>
                        <li class="fs-08">What perspectives can you look at it from? Different users, makers; different physical perspectives.</li>
                        <li class="fs-08">How are you involved? What connections do you have? What assumptions, interests, or personal circumstances shape the way you see it?</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Think, Feel, Care</td>
                    <td>Perspective taking, raises questions, and surfaces areas for further inquiry</td>
                    <td>
                      <p class="fs-12">Choose a variety of people within a system and then step inside each person's point of view. As you think about what you know about the system, consider what each person might think, feel, and care about:</p>
                      <ul>
                        <li class="fs-08"><b>Think:</b> How does this person understand this system and their role within it?</li>
                        <li class="fs-08"><b>Feel:</b> What is this person's emotional response to the system and to their position within it?</li>
                        <li class="fs-08"><b>Care:</b> What are this person's values, priorities, or motivations with regard to the system? What is important to this person?</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Imagine if...</td>
                    <td>Encourages divergent and convergent thinking; finding opportunity and pursuing new ideas</td>
                    <td>
                      <p class="fs-12">Consider the parts, purposes, and people who interact with your object or system, and then ask:</p>
                      <ol>
                        <li class="fs-08">In what ways could it be made to be more effective?</li>
                        <li class="fs-08">In what ways could it be made to be more efficient?</li>
                        <li class="fs-08">In what ways could it be made to be more ethical?</li>
                        <li class="fs-08">In what ways could it be made to be more beautiful?</li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="activeTab === 'introducing-ideas'" style="padding: 0.5rem 0;">
            <div class="admin-table-container">
              <table class="admin-table">
                <thead>
                  <tr>
                    <th class="text-center" colspan="3">Developing Sensitivity to Design</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Routines</td>
                    <td>Type of thinking involved</td>
                    <td>Steps</td>
                  </tr>
                  <tr>
                    <td>See-Think (Build) - Wonder</td>
                    <td>Wondering about and describing something ambiguous, complex or new.</td>
                    <td>
                      <ol>
                        <li class="fs-08">What do you see (this can be an artwork, image, artifact or topic)?</li>
                        <li class="fs-08">What do you think about that? Build a model that represents it.</li>
                        <li class="fs-08">What does it make you wonder?</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Think (Build) - Puzzle - Explore</td>
                    <td>Helping students identify what they already know or think about a topic and what they are wondering.</td>
                    <td>
                      <ol>
                        <li class="fs-08">What do you think you know about this topic? Build a model that represents what you think you know about this topic.</li>
                        <li class="fs-08">What questions or puzzles do you have?</li>
                        <li class="fs-08">How can you explore this topic?</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Chalk Talk</td>
                    <td>Brainstorming ideas, questions or plans regarding a topic or problem.</td>
                    <td>
                      <ol>
                        <li class="fs-08">What ideas come to mind when you think about this idea, question or topic? Build a model that represents that.</li>
                        <li class="fs-08">What connections can you make to others' thoughts?</li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>  
        </div>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();
const activeTab = ref('synthesizing-and-organizing-ideas');

const currentCardIndex = ref(0);

const goToPreviousCard = () => {
  if (currentCardIndex.value > 0) {
    currentCardIndex.value--;
  }
};

const setActiveTab = (tabName) => {
  activeTab.value = tabName;
}

const goToNextCard = () => {
    currentCardIndex.value++;
};

const goToPreviousPage = () => {
  router.push('/makercart-guide/6');
};

const goToNextPage = () => {
  router.push('/makercart-guide/8');
};
</script>

<style scoped>
.tab-chinaus-active-primary {
    border-bottom: 2px solid #003D6A;
    color: #003D6A;
}</style>