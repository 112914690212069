<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Add Teacher</h1>
    </div>
    <div class="tabs">
      <span 
        class="tab" 
        :class="{ 'tab-active-primary': activeTab === 'single' }" 
        @click="changeTab('single')">
        Add Single Teacher
      </span>
      <span 
        class="tab" 
        :class="{ 'tab-active-primary': activeTab === 'bulk' }" 
        @click="changeTab('bulk')">
        Add Bulk Teachers
      </span>
    </div>

    <div v-if="activeTab === 'single'" class="border-container-unique37">
      <div>Add a new teacher to your platform, allowing them to collaborate with you.</div> 
      
      <form @submit.prevent="validateFormSingle" novalidate>
        <div class="admin-form-group mb-3">
          <label>School Name</label>
          <SelectSearchComponent :items="data.data" v-model="formSingle.school_id"></SelectSearchComponent>
          <span v-if="errorsSingle.school_id" class="error-message-123">{{ errorsSingle.school_id }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Email</label>
          <input type="text" v-model="formSingle.email" required />
          <span v-if="errorsSingle.email" class="error-message-123">{{ errorsSingle.email }}</span>
        </div>
        <div class="flex justify-content-end">
          <button type="submit" class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoadingSingle, 'btn-save': !isLoadingSingle }" :disabled="isLoadingSingle" :style="{ 'background-color': isLoadingSingle ? '#CCCCCC' : '#003D6A' }">Save</button>
        </div>
      </form>
    </div>
    
    <div v-if="activeTab === 'bulk'" class="border-container-unique37">
      <div>Add multiple teachers to your platform by uploading an XLSX file.</div> <a href="https://storage.eddy4teachers.com/files/admin/20240726115624_teacher_upload_template.xlsx" target="_blank" rel="noopener noreferrer">Download template file here</a>
      
      <form @submit.prevent="validateFormBulk" novalidate>
        <div class="admin-form-group mb-3">
          <label>School Name</label>
          <SelectSearchComponent :items="data.data" v-model="formBulk.school_id"></SelectSearchComponent>
          <span v-if="errorsBulk.school_id" class="error-message-123">{{ errorsBulk.school_id }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Upload XLSX</label>
          <input id="file-input" type="file" @change="handleFileUpload" required />
          <span v-if="errorsBulk.file" class="error-message-123">{{ errorsBulk.file }}</span>
        </div>
        <div class="flex justify-content-end">
          <button type="submit" class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoadingBulk, 'btn-save': !isLoadingBulk }" :disabled="isLoadingBulk" :style="{ 'background-color': isLoadingBulk ? '#CCCCCC' : '#003D6A' }">Upload</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import UserAdminService from '@/services/userAdminService';
import TermAdminService from '@/services/termAdminService';
import SelectSearchComponent from '@/components/common/SelectSearchComponent.vue';

const router = useRouter();
const activeTab = ref('single');
const appURL = computed(() => `${process.env.VUE_APP_URL}/verify-account`);

const formSingle = reactive({
  school_id: '',
  email: '',
  redirectTo: appURL
});

const errorsSingle = reactive({
  school_id: '',
  email: ''
});

const formBulk = reactive({
  school_id: '',
  file: null
});

const errorsBulk = reactive({
  file: '',
  school_id: ''
});

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const isLoadingSingle = ref(false);
const isLoadingBulk = ref(false);

onMounted(async () => {
  await getAll();
});

const getAll = async () => {
  try {
    const param = { limit: 99999 }

    const response = await TermAdminService.getAllSchool(param);
    if (response.status === 200) {
      data.value = response.data;
      console.log('data.value', data.value.data)
    }
  } catch (error) {
    console.log(error.message);
  }
};

const validateFormSingle = async () => {
  // Reset errors
  Object.keys(errorsSingle).forEach(key => {
    errorsSingle[key] = '';
  });

  let isValid = true;

  // Validate required fields
  if (!formSingle.school_id) {
    errorsSingle.school_id = 'School is required';
    isValid = false;
  }
  if (!formSingle.email) {
    errorsSingle.email = 'Email is required';
    isValid = false;
  }

  if (!isValid) {
    return;
  }

  isLoadingSingle.value = true;

  try {
    await UserAdminService.inviteTeacher(formSingle);
    alert('Teacher Invited successfully!');
    formSingle.school_id = '';
    formSingle.email = '';
    router.push('/admin/invite-teacher');
  } catch (error) {
    console.error(error.message);
    alert('Failed to submit form! Error: ' + error?.response?.data?.message);
  } finally {
    isLoadingSingle.value = false;
  }
};

const validateFormBulk = async () => {
  // Reset errors
  errorsBulk.file = '';
  errorsBulk.school_id = '';

  let isValid = true;

  // Validate required fields
  if (!formBulk.file) {
    errorsBulk.file = 'File is required';
    isValid = false;
  } else if (!formBulk.file.name.endsWith('.xlsx')) {
    errorsBulk.file = 'Only .xlsx files are allowed';
    isValid = false;
  }

  if (!formBulk.school_id) {
    errorsBulk.school_id = 'School is required';
    isValid = false;
  }

  if (!isValid) {
    return;
  }

  isLoadingBulk.value = true;

  const formData = new FormData();
  formData.append('schoolId', formBulk.school_id);
  formData.append('file', formBulk.file);
  formData.append('redirectTo', appURL.value);

  try {
    await UserAdminService.bulkInviteTeachers(formData);
    alert('Teachers uploaded successfully!');
    // Reset the form fields
    formBulk.school_id = '';
    formBulk.file = null;

    // Reset the input file element
    document.getElementById('file-input').value = '';

    router.push('/admin/invite-teacher');
  } catch (error) {
    console.error(error.message);
    alert('Failed to submit form! Error: ' + error?.response?.data?.message);
  } finally {
    isLoadingBulk.value = false;
  }
};

const handleFileUpload = (event) => {
  formBulk.file = event.target.files[0];
};

const changeTab = (tab) => {
  activeTab.value = tab;
};
</script>

<style scoped>
.input-container-333 {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    width: 100%;
}

.loading-state {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #999;
}

.error-state {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: red;
}

.tab {
    cursor: pointer;
}

.tab-active-primary {
    font-weight: bold;
}
</style>
