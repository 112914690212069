<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Scorm File</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title">List of Scorm File</div>
      <div>You can add and delete all of Scorm File from this page.</div>
      
      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery" @input="debouncedgetAll">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>
        <a class="btn btn-create-list mx-2 my-md-auto" href="/admin/scorm/form">
          <span class="icon"><img src="/images/icon/plus-circle.svg" alt="download-icon"></span>
          <span class="admin-button-text">Add Scorm File</span>
        </a>
      </div>
      <div class="admin-list-tool-container"></div>

      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="!tableLoading && data.data.length > 0">
            <tr v-for="(item, index) in data.data" :key="index">
              <td>{{ item.created_at }}</td>
              <td>
                <a target="_blank" style="color: blue;text-decoration: underline;" :href="`https://scorm.eddy4teachers.com/scorm/${item.uuid}/910`">{{ item.title }}</a>
              </td>
              <td>
                <div class="admin-action-column">
                  <a href="#" @click="openModal(item.uuid)"><img src="/images/admin/trash.svg" alt="Delete"></a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!tableLoading && data.data.length === 0">
            <tr>
              <td colspan="3">
                <div class="no-data-message">No data available</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.last_page > 0" class="pagination-controls">
        <img @click="prevPage" src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img @click="nextPage" src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalDelete" @update:visible="updateVisibilityModalDelete">
    <div class="container-modal">
      <div class="modal-title">Remove Scorm File?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1.4em;margin: 15px 0px;">Are you sure you want to remove this Scorm File?</div>
      <div class="modal-button">
        <button class="btn btn-discard" @click="updateVisibilityModalDelete">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }" @click="remove(itemId)">Confirm</button>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
/* eslint-disable */ 
import { onMounted, ref, computed } from 'vue';
import ScormFilesService from '@/services/scormFilesService';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';

const showModalDelete = ref(false);
const isLoading = ref(false);
const itemId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");
const currentStatus = ref(""); // Menyimpan status pengguna saat ini

const data = ref({
    current_page: 0,
    data: [],
    last_page: 0,
    total: 0,
  }
);

const currentPage = ref(1);

onMounted(async () => {
  await getAll(currentPage.value, searchQuery.value, currentStatus.value);
  window.addEventListener('resize', updateLayout);
  updateLayout();
});

const getAll = async (page, search) => {
  try {
    tableLoading.value = true;

    const param = {page, search}

    const response = await ScormFilesService.getAll(param);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const debouncedgetAll = debounce(() => {
  currentPage.value = 1;
  getAll(currentPage.value, searchQuery.value, currentStatus.value);
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.last_page) {
    getAll(page, searchQuery.value, currentStatus.value);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    changePage(currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < data.value.data.last_page) {
    changePage(currentPage.value + 1);
  }
};

const visiblePages = computed(() => {
  const total = data.value.last_page;
  const current = currentPage.value;
  const start = Math.max(1, current - 2);
  const end = Math.min(total, current + 2);

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

const openModal = (id) => {
  itemId.value = id; // Set itemId
  showModalDelete.value = true;
};

const updateVisibilityModalDelete = () => {
  showModalDelete.value = !showModalDelete.value;
};

const remove = async (id) => {
  isLoading.value = true;
  try {
    await ScormFilesService.remove(id);
    isLoading.value = false;
    showModalDelete.value = false;
    getAll(currentPage.value, searchQuery.value, currentStatus.value); // Refresh the list
    alert('Scorm File deleted successfully');
  } catch (error) {
    isLoading.value = false;
    console.error(error.message);
    alert('Failed to delete Scorm File');
  }
};

// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector('.admin-flex-container');
  const toolContainer = document.querySelector('.admin-list-tool-container');
  const parentContainer = document.querySelector('.border-container-unique37');
  const width = window.innerWidth;
  
  if (width <= 991) {
    // Memindahkan toolContainer di bawah admin-flex-container
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    // Memindahkan toolContainer kembali ke dalam admin-flex-container
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};
</script>
