<template>
    <div class="border-container-unique38 mb-4">
        <div class="admin-content-layout">
            <div class="admin-main-content" style="padding-top: 0px;max-width: 100%;padding-right: 0px;overflow-x: scroll;">
                <div class="d-flex justify-space-between">
                    <div class="admin-header">
                        <h1><img src="/images/icon/user.svg" class="filter-primary" height="20" alt=""> Active Users</h1>
                    </div>
                    <button v-if="errorActiveUsers" @click="getActiveUsers" class="btn btn-retry">Retry</button>
                </div>
                <div v-if="loadingActiveUsers" class="loading-state">Loading...</div>
                <div v-else-if="errorActiveUsers" class="error-state">Error: {{ errorActiveUsers }}</div>

                <div v-else >
                    <p class="admin-stat-mins">{{ totalActiveUsers }}</p>
                    <p class="fs-08">Users per minute</p>
                    <div class="country-row no-gutters gc-2">
                        <div v-for="country in activeUsers" :key="country.country" class="country-column mb-2">
                            <div class="d-flex justify-space-between border-default">
                                <div class="fs-11">{{ country.country }}</div>
                                <div class="fs-11">{{ country.activeUsers }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import DashboardService from '@/services/dashboardService.js';

const activeUsers = ref([]);
const totalActiveUsers = ref(0);
const loadingActiveUsers = ref(true);
const errorActiveUsers = ref(null);

const getActiveUsers = async () => {
    loadingActiveUsers.value = true;
    errorActiveUsers.value = null;

    try {
        const response = await DashboardService.getActiveUsers();
        if (response.status === 200) {
            activeUsers.value = response.data.data.listCountries;
            totalActiveUsers.value = response.data.data.totalActiveUsers;
        } else {
            errorActiveUsers.value = response.message;
        }
    } catch (error) {
        errorActiveUsers.value = error.message;
    } finally {
        loadingActiveUsers.value = false;
    }
};

onMounted(() => {
    getActiveUsers();
});
</script>
