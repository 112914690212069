<template>
  <div v-if="props.mode === 'admin'" :style="{ height: props.topSectionHeight }" class="navbar-admin px-2">
    <div v-for="item in menuItems" :key="item.label" class="nav-item">
      <a :href="item.route || item.url" class="nav-link">
        <img :src="item.icon" alt="menu icon" class="nav-icon filter-white" />
        <span class="nav-label">{{ item.label }}</span>
      </a>
    </div>
  </div>

  <div v-else :style="{ height: props.topSectionHeight }" class="navbar-client px-2">
    <div v-for="item in menuItemsClient" :key="item.label" class="nav-item">
      <a :href="item.route || item.url" :target="item.url ? '_blank' : '_self'" class="nav-link">
        <img :src="item.icon" alt="menu icon" class="nav-icon filter-white" />
        <span class="nav-label">{{ item.label }}</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';

const props = defineProps({
  topSectionHeight: String,
  mode: {
    type: String,
    default: 'admin',
  },
});

const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);

const menuItems = ref([
  {
    icon: "/images/icon/manage.svg",
    label: "Visit Site",
    route: '/'
  },
  {
    icon: "/images/sidebar-menu/user-list.svg",
    label: "Users List",
    route: '/admin/users'
  },
  {
    icon: "/images/sidebar-menu/lesson-plan.svg",
    label: "Lesson Plans",
    url: `${appLoginUrl.value}/admin/lesson`
  },
  {
    icon: "/images/sidebar-menu/maker-resources.svg",
    label: "Maker Resources",
    url: `${appLoginUrl.value}/admin/maker-resources`
  },
  {
    icon: "/images/sidebar-menu/micro-learning.svg",
    label: "Micro Learning",
    url: `${appLoginUrl.value}/admin/instructional-strategies`
  },
]);

const menuItemsClient = ref([
  {
    icon: "/images/sidebar-menu/dashboard.svg",
    label: "Dashboard",
    route: '/admin/dashboard'
  },
  {
    icon: "/images/sidebar-menu/user-list.svg",
    label: "Users List",
    route: '/admin/users'
  },
  {
    icon: "/images/sidebar-menu/lesson-plan.svg",
    label: "Lesson Plans",
    url: `${appLoginUrl.value}/admin/lesson`
  },
  {
    icon: "/images/sidebar-menu/maker-resources.svg",
    label: "Maker Resources",
    url: `${appLoginUrl.value}/admin/maker-resources`
  },
  {
    icon: "/images/sidebar-menu/micro-learning.svg",
    label: "Micro Learning",
    url: `${appLoginUrl.value}/admin/instructional-strategies`
  },
]);
</script>

<style scoped>
.navbar-admin {
  background-color: #003D6A;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  gap: 15px;
}

.navbar-client {
  display: flex;
  background-color: #003D6A;
  width: 100%;
  gap: 15px;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-icon {
  width: 20px;
  height: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: flex;
  gap: 5px;
}

.nav-label {
  display: inline-block;
}

/* Hide labels on mobile */
@media (max-width: 768px) {
  .nav-label {
    display: none;
  }
}
</style>
