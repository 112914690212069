<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-10000064031.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Material Management</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="finishPage">Finish</button>
    </div>
    
    <div class="margin-1b" style="width: 80%;">
      <img src="/images/maker-cart-guide/top-comma-green.svg" alt="Top Comma">
      <p class="fs-12" style="color: #00925E;font-size: 22px;">Material costing is a method for students to plan the materials they want to purchase within the given budget and take the materials according to what they've written on the budget sheet. Facilitators may do a spot check to see if the students carry this out.</p>
      <p class="fs-12" style="color: #00925E;font-size: 22px;">Facilitators would have to place a price tag on the materials. The price tag may be in the form of a monetary value ($1.00) or a representative value (Stars, Points, etc). We've provided a template  of the budget sheet below as a printable resource.</p>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-image-container">
        <img src="/images/maker-cart-guide/group-1000003794.svg" height="180px" alt="Tinker to Discover">
      </div> 
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Fixed Quantity</h2>
        <p class="fs-12">Fixed quantity is a method where students can take a fixed amount of items from the cart. The amount decided is up to the facilitator's discretion. In this scenario, the teachers have to be gatekeepers of the materials to prevent hoarding. Students have to plan which item is of priority to get for them to complete the project.</p>
      </div>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-image-container">
        <img src="/images/maker-cart-guide/story-1.svg" height="180px" alt="Tinker to Discover">
      </div> 
      <div class="x-content-text">
        <h2 class="color-primary margin-10">Material Costing</h2>
        <p class="fs-12 margin-10">Material costing is a method for students to plan the materials they want to purchase within the given budget and take the materials according to what they've written on the budget sheet. Facilitators may do a spot check to see if the students carry this out.</p>
        <p class="fs-12">Facilitators would have to place a price tag on the materials. The price tag may be in the form of a monetary value ($1.00) or a representative value (Stars, Points, etc). We've provided a template  of the budget sheet below as a printable resource.</p>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="finishPage">Finish</button>
    </div>

    <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
        <!-- Fill in capital here -->
        <div class="container-modal-small">
            <div class="modal-title">Complete!</div>
            <div class="border-header"></div>
            <div class="modal-icon">
                <img src="/images/maker-cart-guide/frame-1000006111.svg" height="200" alt="Save Icon">
            </div>
            <div style="display: flex;justify-content: center;font-size: 1em;color: #00925E">
                <h1>Congratulations!</h1>
            </div>

            <div style="display: flex;justify-content: center;font-size: 1em;">
              Now that you’ve learnt more about Maker Education,
            </div>
            <div style="display: flex; justify-content: center; font-size: 1em; margin-bottom: 20px">
              how about applying your knowledge to one of your new <b style="margin-left: 0.3em;"> Maker Activities?</b>
            </div>

            <div class="modal-button">
                <button :class="['btn', 'btn-discard', { disabled: buttonsDisabled }]" :disabled="buttonsDisabled" @click="updateVisibilityModal(false)" style="min-width: 100px;">Stay Here</button>
                <button :class="['btn', 'btn-save', { disabled: buttonsDisabled }]" :disabled="buttonsDisabled" @click="triggerConfirm" style="padding: 0.5em 1em; min-width: 100px;">
                  View Activities
                </button>
            </div>
        </div>
    </ModalComponent>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();
const showModal = ref(false);

function updateVisibilityModal(visible) {
    showModal.value = visible;
}

const goToPreviousPage = () => {
  router.push('/makercart-guide/10');
};

const triggerConfirm = () => {
  router.push('/profile?tab=stem-activities');
};

const finishPage = () => {
  updateVisibilityModal(true);
};
</script>