<template>
  <div class="input-wrap">
    <label class="input-main-2" v-if="label" v-bind="$attrs">{{ label }}</label>
    <input class="input-main-2" :class="inputClasses" :style="{ backgroundColor: inputBackgroundColor }" v-if="type === 'text'" type="text" :value="modelValue" :placeholder="error ? errorMessage : placeholder"
      @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs">
    <textarea :class="textareaClasses" :style="{ backgroundColor: inputBackgroundColor }" v-else-if="type === 'multiline'" :value="modelValue" :placeholder="error ? errorMessage : placeholder"
      @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs"></textarea>
    <div v-if="type === 'select'" :class="['dropdown', { 'disabled': disabled }]" ref="dropdownRef">
      <input class="input-main-2" :class="[inputClasses, {'input-error': error, 'disabled': disabled}]" :style="{ backgroundColor: inputBackgroundColor }" type="text" style="width: 100%;" :value="selectedOptionText" :placeholder="error ? errorMessage : placeholder"
        readonly v-bind="$attrs" @click="toggleDropdown">
      <img :src="currentIcon" :style="{ width: iconSize, height: iconSize }" class="dropdown-icon" :class="{ 'rotate-icon': showDropdown }" @click="iconClickHandler">
      <div v-if="showDropdown" class="dropdown-panel" :style="dropdownPanelStyles">
        <input v-if="showSearch" class="input-search" type="text" placeholder="Search ..." @input="searchHandler($event.target.value)" />
        <div class="options-container" :style="optionsContainerStyles">
          <div v-for="(option, index) in filteredOptions" :key="index" :class="{ 'disabled-option': option.disabled }" @click.stop="selectOption(option)">
            {{ option.text }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 'multipleSelect'" class="dropdown" ref="dropdownRef">
      <input class="input-main-2" :class="inputClasses" :style="{ backgroundColor: inputBackgroundColor }" type="text" style="width: 100%;" :value="selectedItemsCount" :placeholder="placeholder"
        readonly v-bind="$attrs" @click="toggleDropdown">
      <img :src="currentIcon" :style="{ width: iconSize, height: iconSize }" class="dropdown-icon" :class="{ 'rotate-icon': showDropdown }" @click="iconClickHandler">
      <div v-if="showDropdown" class="dropdown-panel" :style="dropdownPanelStyles">
        <input v-if="showSearch" class="input-search" type="text" placeholder="Search ..." @input="searchHandler($event.target.value)" />
        <div class="options-container" :style="optionsContainerStyles">
          <div v-for="(option, index) in filteredOptions" :key="index" class="checkbox-option">
            <input type="checkbox" :id="'checkbox-' + index" :value="option.value"
                  :checked="isSelected(option.value)" @change="toggleSelection(option.value, index)" 
                  :disabled="shouldDisableCheckbox(option.value)"
                  v-model="selectedValues"
                  >
            <label :for="'checkbox-' + index">{{ option.text }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUnmounted, computed, nextTick } from 'vue';
import { watch, watchEffect } from 'vue';

const props = defineProps({
  label: {
    type: [String, Boolean],
    default: false,
  },
  modelValue: {
    type: [String, Array, Number],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: 'text',
  },
  options: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  maxHeight: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'default', // 'default' for full border, 'underline' for underline
    validator: (value) => ['default', 'underline'].includes(value),
  },
  icon: {
    type: String,
    default: '/images/arrow-circle-left.svg'
  },
  iconSize: {
    type: String,
    default: '20px'
  },
  dropdownBackgroundColor: {
    type: String,
    default: '#F2F3FB'
  },
  inputBackgroundColor: {
    type: String,
    default: '#F2F3FB'
  },
  showSearch: {
    type: Boolean,
    default: true
  },
  hideScrollbar: {
    type: Boolean,
    default: false
  },
  maxSelections: {
    type: Number,
    default: Infinity,
  },
});

const currentIcon = ref(props.icon);
const selectedValues = ref([]);

const dropdownMaxHeight = computed(() => {
  return props.maxHeight ? `${props.maxHeight}px` : "none";
});

const inputClasses = computed(() => ({
  'input-error': props.error,
  'disabled': props.disabled,
  'underline-mode': props.mode === 'underline',
}));

const textareaClasses = computed(() => ({
  'input-main-2': true,
  'input-error': props.error,
  'underline-mode': props.mode === 'underline',
}));

const dropdownPanelStyles = computed(() => ({
  '--dropdown-max-height': dropdownMaxHeight.value,
  'background-color': props.dropdownBackgroundColor,
}));

const optionsContainerStyles = computed(() => ({
  'top': props.showSearch ? '50px' : '0px',
  'padding-right': props.hideScrollbar ? '15px' : '0px', // Adjust padding when scrollbar is hidden
  'overflow-y': 'auto',
  'scrollbar-width': props.hideScrollbar ? 'none' : 'thin', // Hide scrollbar in Firefox
  '-ms-overflow-style': props.hideScrollbar ? 'none' : 'auto' // Hide scrollbar in IE and Edge
}));

onMounted(() => {
  window.addEventListener('click', closeDropdown);
  
  if ((props.type === 'select' && props.modelValue) || 
      (props.type === 'multipleSelect' && props.modelValue && props.modelValue.length > 0)) {
    currentIcon.value = '/images/close.svg';
  } else {
    currentIcon.value = props.icon;
  }
});

onUnmounted(() => {
  window.removeEventListener('click', closeDropdown);
});

watch(() => props.modelValue, (newValue) => {
    selectedValues.value = [...newValue];
  },
  { immediate: true }
);

watchEffect(() => {
  if ((props.type === 'select' && props.modelValue) || 
      (props.type === 'multipleSelect' && props.modelValue && props.modelValue.length > 0)) {
    currentIcon.value = '/images/close.svg';
  } else {
    currentIcon.value = props.icon;
  }
});

const emit = defineEmits(['update:modelValue', 'search-change', 'clear']);

const showDropdown = ref(false);
const dropdownRef = ref(null);

const adjustDropdownPosition = () => {
  nextTick(() => {
    if (!dropdownRef.value) return;

    const dropdownPanel = dropdownRef.value.querySelector('.dropdown-panel');
    if (!dropdownPanel) return;

    const rect = dropdownPanel.getBoundingClientRect();

    if (rect.right < 200) {
      const inputSearch = dropdownPanel.querySelector('.input-search');
      inputSearch.style.left = `0px`;
      inputSearch.style.right = 'auto';

      const optionsContainer = dropdownPanel.querySelector('.options-container');
      optionsContainer.style.left = `0px`;
      optionsContainer.style.right = 'auto';
    } 
  });
};

const toggleDropdown = () => {
  if(props.disabled) {
    return;
  }
  showDropdown.value = !showDropdown.value;
  if (showDropdown.value) {
    nextTick(() => {
      adjustDropdownPosition();
    });
  }
};

const iconClickHandler = () => {
  if(props.disabled) {
    return;
  }
  
  if (selectedOptionText.value && currentIcon.value.includes('close.svg')) {
    clearSelection();
  } else {
    toggleDropdown();
  }

  if((props.type === 'multipleSelect' || props.type === 'select') && currentIcon.value.includes('close.svg')) {
    clearSelection();
  }
};

const closeDropdown = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    showDropdown.value = false;
    searchText.value = '';
  }
};

const clearSelection = () => {
  if (props.type === 'select') {
    // Jika tipe 'select', hapus pilihan dan reset ke keadaan awal
    selectedOptionText.value = '';
    emit('update:modelValue', '');
  } else if (props.type === 'multipleSelect') {
    // Jika tipe 'multipleSelect', hapus semua pilihan dan reset ke keadaan awal
    selectedValues.value = [];
    emit('update:modelValue', []); 
  }
  currentIcon.value = props.icon;
  showDropdown.value = false;
  emit('clear'); 
};

const selectOption = (option) => {
  if (option.disabled) {
    return;
  }
  currentIcon.value = '/images/close.svg';
  showDropdown.value = false;
  selectedOptionText.value = option.text;
  emit('update:modelValue', option.value);
  searchText.value = '';
};

const searchText = ref('');

const filteredOptions = computed(() => {
  if (!searchText.value) {
    return props.options;
  }
  return props.options.filter(option => 
    option.text.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const searchHandler = (value) => {
  searchText.value = value;
  emit('search-change', value); // Memancarkan nilai search ke parent
};

const selectedOptionText = computed(() => {
  if (props.type === 'select') {
    const option = props.options.find(o => o.value === props.modelValue);
    return option ? option.text : '';
  }
  return ''; // Mengembalikan string kosong jika tidak ada yang cocok atau bukan tipe 'select'
});

const isSelected = computed(() => {
  return (optionValue) => props.modelValue.includes(optionValue);
});

const toggleSelection = (optionValue, optionIndex) => {
  if (props.options[optionIndex].disabled) {
    return;
  }
  const newValue = [...props.modelValue];
  const index = newValue.indexOf(optionValue);
  if (index === -1) {
    if (newValue.length < props.maxSelections) {
      newValue.push(optionValue);
    } else {
      alert(`You can only select up to ${props.maxSelections} items.`);
      return;
    }
  } else {
    newValue.splice(index, 1);
  }
  emit('update:modelValue', newValue);
};

const shouldDisableCheckbox = (checkboxId) => {
  return selectedValues.value.length >= props.maxSelections && !selectedValues.value.includes(checkboxId);
};

const selectedItemsCount = computed(() => {
  const count = props.modelValue.length;
  if (count === 0) return ''; // Tidak menampilkan apapun jika tidak ada yang dipilih
  if (count === 1) {
    const selectedOption = props.options.find(option => option.value === props.modelValue[0]);
    return selectedOption ? selectedOption.text : '';
  }
  return `${count} Selected`; // Menampilkan jumlah yang dipilih jika lebih dari satu
});
</script>

<style scoped>
.input-wrap {
  display: flex;
  flex-direction: column;
}

.input-main-2 {
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  padding: 8px 12px;
}

.input-main-2.underline-mode {
  border: none;
  border-bottom: 1px solid #D8DAE5;
  border-radius: 0;
}

.input-error {
  border: 1px solid #E00069 !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.dropdown {
  position: relative;
}

.dropdown input {
  cursor: pointer;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.rotate-icon {
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s;
}

.input-search {
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(100% - 20px);
  padding: 8px 10px;
  z-index: 1001;
  background-color: #fff;
  border-radius: 10px;
  border: 0.5px solid #D8DAE5 !important;
  margin: 5px 0px;
}

.dropdown-panel {
  font-family: 'League Spartan', sans-serif;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.options-container {
  position: absolute !important;
  top: 50px;
  right: 0px;
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5 !important;
  border-radius: 10px;
  color: #003D6A;
  width: 100%;
  z-index: 999;
  max-height: var(--dropdown-max-height);
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
}

.options-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none; /* Hide scrollbar in WebKit-based browsers when hideScrollbar is true */
}

.options-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.options-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.options-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.options-container div:hover {
  background-color: #f0f0f0;
}

.options-container div {
  color: #003D6A;
  padding: 8px 12px;
  cursor: pointer;
}

.options-container div:not(:first-child) {
  border-top: 0.5px solid #D8DAE5;
}

.options-container div:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.options-container div:last-child:hover {
  border-radius: 10px 10px;
}

.checkbox-option {
  display: flex;
  align-items: center;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 8px;
}

.input-error {
  border: 1px solid #E00069 !important;
}

.input-error::placeholder {
  color: #E00069 !important;
}

.input-main-2.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
  color: #79797d;
}

.disabled-option {
  color: #ccc !important;
  cursor: not-allowed !important;
}

.disabled-option:hover {
  background-color: transparent;
}
</style>
