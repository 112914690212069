<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>{{ isEditMode ? 'Edit Sub Category' : 'Add Sub Category' }}</h1>
    </div>
    <div style="margin: 10px 0px;">
      <a href="/admin/categories" style="color: #e00069;cursor: pointer;text-decoration: none;">Categories / </a>
      <a :href="`/admin/terms/${route.params.submissionId}`" style="color: #e00069;cursor: pointer;text-decoration: none;">{{ category.title }}</a> 
    </div>
    <div class="border-container-unique37">
      <form @submit.prevent="validateForm" novalidate>
        <div class="admin-form-group mb-3">
          <label>Title</label>
          <input @input="titleChange" type="text" v-model="form.title" required />
          <span v-if="errors.title" class="error-message-123">{{ errors.title }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Slug</label>
          <input disabled type="text" v-model="form.slug" required />
          <span v-if="errors.slug" class="error-message-123">{{ errors.slug }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Description</label>
          <textarea v-model="form.description"></textarea>
          <span v-if="errors.description" class="error-message-123">{{ errors.description }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Priority</label>
          <input type="number" v-model="form.priority" />
          <span v-if="errors.priority" class="error-message-123">{{ errors.priority }}</span>
        </div>
        <div class="admin-form-group mb-3">
          <label>Taxonomy</label>
          <input disabled type="text" v-model="category.title" />
        </div>
        <div style="display: none;" class="admin-form-group mb-3">
          <label>Taxonomy</label>
          <input type="text" v-model="form.taxonomy" />
        </div>
        <div v-if="showSpecials()" class="admin-form-group mb-3">
          <label>Visibility</label>
          <div class="custom-select">
            <select v-model="form.visibility">
              <option value="" disabled>Select Visibility</option>
              <option value="private_to_school">Private to School</option>
              <option value="private_to_user">Private to User</option>
            </select>
            <img src="/images/arrow-bottom.svg" class="select-icon" />
          </div>
          <span v-if="errors.visibility" class="error-message-123">{{ errors.visibility }}</span>
        </div>
        <div v-if="showSpecials()" class="admin-form-group mb-3">
          <label>Special Value</label>
          <input type="text" v-model="form.special_value" />
          <span v-if="errors.special_value" class="error-message-123">{{ errors.special_value }}</span>
        </div>
        <div v-if="showSpecials()" class="admin-form-group mb-3">
          <label>School ID</label>
          <input type="number" v-model="form.school_id" />
          <span v-if="errors.school_id" class="error-message-123">{{ errors.school_id }}</span>
        </div>
        <div v-if="showSpecials()" class="admin-form-group mb-3">
          <label>Icon</label>
          <input type="text" v-model="form.icon" />
          <span v-if="errors.icon" class="error-message-123">{{ errors.icon }}</span>
        </div>
        <button type="submit" class="submit-btn">{{ isEditMode ? 'Update' : 'Submit' }}</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TermAdminService from '@/services/termAdminService';
import CategoryAdminService from '@/services/categoryAdminService';

const router = useRouter();
const route = useRoute();

const form = reactive({
  taxonomy: null,
  slug: '',
  title: '',
  description: '',
  priority: null,
  parent: null,
  language: '',
  special_value: '',
  icon: '',
  school_id: null,
  visibility: 'public'
});

const errors = reactive({
  taxonomy: '',
  slug: '',
  title: ''
});

const category = ref({ "id": null, "name": null, "title": null });

const isEditMode = route.params.id !== undefined;

const fetchTerm = async (id) => {
  try {
    const response = await TermAdminService.getById(id);
    if (response.status === 200) {
      Object.assign(form, response.data.data);
    }
  } catch (error) {
    console.error(error.message);
    alert('Failed to fetch term data');
  }
};

const getCategorybyId = async (id) => {
  try {
    const response = await CategoryAdminService.getById(id);
    if (response.status === 200) {
      category.value = response.data.data;
      form.taxonomy = category.value.id;
    }
  } catch (error) {
    console.log(error.message);
  }
};

onMounted(() => {
  getCategorybyId(route.params.submissionId);
  if (isEditMode) {
    fetchTerm(route.params.id);
  }
});

const validateForm = async () => {
  // Reset errors
  Object.keys(errors).forEach(key => {
    errors[key] = '';
  });

  let isValid = true;

  // Validate required fields
  const requiredFields = ['taxonomy', 'slug', 'title'];
  for (const key of requiredFields) {
    if (!form[key]) {
      errors[key] = `${key} is required`;
      isValid = false;
    }
  }

  console.log('errors', errors);

  if (!isValid) {
    alert('Please fill in all required fields.');
    return;
  }

  try {
    if (isEditMode) {
      await TermAdminService.update(route.params.id, form);
      alert('Sub Category updated successfully!');
    } else {
      await TermAdminService.create(form);
      alert('Sub Category created successfully!');
    }
    router.push(`/admin/terms/${route.params.submissionId}`);
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message) {
      alert(`Failed to submit form: ${error.response.data.message}`);
    } else {
      alert('Failed to submit form due to an unknown error!');
    }
  }
};

function titleChange() {
  form.slug = form.title.toLowerCase().replace(/\s+/g, '-');
}

function showSpecials() {
  return category.value.title === 'Category';
}
</script>
