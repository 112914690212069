<template>
  <div class="card" style="margin-bottom: 20px;">
    <div class="card-content" style="display: flex; justify-content: start; gap: 16px; padding: 30px 30px;">
      <div>
        <img v-if="activity.image_thumbnail" :src="activity.image_thumbnail[0].url"
          style="width: 180px; height: 150px; object-fit: cover;">
        <img v-else src="./images/content-placeholder.svg" style="width: 180px; height: 150px; object-fit: cover;">
      </div>
      <div>
        <h2 :title="activity.makercart_activity" style="font-size: 24px;">{{ activity.activity_name }}</h2>
        <p style="font-size: 0.7em; color: #333333; font-weight: 400;">{{ activity.activity_description }}</p>
        <div class="terms-container" style="margin-top: 16px;">
          <div class="term" style="font-size: 0.7em;">
            <div>{{ activity.number_of_students }}</div>
          </div>
          <div class="term" style="font-size: 0.7em;">
            <div>{{ activity.subject }}</div>
          </div>
          <div class="term" style="font-size: 0.7em;">
            <div>{{ activity.grade }}</div>
          </div>
          <div class="term" style="font-size: 0.7em;">
            <div>{{ activity.focus }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Divider (underline effect) -->
    <hr
      style="border-top: 1px solid #CCCCCC;border-right: none;border-bottom: none;border-left: none;border-image: initial;margin: 0px 30px 10px;">

    <!-- Footer with buttons aligned to the right -->
    <div class="card-footer" style="padding: 0px 30px 15px; position: relative;">
      <div style="display: flex; gap: 10px; justify-content: flex-end;">
        <a class="button details-btn" style="cursor: pointer;border-radius: 15px;padding: 10px 20px;"
          @click="updateVisibilityModalMakerCartDetail(true, activity.stem_makercart_id, activity.stem_makercart_order_id)">More Details</a>
        <a href="#" class="button disabled"
          style="background-color: rgb(204, 204, 204);cursor: not-allowed;border-radius: 15px;padding: 10px 20px;"
          @mouseover="showTooltip = true" @mouseleave="showTooltip = false">View Resources</a>
      </div>
      <div v-if="showTooltip" class="tooltip"
        style="border: 1px solid #CCCCCC; position: absolute; background-color: #f0f0f0; padding: 10px; border-radius: 4px; top: 100%; right: 0; font-size: 0.75em; z-index: 100;">
        You'll receive an email once the resources for this activity are ready for download.
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineProps, defineEmits, ref } from 'vue';

defineProps({
  activity: Object
});

const emit = defineEmits(['updateVisibilityModalMakerCartDetail']);

function updateVisibilityModalMakerCartDetail(visible ,id, stem_makercart_order_id) {
  emit('updateVisibilityModalMakerCartDetail', {visible ,id, stem_makercart_order_id});
}

const showTooltip = ref(false);
</script>
