<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Student List</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title">List of Student List</div>
      <div>You can view student, add student to YSA program and remove student from YSA program.</div>
      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery" @input="debouncedgetAll">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>
      </div>
      <div class="admin-list-tool-container">
      </div>

      <div class="mb-3" style="display: flex;gap: 10px;flex-wrap: wrap;">
      </div>

      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th @click="toggleSortOrder('firstname')" class="sortable-column">
                <div class="sortable-header">
                  <span>Student Name</span>
                  <span>{{ sortOrderArrow('firstname') }}</span>
                </div>
              </th>
              <th>Email</th>
              <th>School</th>
              <th>Country</th>
              <th @click="toggleSortOrder('date_added')" class="sortable-column">
                <div class="sortable-header">
                  <span>Join Date</span>
                  <span>{{ sortOrderArrow('date_added') }}</span>
                </div>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="!tableLoading && data.data.rows.length > 0">
            <tr v-for="(item, index) in data.data.rows" :key="index">
              <td>{{ `${item.firstname || '-'} ${item.lastname || ''}` }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.school_name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.date_added }}</td>
              <td>
                <div class="d-flex justify-center cursor-pointer">
                  <img v-if="item.ysa === 0 ||item.ysa === null" @click="toggleYSA(item.user_id)" src="/images/icon/plusmark.svg" alt="plus-mark">
                  <img v-if="item.ysa === 1" @click="toggleYSA(item.user_id)" src="/images/icon/subtract.svg" alt="plus-mark">
                </div> 
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!tableLoading && data.data.rows.length === 0">
            <tr>
              <td colspan="6">
                <div class="no-data-message">No data available</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.data.total_pages > 0" class="pagination-controls">
        <img @click="prevPage" src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img @click="nextPage" src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalDelete" @update:visible="updateVisibilityModalDelete">
    <div class="container-modal">
      <div class="modal-title">Remove Category?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1.4em;margin: 15px 0px;">Are you sure want to remove this category?</div>
      <div class="modal-button">
        <button class="btn btn-discard" @click="updateVisibilityModalDelete">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }" @click="remove(itemId)">Confirm</button>
      </div>
    </div>
  </ModalComponent>
  
</template>

<script setup>
/* eslint-disable */ 
import { onMounted, ref, computed } from 'vue';
import YsaAdminService from '@/services/ysaAdminService';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';

const showModalDelete = ref(false);
const isLoading = ref(false);
const itemId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");
const currentStatus = ref("");
const buttons = ref([
  { label: "All", clicked: true, status: "" },
  { label: "Edit", clicked: false, status: "edit" },
  { label: "Delete", clicked: false, status: "delete" },
  { label: "Create", clicked: false, status: "delete" },
  { label: "Copy", clicked: false, status: "copy" }
]);

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const currentPage = ref(1);
const sortOrder = ref('asc');
const sortKey = ref('email');

onMounted(async () => {
  await getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  window.addEventListener('resize', updateLayout);
  updateLayout();
});

const getAll = async (page, search, key, sort_by, order) => {
  try {
    tableLoading.value = true;

    const param = {page, search, key, sort_by, order}

    const response = await YsaAdminService.getAllStudent(param);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const debouncedgetAll = debounce(() => {
  currentPage.value = 1;
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAll(page, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    changePage(currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < data.value.data.total_pages) {
    changePage(currentPage.value + 1);
  }
};

const visiblePages = computed(() => {
  const total = data.value.data.total_pages;
  const current = currentPage.value;
  const start = Math.max(1, current - 2);
  const end = Math.min(total, current + 2);

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

const updateVisibilityModalDelete = () => {
  showModalDelete.value = !showModalDelete.value;
};

// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector('.admin-flex-container');
  const toolContainer = document.querySelector('.admin-list-tool-container');
  const parentContainer = document.querySelector('.border-container-unique37');
  const width = window.innerWidth;
  
  if (width <= 991) {
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};

// Function untuk toggle clicked state
const toggleYSA = async (index) => {
  const response = await YsaAdminService.toggleStudentYSA(index);
  if (response.status === 200) {
    getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
  }
};

const toggleSortOrder = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortKey.value = key;
    sortOrder.value = 'asc';
  }
  
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value);
};

const sortOrderArrow = (key) => {
  if (sortKey.value !== key) return '';
  return sortOrder.value === 'asc' ? '▲' : '▼';
};

</script>
