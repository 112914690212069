<template>
    <div class="admin-container">
      <div class="admin-header">
        <h1>Create Account from Invitation</h1>
      </div>
      <div class="border-container-unique37">
        <form class="admin-form-group-container" @submit.prevent="validateForm" novalidate>
          <input type="hidden" v-model="form.token" />
          <div class="admin-form-group">
            <label>First Name</label>
            <input type="text" v-model="form.firstname" required />
            <span v-if="errors.firstname" class="error-message-123">{{ errors.firstname }}</span>
          </div>
          <div class="admin-form-group">
            <label>Last Name</label>
            <input type="text" v-model="form.lastname" required />
            <span v-if="errors.lastname" class="error-message-123">{{ errors.lastname }}</span>
          </div>
          <div class="admin-form-group">
            <label>Password</label>
            <div class="password-container">
              <input :type="showPassword ? 'text' : 'password'" v-model="form.password" @input="validatePassword" required />
              <button type="button" @click="toggleShowPassword">{{ showPassword ? 'Hide' : 'Show' }}</button>
            </div>
            <div v-if="errors.password.length > 0" class="error-message-123">
              <p v-for="error in errors.password" :key="error">{{ error }}</p>
            </div>
          </div>
          <div class="admin-form-group">
            <label>Confirm Password</label>
            <div class="password-container">
              <input :type="showConfirmPassword ? 'text' : 'password'" v-model="form.confirmPassword" @input="validatePassword" required />
              <button type="button" @click="toggleShowConfirmPassword">{{ showConfirmPassword ? 'Hide' : 'Show' }}</button>
            </div>
            <span v-if="errors.confirmPassword" class="error-message-123">{{ errors.confirmPassword }}</span>
          </div>
          <button type="submit" class="submit-btn">Create Account</button>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
  import { reactive, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import UserAdminService from '@/services/userAdminService';
  
  const form = reactive({
    token: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: ''
  });
  
  const errors = reactive({
    token: '',
    firstname: '',
    lastname: '',
    password: [],
    confirmPassword: ''
  });
  
  const showPassword = ref(false);
  const showConfirmPassword = ref(false);
  
  const router = useRouter();
  
  onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    form.token = urlParams.get('t');
  });
  
  const toggleShowPassword = () => {
    showPassword.value = !showPassword.value;
  };
  
  const toggleShowConfirmPassword = () => {
    showConfirmPassword.value = !showConfirmPassword.value;
  };
  
  const validatePassword = () => {
    errors.password = [];
    const password = form.password;
    if (!/[a-zA-Z]/.test(password)) errors.password.push('Password must contain at least one letter');
    if (!/[A-Z]/.test(password)) errors.password.push('Password must contain at least one capital letter');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.password.push('Password must contain at least one special character');
    if (password.length < 4 || password.length > 20) errors.password.push('Password must be between 4 and 20 characters');
    
    if (form.password !== form.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    } else {
      errors.confirmPassword = '';
    }
  };
  
  const validateForm = async () => {
    // Reset errors
    Object.keys(errors).forEach(key => {
      if (Array.isArray(errors[key])) {
        errors[key] = [];
      } else {
        errors[key] = '';
      }
    });
  
    let isValid = true;
  
    // Validate required fields
    const requiredFields = ['token', 'firstname', 'lastname', 'password', 'confirmPassword'];
    for (const key of requiredFields) {
      if (!form[key]) {
        errors[key] = `${key} is required`;
        isValid = false;
      }
    }
  
    validatePassword();
    if (errors.password.length > 0 || errors.confirmPassword) {
      isValid = false;
    }
  
    if (!isValid) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      await UserAdminService.createAccountFromInvitation({
        token: form.token,
        firstname: form.firstname,
        lastname: form.lastname,
        password: form.password
      });
      alert('Account created successfully!, Please try to sign in');
      router.push('/'); // Redirect to a success page or another route
    } catch (error) {
      console.error(error);
      alert('Failed to create account.');
    }
  };
  </script>
  
  <style scoped>
  .admin-container {
    /* Add your specific styles here */
  }
  .admin-header {
    margin-bottom: 20px;
  }
  .admin-form-group-container {
    display: flex;
    flex-direction: column;
  }
  .admin-form-group {
    margin-bottom: 15px;
  }
  .custom-select {
    position: relative;
  }
  .custom-select select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
  }
  .select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .password-container {
    display: flex;
    align-items: center;
  }
  .password-container input {
    flex: 1;
  }
  .password-container button {
    margin-left: 10px;
  }
  .error-message-123 {
    color: red;
    font-size: 12px;
  }
  
  </style>
  