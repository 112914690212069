<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Users</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title">List of Users</div>
      <div>You can add, edit, and delete all of Users from this page.</div>

      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery" @input="debouncedgetAll">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>
        <a class="btn btn-create-list mx-2 my-md-auto" href="/admin/users/form">
          <span class="icon"><img src="/images/icon/plus-circle.svg" alt="download-icon"></span>
          <span class="admin-button-text">Add Users</span>
        </a>
      </div>
      <div class="admin-list-tool-container">
        <button 
          v-for="(button, index) in buttons" 
          :key="index" 
          :class="{'checkbox-button-clicked': button.clicked}" 
          @click="toggleClicked(index)" 
          class="checkbox-button">
          {{ button.label }}
        </button>
      </div>

      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th>User Type</th>
              <th>School Name</th>
              <th>Email</th>
              <th>Name</th>
              <th>Country</th>
              <th>Date Added</th>
              <th>Last Login</th>
              <th>Action</th>       
            </tr>
          </thead>
          <tbody v-if="!tableLoading && data.data.rows.length > 0">
            <tr v-for="(project, index) in data.data.rows" :key="index">
              <td>{{ project.user_group_name }}</td>
              <td>{{ project.school_name }}</td>
              <td>{{ project.email }}</td>
              <td>{{ project.name }}</td>
              <td>{{ project.country }}</td>
              <td>{{ formatToUserTimezone(project.date_added) }}</td>
              <td>{{ formatToUserTimezone(project.last_login) }}</td>
              <td>
                <div class="admin-action-column">
                  <a :href="`/admin/users/form/${project.id}`">Edit</a>
                  <a href="#" @click="openModal(project.id)"><img src="/images/admin/trash.svg" alt="Delete"></a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!tableLoading && data.data.rows.length === 0">
            <tr>
              <td colspan="8">
                <div class="no-data-message">No data available</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.data.total_pages > 0" class="pagination-controls">
        <img @click="prevPage" src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img @click="nextPage" src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalDelete" @update:visible="updateVisibilityModalDelete">
    <div class="container-modal">
      <div class="modal-title">Remove User?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1.4em;margin: 15px 0px;">Are you sure want to remove this user?</div>
      <div class="modal-button">
        <button class="btn btn-discard" @click="updateVisibilityModalDelete">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }" @click="remove(itemId)">Confirm</button>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
/* eslint-disable */ 
import { onMounted, ref, computed } from 'vue';
import UserAdminService from '@/services/userAdminService.js';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';
import DateFormatter from '@/utils/DateFormatter.js'; // Import DateFormatter

const showModalDelete = ref(false);
const isLoading = ref(false);
const itemId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");
const currentStatus = ref(""); // Menyimpan status pengguna saat ini

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

const currentPage = ref(1);
const buttons = ref([
  { label: "All", clicked: true, status: "" },
  { label: "Active User", clicked: false, status: "active" },
  { label: "Pending User", clicked: false, status: "pending" },
  { label: "Blocked User", clicked: false, status: "blocked" }
]); // Array untuk state button

onMounted(async () => {
  await getAll(currentPage.value, searchQuery.value, currentStatus.value);
  window.addEventListener('resize', updateLayout);
  updateLayout();
});

const formatToUserTimezone = (date) => {
  return DateFormatter.formatToUserTimezone(date);
};

const getAll = async (page, search, status) => {
  try {
    tableLoading.value = true;

    const param = {page, search}

    if(status === "active") {
      param.status = 'true';
    } else if(status === "pending") {
      param.status = 'true';
      param.approved = 'false';
    } else if(status === "blocked") {
      param.status = 'false';
      param.approved= 'false';
    }

    const response = await UserAdminService.getAll(param);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const debouncedgetAll = debounce(() => {
  currentPage.value = 1;
  getAll(currentPage.value, searchQuery.value, currentStatus.value);
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAll(page, searchQuery.value, currentStatus.value);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    changePage(currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < data.value.data.total_pages) {
    changePage(currentPage.value + 1);
  }
};

const visiblePages = computed(() => {
  const total = data.value.data.total_pages;
  const current = currentPage.value;
  const start = Math.max(1, current - 2);
  const end = Math.min(total, current + 2);

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

const openModal = (id) => {
  itemId.value = id; // Set itemId
  showModalDelete.value = true;
};

const updateVisibilityModalDelete = () => {
  showModalDelete.value = !showModalDelete.value;
};

const remove = async (id) => {
  isLoading.value = true;
  try {
    await UserAdminService.remove(id);
    isLoading.value = false;
    showModalDelete.value = false;
    getAll(currentPage.value, searchQuery.value, currentStatus.value); // Refresh the list
    alert('User deleted successfully');
  } catch (error) {
    isLoading.value = false;
    console.error(error.message);
    alert('Failed to delete user');
  }
};

// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector('.admin-flex-container');
  const toolContainer = document.querySelector('.admin-list-tool-container');
  const parentContainer = document.querySelector('.border-container-unique37');
  const width = window.innerWidth;
  
  if (width <= 991) {
    // Memindahkan toolContainer di bawah admin-flex-container
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    // Memindahkan toolContainer kembali ke dalam admin-flex-container
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};

// Function untuk toggle clicked state
const toggleClicked = (index) => {
  buttons.value.forEach((button, idx) => {
    button.clicked = idx === index;
  });
  currentStatus.value = buttons.value[index].status;
  getAll(currentPage.value, searchQuery.value, currentStatus.value);
};
</script>