// src/services/CommentAdminService.js

import http from "./httpService";

class YsaAdminService {
  async getAllSchool(params) {
    const url = http.getUrlWithParams("/admin/dashboard/schools", params);
    return await http.get(url);
  }

  async getAllStudent(params) {
    const url = http.getUrlWithParams("/admin/dashboard/students", params);
    return await http.get(url);
  }

  async getAllTeacher(params) {
    const url = http.getUrlWithParams("/admin/dashboard/teachers", params);
    return await http.get(url);
  }  

  async toggleStudentYSA(id) {
    return await http.put(`/admin/dashboard/students/${id}/toggle-ysa`);
  }   

  async toggleTeacherYSA(id) {
    return await http.put(`/admin/dashboard/teachers/${id}/toggle-ysa`);
  }   

  async toggleSchoolYSA(id) {
    return await http.put(`/admin/dashboard/schools/${id}/toggle-ysa`);
  }  
}

export default new YsaAdminService();
