export default class DateFormatter {
    /**
     * Format the given date to "YYYY-MM-DD HH:mm:ss" in the user's local timezone
     * without changing the actual time.
     * @param {Date | string} date - The date to format (can be a Date object or a string)
     * @returns {string | null} - The formatted date string in the user's local timezone
     */
    static formatToUserTimezone(date) {
      if (!date) return null;
  
      // Convert string to Date object if necessary
      const userDate = typeof date === 'string' ? new Date(date) : date;
  
      // Ensure that we are working with a valid Date object
      if (!(userDate instanceof Date) || isNaN(userDate)) {
        throw new Error('Invalid date');
      }
  
      const year = userDate.getFullYear();
      const month = String(userDate.getMonth() + 1).padStart(2, '0');
      const day = String(userDate.getDate()).padStart(2, '0');
      const hours = String(userDate.getHours()).padStart(2, '0');
      const minutes = String(userDate.getMinutes()).padStart(2, '0');
      const seconds = String(userDate.getSeconds()).padStart(2, '0');
  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
  