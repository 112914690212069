<template>
  <div class="content-container-maker-cart"
    style="display: flex; justify-content: space-between; align-items: center; position: relative;">

    <!-- Bagian kiri -->
    <div style="display: flex; align-items: center; gap: 5px;">
      <!-- Button with conditional class -->
      <button :class="isFilterSelected ? 'button-white' : 'button-white-primary'" @click="toggleFilterPanel" style="cursor: pointer;">
        <img height="18" src="/images/maker-cart/setting-4.svg" alt="Settings">
      </button>

      <!-- Filters di kiri -->
      <SelectComponent
        :options="['<div> Sort by <strong>Newest</strong> </div>', '<div>Sort by <strong>Oldest</strong> </div>', '<div> Sort by <strong>A-Z</strong> </div>', '<div> Sort by <strong>Z-A</strong> </div>']"
        :icon="`/images/arrow-bottom.svg`" v-model="selectedSort" @change="onSortChange" />

      <BaseInput2 v-model="subject" inputBackgroundColor="#FFFFFF" :showSearch="false"
        :icon="`/images/arrow-bottom.svg`" type="multipleSelect" :options="subjectOptions" mode="underline"
        :maxHeight="'245px'" placeholder="Subjects" @change="selectSubject($event)" @clear="selectSubject" />

      <BaseInput2 v-model="grade" inputBackgroundColor="#FFFFFF" :showSearch="false" :icon="`/images/arrow-bottom.svg`"
        type="multipleSelect" :options="gradeOptions" mode="underline" :maxHeight="'245px'" placeholder="Grade"
        @change="selectGrade($event)" @clear="selectGrade" />

      <BaseInput2 v-model="focus" inputBackgroundColor="#FFFFFF" :showSearch="false" :icon="`/images/arrow-bottom.svg`"
        type="multipleSelect" :options="focusOptions" mode="underline" :maxHeight="'245px'" placeholder="Focus"
        @change="selectFocus($event)" @clear="selectFocus" />
    </div>

    <!-- Tombol Cart di kanan dengan badge -->
    <div class="ml-2 cart-button" @click="updateVisibilityModalUserCart(true)"
      style="cursor: pointer;margin-top: 0.5em; position: relative;">
      <img class="filter-green" height="25" src="/images/icon/cart.svg" alt="Cart">
      <div v-if="cartItemCount > 0" class="cart-badge-x">{{ cartItemCount }}</div>
    </div>

    <!-- Overlay -->
    <div v-show="showFilterPanel" class="overlay" @click="toggleFilterPanel"></div>

    <!-- Panel Filter -->
    <div v-show="showFilterPanel" class="zz-filter-panel">
      <div style="margin-bottom: 20px;" class="zz-filter-panel-content">

        <!-- Grades Section -->
        <div class="zz-filter-column">
          <div>Grades</div>
          <div class="underline"></div>
          <div v-for="option in gradeOptions" :key="option.value" class="option-label"
            :class="{ 'disabled-item': option.disabled }">
            <template v-if="option.value === 'all'">
              <input type="checkbox" @change="handleAllSelection('grade', $event)" :checked="allSelected.grade" />
              <span class="margin-label">All Grades</span>
            </template>
            <template v-else>
              <input type="checkbox" v-model="grade" :value="option.value" @change="emitFilters"
                :disabled="option.disabled" />
              <span class="margin-label">{{ option.title }}</span>
            </template>
          </div>
        </div>

        <!-- Subjects Section -->
        <div class="zz-filter-column">
          <div>Subjects</div>
          <div class="underline"></div>
          <div v-for="option in subjectOptions" :key="option.value" class="option-label"
            :class="{ 'disabled-item': option.disabled }">
            <template v-if="option.value === 'all'">
              <input type="checkbox" @change="handleAllSelection('subject', $event)" :checked="allSelected.subject" />
              <span class="margin-label">All Subjects</span>
            </template>
            <template v-else>
              <input type="checkbox" v-model="subject" :value="option.value" @change="emitFilters"
                :disabled="option.disabled" />
              <span class="margin-label">{{ option.title }}</span>
            </template>
          </div>
        </div>

        <!-- Focus Section -->
        <div class="zz-filter-column">
          <div>Focus</div>
          <div class="underline"></div>
          <div v-for="option in focusOptions" :key="option.value" class="option-label"
            :class="{ 'disabled-item': option.disabled }">
            <template v-if="option.value === 'all'">
              <input type="checkbox" @change="handleAllSelection('focus', $event)" :checked="allSelected.focus" />
              <span class="margin-label">All Focus</span>
            </template>
            <template v-else>
              <input type="checkbox" v-model="focus" :value="option.value" @change="emitFilters"
                :disabled="option.disabled" />
              <span class="margin-label">{{ option.title }}</span>
            </template>
          </div>
        </div>

        <!-- Hardware Section -->
        <div class="zz-filter-column">
          <div>Hardware</div>
          <div class="underline"></div>
          <div v-for="option in hardwareOptions" :key="option.value" class="option-label"
            :class="{ 'disabled-item': option.disabled }">
            <template v-if="option.value === 'all'">
              <input type="checkbox" @change="handleAllSelection('hardware', $event)" :checked="allSelected.hardware" />
              <span class="margin-label">All Hardware</span>
            </template>
            <template v-else>
              <input type="checkbox" v-model="hardware" :value="option.value" @change="emitFilters"
                :disabled="option.disabled" />
              <span class="margin-label">{{ option.title }}</span>
            </template>
          </div>
        </div>

        <!-- Downloadable Resources Section -->
        <div class="zz-filter-column">
          <div>Downloadable Resources</div>
          <div class="underline"></div>
          <div v-for="option in resourceOptions" :key="option.value" class="option-label"
            :class="{ 'disabled-item': option.disabled }">
            <template v-if="option.value === 'all'">
              <input type="checkbox" @change="handleAllSelection('resources', $event)"
                :checked="allSelected.resources" />
              <span class="margin-label">All Resources</span>
            </template>
            <template v-else>
              <input type="checkbox" v-model="resources" :value="option.value" @change="emitFilters"
                :disabled="option.disabled" />
              <span class="margin-label">{{ option.title }}</span>
            </template>
          </div>
        </div>
      </div>

      <!-- Tags Section -->
      <div>
        <div class="zz-filter-column">
          <div>Tags</div>
          <div class="underline"></div>
          <span class="mr-4 subject-item-2" style="font-size: 14px;font-weight: 400;margin-bottom: 10px;"
            v-for="(option, index) in tagOptions" :key="index"
            :class="{ 'subject-item-2-selected': isSelected(option, tags), 'disabled-item': option.disabled }"
            @click="toggleSelection(option, tags, true)">
            {{ option.title }} ({{ option.count }})
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, onBeforeUnmount, computed, defineEmits } from 'vue';
import SelectComponent from '@/components/common/SelectComponent.vue';
import BaseInput2 from '@/components/BaseInput2.vue';
import TermService from '@/services/termService';
import StemStudioService from '@/services/stemStudioService';

const emit = defineEmits(['filtersChanged', 'sortChanged', 'updateVisibilityModalUserCart']);

const selectedSort = ref('<div> Sort by <strong>Newest</strong> </div>');
const subject = ref([]);
const grade = ref([]);
const focus = ref([]);
const hardware = ref([]);
const resources = ref([]);
const tags = ref([]);
const showFilterPanel = ref(false);  // Control panel visibility
const cartItemCount = ref(0);  // jumlah item dalam keranjang

// State untuk "All" checkbox di masing-masing kategori
const allSelected = ref({
  grade: false,
  subject: false,
  focus: false,
  hardwareAssociated: false,
  resources: false
});

// Menghitung apakah ada filter yang dipilih
const isFilterSelected = computed(() => {
  return (
    subject.value.length > 0 ||
    grade.value.length > 0 ||
    focus.value.length > 0 ||
    hardware.value.length > 0 ||
    resources.value.length > 0
  );
});

const subjectOptions = ref([]);
const gradeOptions = ref([]);
const focusOptions = ref([{ value: 1, title: "Sustainability", text: "Sustainability", disabled: false }, { value: 2, title: "Engineering Design", text: "Engineering Design", disabled: false }, { value: 3, title: "Classic Maker", text: "Classic Maker", disabled: false }]);
const hardwareOptions = ref([]);
const resourceOptions = ref([{ value: 0, title: "All Resources", text: "All Resources", disabled: true }, { value: 1, title: "Free Resources", text: "Free Resources", disabled: true }, { value: 2, title: "Purchased Resources", text: "Purchased Resources", disabled: true }]);
const tagOptions = ref([]);

onMounted(async () => {
  try {
    // Panggil termService.getAllFilterAirtable sekali untuk mendapatkan semua data
    const response = await TermService.getAllFilterAirtable();

    if (response.status === 200) {
      const data = response.data.data;

      // Proses Tags
      const updatedTags = data.tags
        .map((item, index) => ({
          value: item.tag,
          title: item.tag,
          count: item.count,
          disabled: false,
          id: index + 1  // buat ID integer
        }))
        .sort((a, b) => b.count - a.count); // Sort berdasarkan count (descending)

      tagOptions.value = updatedTags;

      subjectOptions.value = [
        {
          value: 'all',
          title: 'All Subjects',
          text: 'All Subjects',
          disabled: false,
          id: 0
        },
        ...data.subjects
        .map((subject, index) => ({
          value: subject,
          title: subject,
          text: subject,
          disabled: false,
          id: index + 1  // buat ID integer
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
      ];        
      
      // Proses Grades
      const gradeOrder = ['All Grades', 'Lower Elementary', 'Upper Elementary', 'Middle School'];
      gradeOptions.value = [
        {
          value: 'all',
          title: 'All Grades',
          text: 'All Grades',
          disabled: false,
          id: 0
        },
        ...data.grades
          .map((grade, index) => ({
            value: grade,
            title: grade,
            text: grade,
            disabled: false,
            id: index + 1
          }))
          .sort((a, b) => gradeOrder.indexOf(a.title) - gradeOrder.indexOf(b.title))
      ];

      focusOptions.value = [
        {
          value: 'all',
          title: 'All Focus',
          text: 'All Focus',
          disabled: false,
          id: 0
        },
        ...data.focus
          .map((focus, index) => ({
            value: focus,
            title: focus,
            text: focus,
            disabled: false,
            id: index + 1
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
      ];      

      // Proses Hardware Associated
      hardwareOptions.value = [
        {
          value: 'all',
          title: 'All Hardware',
          text: 'All Hardware',
          disabled: false,
          id: 0
        },
        ...data.hardwareAssociateds
          .map((item, index) => ({
            value: index + 1,
            title: item.hardwareAssociated,
            count: item.count,
            disabled: false
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
      ];

      // Proses lainnya jika diperlukan
    }

    // Mulai interval untuk memperbarui cart count
    const interval = setInterval(updateCartCount, 1000);

    onBeforeUnmount(() => {
      clearInterval(interval);
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

const onSortChange = () => {
  let sortParam;

  if (selectedSort.value === '<div> Sort by <strong>Newest</strong> </div>') {
    sortParam = 'newest';
  } else if (selectedSort.value === '<div>Sort by <strong>Oldest</strong> </div>') {
    sortParam = 'oldest';
  } else if (selectedSort.value === '<div> Sort by <strong>A-Z</strong> </div>') {
    sortParam = 'a-z';
  } else if (selectedSort.value === '<div> Sort by <strong>Z-A</strong> </div>') {
    sortParam = 'z-a';
  }

  emit('sortChanged', sortParam);
};

const emitFilters = () => {
  // Memastikan semua checkbox "All" ter-update saat filter individual dipilih
  allSelected.value.grade = grade.value.length === gradeOptions.value.filter(option => !option.disabled).length;
  allSelected.value.subject = subject.value.length === subjectOptions.value.filter(option => !option.disabled).length;
  allSelected.value.focus = focus.value.length === focusOptions.value.filter(option => !option.disabled).length;
  allSelected.value.hardware = hardware.value.length === hardwareOptions.value.filter(option => !option.disabled).length;
  allSelected.value.resources = resources.value.length === resourceOptions.value.filter(option => !option.disabled).length;

  emit('filtersChanged', {
    subjects: subjectOptions.value.filter(option => subject.value.includes(option.value)),
    grades: gradeOptions.value.filter(option => grade.value.includes(option.value)),
    focus: focusOptions.value.filter(option => focus.value.includes(option.value)),
    hardwareAssociated: hardwareOptions.value.filter(option => hardware.value.includes(option.value)),
    resources: resourceOptions.value.filter(option => resources.value.includes(option.value)),
    tags: tags.value
  });
};

function selectSubject(ev) {
  const clickedItem = ev?.target?._value;
  
  if (clickedItem === 'all') {
    if (subject.value.length === 0 || subject.value.includes('all')) {
      subject.value = subjectOptions.value.map(option => option.value); // Select all options
    } else {
      subject.value = []; // Clear all selections
    }
  } else {
    if (subject.value.includes('all')) {
      subject.value = subject.value.filter(value => value !== 'all'); // Remove 'all' if another option is selected
    }

    if (subject.value.length === subjectOptions.value.length - 1) {
      subject.value.unshift('all'); // Automatically select 'all' when all other options are selected
    }

    if (subject.value.length === subjectOptions.value.length) {
      subject.value = []; // Clear all selections when everything is selected
    }
  }

  emitFilters(); // apply the selected filters
}

function selectGrade(ev) {
  const clickedItem = ev?.target?._value;
  
  if (clickedItem === 'all') {
    if (grade.value.length === 0 || grade.value.includes('all')) {
      grade.value = gradeOptions.value.map(option => option.value); // Select all options
    } else {
      grade.value = []; // Clear all selections
    }
  } else {
    if (grade.value.includes('all')) {
      grade.value = grade.value.filter(value => value !== 'all'); // Remove 'all' if another option is selected
    }

    if (grade.value.length === gradeOptions.value.length - 1) {
      grade.value.unshift('all'); // Automatically select 'all' when all other options are selected
    }

    if (grade.value.length === gradeOptions.value.length) {
      grade.value = []; // Clear all selections when everything is selected
    }
  }

  emitFilters(); // apply the selected filters
}

function selectFocus(ev) {
  const clickedItem = ev?.target?._value;
  // Handle the case when 'all' is clicked
  if (clickedItem === 'all') {
    // If 'all' is not selected, it should select all options
    if (focus.value.length === 0 || focus.value.includes('all')) {
      focus.value = focusOptions.value.map(option => option.value); // Select all options
      console.log('focus.value', focus.value);
    } else {
      focus.value = []; // If 'all' is deselected, clear all selections
    }
  } else {
    // Handle the case when a specific item (not 'all') is clicked
    if (focus.value.includes('all')) {
      // Remove 'all' if any other item is clicked
      focus.value = focus.value.filter(value => value !== 'all');
    }

    // If all options except 'all' are selected, automatically select 'all'
    if (focus.value.length === focusOptions.value.length - 1) {
      focus.value.unshift('all');
    }

    // If all options are selected and then an item is deselected, clear all selections
    if (focus.value.length === focusOptions.value.length) {
      focus.value = []; // Clear all selections
    }
  }

  // Call emitFilters to apply the filters based on the current selection
  emitFilters();
}

const toggleFilterPanel = () => {
  showFilterPanel.value = !showFilterPanel.value;
};

const updateVisibilityModalUserCart = (visible) => {
  emit('updateVisibilityModalUserCart', visible);
};

const toggleSelection = (item, list, isMultiple) => {
  if (isMultiple && !item.disabled) {
    const index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(item);
    }
  } else if (!isMultiple && !item.disabled) {
    if (list.includes(item)) {
      list.length = 0;
    } else {
      list.length = 0;
      list.push(item);
    }
    tags.value = list;
  }

  emitFilters();
};

const isSelected = (item, list) => {
  return list.includes(item);
};

function handleAllSelection(category, event) {
  const isChecked = event.target.checked;

  switch (category) {
    case 'grade':
      allSelected.value.grade = isChecked;
      grade.value = isChecked ? gradeOptions.value.filter(option => !option.disabled).map(option => option.value) : [];
      break;
    case 'subject':
      allSelected.value.subject = isChecked;
      subject.value = isChecked ? subjectOptions.value.filter(option => !option.disabled).map(option => option.value) : [];
      break;
    case 'focus':
      allSelected.value.focus = isChecked;
      focus.value = isChecked ? focusOptions.value.filter(option => !option.disabled).map(option => option.value) : [];
      break;
    case 'hardware':
      allSelected.value.hardware = isChecked;
      hardware.value = isChecked ? hardwareOptions.value.filter(option => !option.disabled).map(option => option.value) : [];
      break;
    case 'resources':
      allSelected.value.resources = isChecked;
      resources.value = isChecked ? resourceOptions.value.filter(option => !option.disabled).map(option => option.value) : [];
      break;
  }

  emitFilters();
}

function updateCartCount() {
  const storedCart = localStorage.getItem('cartItems');
  cartItemCount.value = storedCart ? JSON.parse(storedCart).length : 0;
}
</script>

<style scoped>
.content-container-maker-cart {
  position: relative;
}

.cart-button {
  position: relative;
}

.cart-badge-x {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  padding: 2px 8px 0px 8px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #E00069;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 4000;
  /* Behind the filter panel */
}

.zz-filter-panel {
  position: absolute;
  top: 130%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 20px 30px;
  border: 1px solid #ddd;
  z-index: 5000;
  border-radius: 10px;
}

.zz-filter-panel-content {
  display: flex;
  gap: 20px;
}

.zz-filter-column {
  flex: 1;
}

.zz-filter-column div {
  margin-bottom: 5px;
  color: #003D6A;
  /* Text color for headers */
}

.underline {
  height: 1px;
  background-color: #003D6A;
}

.option-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.tags-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.tags-container div {
  margin-bottom: 10px;
  color: #003D6A;
  /* Text color for tags header */
}

.subject-item-2 {
  padding-top: 10px;
  display: inline-block;
  padding: 4px 15px;
  border: 1px solid #D8DAE5;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 2px;
  font-style: italic;
  color: #3B4648;
  /* Text color for tags */
}

.subject-item-2-selected {
  background-color: #F2F3FB;
}
</style>
