<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-1000006494.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Conducting a Maker Activity</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="margin-10" style="width: 80%;">
        <h2 class="color-primary margin-10">Role 3: Use appropriate language</h2>
        <p class="fs-12 margin-10">
          The language we use can significantly impact learner engagement and understanding. This section delves into the importance of clear, concise, and accessible language for maker educators. This is especially important when the maker educator notices a teachable moment, and respond with questions or prompts that promote deeper thought as well as socio-emotional learning (SEL) competencies.  
        </p>
        <p class="fs-12 margin-10">
          To help illustrate how language use can invite rather than exclude, here are some language examples adapted from Youth Makerspace Playbook and other scenarios.
        </p>
    </div>


    <div class="margin-10" style="width: 80%;">
      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th style="background-color: #00925E;" class="text-center">Scenario</th>
              <th style="background-color: #00925E;" class="text-center">You could say...</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A student is frustrated with their project or stuck with a particular challenge.</td>
              <td>
                <div class="margin-10">“It looks like you’re really struggling with this. What kind of help do you need? I wonder if someone else has encountered the same problem. Let’s check.”</div>
                <div class="margin-10">“I hear your frustration. I would be frustrated too after so much work. Would you like to take a break and come back to it? Perhaps you can help others with the battery connection you got working earlier?”</div>
                <div class="margin-10">“That doesn’t make any sense to me either. How cool is it that we have this mystery? I wonder what’s going on? When you figure it out, can you tell me so that I can share it with everyone? If not, we can all work together to figure it out.”</div>
              </td>
            </tr>
            <tr>
              <td>You’d like to invite conversation and learn more about a project.</td>
              <td>
                <div class="margin-10">“Whoa, that’s a crazy number of LEDs and wires! I see some LEDs are on, while others turn off when you plug that one in. What are your ideas about what’s happening? This would be handy as an example of how complicated this all can be. How would you feel if I shared it?”</div>
                <div class="margin-10">“That’s an awesome and surprising use of felt, leaves, and wire. What’s the story about how and why you chose these materials?”</div>
              </td>
            </tr>
            <tr>
              <td>You want to reinforce engagement, persistence, and a community of shared support.</td>
              <td>
                <div class="margin-10">“Wow, you really stuck with this and worked through all of those problems. Would it be OK if I sent others to you for help with this kind of thing?”</div>
                <div class="margin-10">“Have you drawn any of these setups? I’d love to be able to share them with everyone.”</div>
                <div class="margin-10">“Can I take a picture of this to use as an example if someone gets stuck? What else do you think might be helpful to share?”</div>
                <div class="margin-10">“I notice you’ve worked incredibly hard on this and have still taken time to help others when they’ve been stuck. I wonder about where you got your inspirations and who might have helped you. Did any ideas come to you when you were helping with other projects?”</div>
              </td>
            </tr>
            <tr>
              <td>A young maker is having a hard time getting started with open-ended explorations.</td>
              <td>
                <div class="margin-10">“I hear that you’re having a hard time getting started and would prefer an example. I do have some if you’d like, but how about waiting a bit and seeing what others are trying out? Perhaps something will inspire you. Try just messing around first, just playing and having fun. I’ll check back with you in a bit to see how you’re feeling.”</div>
                <div class="margin-10">“I do have something that I’d like to show later as a possibility. Can I show it to you first and see what you make of it? I could use your help figuring out what kinds of things it might be useful for. I’d love to have you share it and your ideas, if you like.”</div>
              </td>
            </tr>
            <tr>
              <td>You want to compliment and encourage the students.</td>
              <td>
                <div class="margin-10">Compliment the way that kids try different things (process) more than the results (outcome)</div>
                <div class="margin-10">“I admire how you worked through that hard problem. I noticed how you tried [x], [y], and [z] and you stuck with it until you figured it out.”</div>
                <div class="margin-10">“You must be proud of yourself for thinking about (small details).”</div>
                <div class="margin-10">“Look at that great sun you made, and you even included clouds!”</div>
                <div class="margin-10">“You worked really hard to cut straight lines, and I noticed you’re starting to do it better”</div>
                <div class="margin-10">“I see you’re working on your observation skills.”</div>
                <div class="margin-10">“I noticed that you put away your tools without being asked.”</div>
                <div class="margin-10">“I notice your determination even though you had faced many obstacles when you started.”</div>
                <div class="margin-10">“I see you took initiative to share with your friends”</div>
              </td>
            </tr>
            <tr>
              <td>Student asks you if their work is good enough.</td>
              <td>
                <div class="margin-10">“What do you think?”</div>
                <div class="margin-10">“Do you like what you did?”</div>
                <div class="margin-10">“What would you do differently if you had more resources?”</div>
                <div class="margin-10">“What would you do if you were to do it again?”</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/8');
};

const goToNextPage = () => {
  router.push('/makercart-guide/10');
};
</script>

<style scoped>
.tab-chinaus-active-primary {
    border-bottom: 2px solid #003D6A;
    color: #003D6A;
}</style>