<template>
    <div class="admin-container">
        <div @click="backtoListPage()" class="back-button">
            <img src="/images/chinaus/arrow-left.svg" alt="">
            <div>
                Back
            </div>
        </div>

        <div class="admin-header">
            <h1> {{ data.order_code }} Order details </h1>
        </div>
        <div style="padding-top: 0.8rem;padding-bottom: 0.8rem;"></div>
        <div style="display: flex; justify-content: space-between;">
            <span>Name</span>
            <span style="font-weight: 600;">{{ data.name }}</span>
        </div>
        <div class="border-line"></div>
        <div style="display: flex; justify-content: space-between;">
            <span>Email</span>
            <span style="font-weight: 600;">{{ data.email }}</span>
        </div>
        <div class="border-line"></div>
        <div style="display: flex; justify-content: space-between;">
            <span>School</span>
            <span style="font-weight: 600;">{{ data.school }}</span>
        </div>
        <div class="border-line"></div>
        <div style="display: flex; justify-content: space-between;">
            <span>Order Code</span>
            <span style="font-weight: 600;">{{ data.order_code }}</span>
        </div>
        <div class="border-line"></div>
        <div style="display: flex; justify-content: space-between;">
            <span>Order Date</span>
            <span style="font-weight: 600;">{{ data.order_date }}</span>
        </div>
        <div style="padding-top: 0.8rem;padding-bottom: 0.8rem;"></div>

        <div class="admin-table-container">
            <table class="admin-table">
                <thead>
                    <tr>
                        <th>Activity Name</th>
                        <th>Grade</th>
                        <th>Students</th>
                        <th>Focus</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>

                    <!-- <div v-for="(option, index) in filteredOptions" :key="index" @click.stop="selectOption(option)"> -->
                        <!-- {{ option.text }} -->
                    <!-- </div> -->

                    <tr v-for="(item, index) in data.order_items" :key="index" >
                        <td>{{ item.activity_name }}</td>
                        <td>{{ item.grade }}</td>
                        <td class="text-center">{{ item.number_of_students }}</td>
                        <td class="text-center">{{ item.focus }}</td>
                        <td>
                            <div class="custom-select">
                                <select @change="changeStatus(data.id, item.stem_makercart_id, $event.target.value)" v-model="item.status" class="color-primary" required>
                                    <option value="" disabled>Select Approval Status</option>
                                    <option value="OPEN">OPEN</option>
                                    <option value="CONFIRMED">CONFIRMED</option>
                                </select>
                                <img src="/images/arrow-bottom.svg" class="select-icon" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import makerCartAdminService from '@/services/makerCartAdminService';
import { useRouter, useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();

const data = ref({
    email: "",
    name: "",
    order_code: "",
    order_date: "",
    order_items: [],
    school: ""
});

const fetchData = async (id) => {
  try {
    const response = await makerCartAdminService.getOrderItems(id);
    if (response.status === 200) {
      data.value = response.data.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const changeStatus = async (id, makercartId, status) => {
  try {
    const response = await makerCartAdminService.updateOrderItemStatus(id, makercartId, status);
    if (response.status === 200) {
        alert('Change Status Success');
    } else {
        alert('Change Status Failed');
    }
  } catch (error) {
    console.log(error.message);
    alert('Change Status Failed');
  }
};

onMounted(() => {
  if (route.params.id) {
    fetchData(route.params.id);
  }
});

function backtoListPage() {
    router.push({ path: `/admin/maker-cart` });
}
</script>