// src/services/CommentAdminService.js

import http from "./httpService";

class TermAdminService {
  async getAll(params) {
    const url = http.getUrlWithParams("/admin/term", params);
    return await http.get(url);
  }

  async getById(id) {
    const url = `/admin/term/${id}`;
    return await http.get(url);
  }  

  async create(data) {
    const url = "/admin/term";
    return await http.post(url, data);
  }

  async update(id, data) {
    const url = `/admin/term/${id}`;
    return await http.put(url, data);
  }

  async remove(id) {
    const url = `/admin/term/${id}`;
    return await http.delete(url);
  }

  async getAllSchool() {
    const url = `/admin/term/school`;
    return await http.get(url);
  }  
}

export default new TermAdminService();
