<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/frame-10000064021.png" alt="Rectangle 4689">
      <div class="x-overlay-text">
        <h1>Conducting a Maker Activity</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div class="margin-10" style="width: 80%;">
        <h2 class="color-primary margin-10">Role 2: Posing Questions</h2>
        <p class="fs-12 margin-10">
          Effective questioning is the cornerstone of meaningful learning. This section explores how to craft powerful questions that spark curiosity, ignite imagination, and guide learners towards deeper understanding during maker activities. We will examine the power of open-ended questions, explore different question types, and discuss strategies for facilitating productive discussions and inquiry.
        </p>
    </div>

    <div class="x-content-card x-content-flex">
      <div class="x-content-text">
        <div class="x-title-container">
          <h2 v-if="currentCardIndex === 0" class="color-primary margin-10">Creating an Atmosphere for Sharing Ideas</h2>
          <h2 v-if="currentCardIndex === 1" class="color-primary margin-10">Types of Questions</h2>
          <h2 v-if="currentCardIndex === 2" class="color-primary margin-10">Ask investigable questions</h2>
          <div class="x-arrows">
            <button id="previous-button" @click="goToPreviousCard" :disabled="currentCardIndex === 0">
              <img class="x-arrow x-arrow-left" :class="{ 'x-arrow-disabled': currentCardIndex === 0 }" src="/images/icon/arrow-square-up.svg" alt="Previous">
            </button>
            <button id="next-button" @click="goToNextCard" :disabled="currentCardIndex === 2">
              <img class="x-arrow x-arrow-right" :class="{ 'x-arrow-disabled': currentCardIndex === 2 }" src="/images/icon/arrow-square-up.svg" alt="Next">
            </button>
          </div>
        </div>
        <div class="x-underline"></div>

        <!-- Card 1 Content -->
        <div v-if="currentCardIndex === 0">
          <p class="fs-12 margin-1b">
            Encourage students to write down all of their questions. Reassure them, if necessary, that no question is “wrong.”
          </p>
          <p class="fs-12 margin-1b">
            Try using these strategies:
          </p>
          <ol>
            <li class="sm-font"><b>Use sufficient “wait time” after asking a question.</b></li>
            <li class="sm-font"><b>Accept all ideas as equally worthy of consideration, whether scientifically correct or not.</b></li>
            <li class="sm-font"><b>Consider ideas collectively as “our” ideas; don’t identify them with individual students.</b></li>
            <li class="sm-font"><b>Set an example by being patient, sympathetic, encouraging, and fair.</b></li>
          </ol>
        </div>

        <!-- Card 2 Content -->
        <div v-if="currentCardIndex === 1">
          <ol class="margin-10">
            <li class="sm-font">Subject-centred question:
              <ul>
                <li class="sm-font">“What causes ….”</li>
                <li class="sm-font">“What makes …”</li>
                <li class="sm-font">“What affects …”</li>
                <li class="sm-font">“What is the difference …”</li>
                <li class="sm-font">“What factors …”</li>
              </ul>
            </li>
            <li class="sm-font">Person-centred question:
              <ul>
                <li class="sm-font">“Why do you think…”</li>
                <li class="sm-font">“How do you explain…”</li>
                <li class="sm-font">“Can you predict what will happen if…”</li>
              </ul>
            </li>
            <li class="sm-font">Process-centred question:
              <ul>
                <li class="sm-font">“What happens when…”</li>
                <li class="sm-font">“What changes do you see when…”</li>
                <li class="sm-font">“Have you seen any patterns?”</li>
                <li class="sm-font">“What might you do to…”</li>
              </ul>
            </li>
          </ol>

          <p><b>Note that most questions that invite students to express their own ideas are open-ended and person-centered.</b></p>
          <p class="fs-12 margin-10">You can also try these with your students. Notice how many different questions came up. Even though everyone observed the same phenomenon, people saw very different things. Notice that the phenomenon was very simple, yet it was intriguing enough to pique everyone’s curiosity. Notice that there was ample time to observe, so a rich variety of questions could be generated by each observer.</p>
        </div>

        <!-- Card 3 Content -->
        <div v-if="currentCardIndex === 2">

          <div class="margin-1b">
            <p class="fs-12">This tip is adapted from <b>Institute for Inquiry</b>.</p> 
          </div>

          <div class="margin-1b">
            <p class="fs-12">Questions that lead to taking action and firsthand experiences with materials are considered <b>“investigable”</b>. For example, questions that begin with “what will happen if . . . “ or contain the phrase “does the _____ make a difference” can be investigated. The way they are phrased invites one to experiment with materials and phenomena.</p>
          </div>

          <div class="margin-1b">
            <p class="fs-12">Conversely, questions that do not lead to taking hands-on action are considered “non-investigable.” For example, questions that begin with “why”—such as “Why is most of the ice balloon underneath the water?” or “Why are parts of the ice balloon cloudy?” are considered non-investigable. They're stated in a way that does not lead directly to hands-on action that would help answer the question as stated. Instead, they're requests for information or explanations.</p>
          </div>

          <div class="margin-1b">
            <p class="fs-12">Using a technique called “variables scan”, we can scan a non-investigable question to identify the variables in it—that is, examining it to find elements you can change in an experiment.</p>
          </div>

          <div style="color: #00925E;" class="margin-1b">
            <p>Example: Why does ice melt so fast when you put it in water?</p>
            <ol>
              <li class="sm-font">What are the variables? (1. ice; 2. water or liquid)</li>
              <li class="sm-font">How can the ice be changed? (e.g., size and shape; how much it's submerged.)</li>
              <li class="sm-font">How can the water/liquid be changed? (e.g., amount; temperature; adding salt or sugar; different types of liquids)</li>
              <li class="sm-font">Turn it into a investigable question
                <ul>
                  <li class="sm-font">What happens when I change the shape of the ice? Do lots of little pieces melt faster than one big piece? What happens if I put ice in salt water? Does the ice melt faster in a quart of water or a gallon of water?</li>
                </ul>
              </li>
            </ol>
          </div>

        </div>
      </div>
    </div>

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const currentCardIndex = ref(0);

const goToPreviousCard = () => {
  if (currentCardIndex.value > 0) {
    currentCardIndex.value--;
  }
};

const goToNextCard = () => {
    currentCardIndex.value++;
};

const goToPreviousPage = () => {
  router.push('/makercart-guide/7');
};

const goToNextPage = () => {
  router.push('/makercart-guide/9');
};
</script>

<style scoped>
.tab-chinaus-active-primary {
    border-bottom: 2px solid #003D6A;
    color: #003D6A;
}
</style>