<template>
  <div>
    <div class="card-header">
      <span class="card-header-created">Created:</span>
      <span class="card-header-date">{{ formattedDate(resource.date) }}</span>
    </div>
    <div class="card">
      <div class="card-content" style="display:flex;justify-content: start;gap: 1em;">
        <div>
          <img v-if="resource.thumbnail" :src="resource.thumbnail" style="height: 150px;">
          <img v-else src="./images/content-placeholder.svg" style="height: 150px;">
        </div>
        <div>
          <div style="display:flex;justify-content:start;gap: 0.5em;">
            <div style="display:flex;justify-content:start;gap: 0.5em;">
              <div style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;">
                <img height="20" src="/images/ai-assisted.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                <span style="font-size: 0.8em;margin-top: 0.2em;">AI Assisted</span>
              </div>
              <div v-if="!resource.is_liked" @click="likeLessonPackage(resource.id)" style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer;">
                <img height="20" src="/images/like-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;">Like</span>
              </div>
              <div v-else @click="likeLessonPackage(resource.id)" style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer;">
                <img height="20" src="/images/liked-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;">Liked</span>
              </div>
            </div>
          </div>
          <h2 :title="resource.title">{{ truncateText(resource.title) }}</h2>
          <p>{{ resource.short_description }}</p>
          <div class="tags">
            <template v-for="(tag, index) in resource.software" :key="tag">
              <span class="tag">{{ tag }}</span>
              <span v-if="index < resource.software.length - 1"> | </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <a :href="`/lesson-packages/${resource.id}`" target="_blank" class="button">View</a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  resource: Object
});

const emit = defineEmits(['like']);

function formattedDate(date1) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const date = new Date(date1);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}${getOrdinalSuffix(day)} ${year}`;
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

function likeLessonPackage(id) {
  emit('like', id);  // Emit the event to the parent
}

const truncateText = (text) => {
  if (text.length > 60) {
    return text.substring(0, 60) + '...';
  }
  return text;
}
</script>
