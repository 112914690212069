// src/services/ScormFilesService.js

import httpExternal from "./httpExternalService";

class ScormFilesService {
  async getAll(params) {
    const url = httpExternal.getUrlWithParams("https://scorm.eddy4teachers.com/api/scorm/list", params);
    return await httpExternal.get(url);
  }

  async remove(id) {
    const url = `https://scorm.eddy4teachers.com/api/scorm/delete/${id}`;
    return await httpExternal.delete(url);
  }

  async upload(file) {
    const url = "https://scorm.eddy4teachers.com/api/scorm/storeapi";
    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return await httpExternal.post(url, formData, config);
  }
}

export default new ScormFilesService();
