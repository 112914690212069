<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Sub Categories</h1>
    </div>
    <div class="border-container-unique37">
      <div class="admin-list-title">List of Sub Categories</div>
      <div>You can add, edit, and delete all of Sub Categories from this page.</div>

      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-search-container">
          <input type="text" class="admin-search-input" placeholder="Search..." v-model="searchQuery" @input="debouncedgetAll">
          <img src="/images/maker-cart/union.svg" alt="Search" class="admin-search-icon">
        </div>
        <a class="btn btn-create-list mx-2 my-md-auto" :href="`/admin/terms/form/${route.params.id}`">
          <span class="icon"><img src="/images/icon/plus-circle.svg" alt="download-icon"></span>
          <span class="admin-button-text">Add Sub Categories</span>
        </a>
      </div>
      <div class="admin-list-tool-container">
      </div>

      <div style="margin: 10px 0px;">
        <a href="/admin/categories" style="color: #e00069;cursor: pointer;text-decoration: none;">Categories / </a>
        <span style="color: #555555;">{{ category.title }}</span> 
      </div>
      <div class="admin-table-container">
        <table class="admin-table">
          <thead>
            <tr>
              <th @click="toggleSortOrder('slug')" class="sortable-column">
                <div class="sortable-header">
                  <span>Slug</span>
                  <span>{{ sortOrderArrow('slug') }}</span>
                </div>
              </th>
              <th @click="toggleSortOrder('title')" class="sortable-column">
                <div class="sortable-header">
                  <span>Title</span>
                  <span>{{ sortOrderArrow('title') }}</span>
                </div>
              </th>
              <th>Priority</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="!tableLoading && data.data.rows.length > 0">
            <tr v-for="(item, index) in data.data.rows" :key="index">
              <td>{{ item.slug }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.priority }}</td>
              <td>
                <div class="admin-action-column">
                  <a :href="`/admin/terms/form/${route.params.id}/${item.id}`">Edit</a>
                  <a href="#" @click="openModal(item.id)"><img src="/images/admin/trash.svg" alt="Delete"></a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!tableLoading && data.data.rows.length === 0">
            <tr>
              <td colspan="6">
                <div class="no-data-message">No data available</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <div class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data.data.total_pages > 0" class="pagination-controls">
        <img @click="prevPage" src="/images/chinaus/vector-right.svg" class="mr-2" alt="">
        <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ 'page-active': page === currentPage }">{{ page }}</button>
        <img @click="nextPage" src="/images/chinaus/vector-left.svg" class="ml-2" alt="">
      </div>
    </div>
  </div>

  <ModalComponent :visible="showModalDelete" @update:visible="updateVisibilityModalDelete">
    <div class="container-modal">
      <div class="modal-title">Remove Category?</div>
      <div class="border-header"></div>
      <div style="display: flex;justify-content: center;font-size: 1.4em;margin: 15px 0px;">Are you sure want to remove this category?</div>
      <div class="modal-button">
        <button class="btn btn-discard" @click="updateVisibilityModalDelete">Back</button>
        <button class="btn admin-confirm-button" :class="{ 'btn-save-loading': isLoading, 'btn-save': !isLoading }" :disabled="isLoading" :style="{ 'background-color': isLoading ? '#CCCCCC' : '#003D6A' }" @click="remove(itemId)">Confirm</button>
      </div>
    </div>
  </ModalComponent>
  
</template>

<script setup>
/* eslint-disable */ 
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import TermAdminService from '@/services/termAdminService';
import CategoryAdminService from '@/services/categoryAdminService';
import ModalComponent from '@/components/ModalComponent.vue';
import { debounce } from 'lodash';

const showModalDelete = ref(false);
const isLoading = ref(false);
const itemId = ref(null);
const tableLoading = ref(true);
const searchQuery = ref("");
const currentStatus = ref("");

const route = useRoute();

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_pages: 0,
    rows: []
  }
});

// const taxonomyId = route.params.id !== undefined;

const currentPage = ref(1);
const sortOrder = ref('desc');
const sortKey = ref('id');

const category = ref({ "id": null, "name": null, "title": null });

onMounted(async () => {
  await getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value, route.params.id);
  await getCategorybyId(route.params.id);
  window.addEventListener('resize', updateLayout);
  updateLayout();
});

const getAll = async (page, search, key, sort_by, order, taxonomy) => {
  try {
    tableLoading.value = true;

    const param = {page, search, key, sort_by, order, taxonomy}

    const response = await TermAdminService.getAll(param);
    if (response.status === 200) {
      tableLoading.value = false;
      data.value = response.data;
    }
  } catch (error) {
    tableLoading.value = false;
    console.log(error.message);
  }
};

const getCategorybyId = async (id) => {
  try {
    const response = await CategoryAdminService.getById(id);
    if (response.status === 200) {
      category.value = response.data.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const debouncedgetAll = debounce(() => {
  currentPage.value = 1;
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value, route.params.id);
}, 300);

const changePage = (page) => {
  currentPage.value = page;
  if (currentPage.value > 0 && page <= data.value.data.total_pages) {
    getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value, route.params.id);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    changePage(currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < data.value.data.total_pages) {
    changePage(currentPage.value + 1);
  }
};

const openModal = (id) => {
  itemId.value = id; // Set itemId
  showModalDelete.value = true;
};

const visiblePages = computed(() => {
  const total = data.value.data.total_pages;
  const current = currentPage.value;
  const start = Math.max(1, current - 2);
  const end = Math.min(total, current + 2);

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

const updateVisibilityModalDelete = () => {
  showModalDelete.value = !showModalDelete.value;
};

const remove = async (id) => {
  isLoading.value = true;
  try {
    await TermAdminService.remove(id);
    isLoading.value = false;
    showModalDelete.value = false;
    getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value, route.params.id);
    alert('Sub Category deleted successfully');
  } catch (error) {
    isLoading.value = false;
    console.error(error.message);
    alert('Failed to delete category');
  }
};
// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector('.admin-flex-container');
  const toolContainer = document.querySelector('.admin-list-tool-container');
  const parentContainer = document.querySelector('.border-container-unique37');
  const width = window.innerWidth;
  
  if (width <= 991) {
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};

const toggleSortOrder = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortKey.value = key;
    sortOrder.value = 'asc';
  }
  
  getAll(currentPage.value, searchQuery.value, currentStatus.value, sortKey.value, sortOrder.value, route.params.id);
};

const sortOrderArrow = (key) => {
  if (sortKey.value !== key) return '';
  return sortOrder.value === 'asc' ? '▲' : '▼';
};

function convertTimestamp(timestamp) {
    // Buat objek Date dari timestamp
    var date = new Date(timestamp * 1000);

    // Format tanggal dan waktu
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    var seconds = ('0' + date.getSeconds()).slice(-2);

    // Gabungkan hasil format
    var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDate;
}


</script>
