<template>
  <div class="border-container-unique38 mb-4">
    <div class="admin-content-layout">
      <div class="admin-main-content" style="padding-top: 0px;max-width: 100%;padding-right: 0px;overflow-x: scroll;">
        <div class="d-flex justify-space-between">
          <div class="admin-header">
            <h1>
              <img src="/images/icon/people.svg" class="filter-primary" height="20" alt="">
              Visitors
            </h1>
          </div>
          <button v-if="errorRegistration" @click="getRegisteredUsersByMonth" class="btn btn-retry">Retry</button>
        </div>
        <div v-if="loadingRegistration" class="loading-state">Loading...</div>
        <div v-else-if="errorRegistration" class="error-state">Error: {{ errorRegistration }}</div>
        <div v-else>
          <LineChart :chart-data="chartData" :chart-options="chartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import DashboardService from '@/services/dashboardService';
import LineChart from '@/components/common/chart/LineChartComponent.vue';

const registeredUsersByMonth = ref([]);
const loadingRegistration = ref(true);
const errorRegistration = ref(null);
const chartData = ref({
  labels: [],
  datasets: []
});
const chartOptions = ref({
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: false,
      text: 'New and Active Users Per Month'
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      grid: {
        display: false
      }
    }

  }
});

const getRegisteredUsersByMonth = async () => {
  loadingRegistration.value = true;
  errorRegistration.value = null;

  try {
    const response = await DashboardService.getReturningChart();
    if (response.status === 200) {
      const data = response.data.data;
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"];
      registeredUsersByMonth.value = data.map(item => {
        const month = monthNames[parseInt(item.yearMonth.split('-')[1]) - 1];
        return { ...item, month };
      });
      registeredUsersByMonth.value.sort((a, b) => new Date(a.month) - new Date(b.month));

      chartData.value = {
        labels: registeredUsersByMonth.value.map(item => item.month),
        datasets: [
          {
            label: 'New Users',
            data: registeredUsersByMonth.value.map(item => item.newUsers),
            backgroundColor: '#4C4DDC',
            borderColor: '#4C4DDC',
            borderWidth: 1,
            fill: false
          },
          {
            label: 'Active Users',
            data: registeredUsersByMonth.value.map(item => item.activeUsers),
            backgroundColor: '#E00069',
            borderColor: '#E00069',
            borderWidth: 1,
            fill: false
          }
        ]
      };
    } else {
      errorRegistration.value = response.message;
    }
  } catch (error) {
    errorRegistration.value = error.message;
  } finally {
    loadingRegistration.value = false;
  }
};

onMounted(() => {
  getRegisteredUsersByMonth();
});

watch(registeredUsersByMonth, (newVal) => {
  if (newVal.length) {
    chartData.value = {
      labels: newVal.map(item => item.month),
      datasets: [
        {
          label: 'New Users',
          data: newVal.map(item => item.newUsers),
          backgroundColor: '#4C4DDC',
          borderColor: '#4C4DDC',
          borderWidth: 1,
          fill: false
        },
        {
          label: 'Returning Users',
          data: newVal.map(item => item.activeUsers),
          backgroundColor: '#E00069',
          borderColor: '#E00069',
          borderWidth: 1,
          fill: false
        }
      ]
    };
  }
});
</script>