<template>
    <div class="admin-container">
      <div class="admin-header">
        <h1>{{ isEditMode ? 'Edit Category' : 'Add Category' }}</h1>
      </div>
      <div class="border-container-unique37">
        <form @submit.prevent="validateForm" novalidate>
          <div class="admin-form-group mb-3">
            <label>Title</label>
            <input type="text" v-model="form.title" required />
            <span v-if="errors.title" class="error-message-123">{{ errors.title }}</span>
          </div>
          <div class="admin-form-group mb-3">
            <label>Name</label>
            <input type="text" v-model="form.name" required />
            <span v-if="errors.name" class="error-message-123">{{ errors.name }}</span>
          </div>
          <button type="submit" class="submit-btn">{{ isEditMode ? 'Update' : 'Submit' }}</button>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
  import { reactive, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import CategoryAdminService from '@/services/categoryAdminService';
  
  const router = useRouter();
  const route = useRoute();
  
  const form = reactive({
    title: '',
    name: ''
  });
  
  const errors = reactive({
    title: '',
    name: ''
  });
  
  const isEditMode = route.params.id !== undefined;
  
  const fetchCategory = async (id) => {
    try {
      const response = await CategoryAdminService.getById(id);
      if (response.status === 200) {
        const category = response.data.data;
        form.title = category.title;
        form.name = category.name;
      }
    } catch (error) {
      console.error(error.message);
      alert('Failed to fetch category data');
    }
  };
  
  onMounted(() => {
    if (isEditMode) {
      fetchCategory(route.params.id);
    }
  });
  
  const validateForm = async () => {
    // Reset errors
    Object.keys(errors).forEach(key => {
      errors[key] = '';
    });
  
    let isValid = true;
  
    // Validate required fields
    Object.keys(form).forEach(key => {
      if (!form[key]) {
        errors[key] = `${key} is required`;
        isValid = false;
      }
    });
  
    if (!isValid) {
      return;
    }
  
    try {
      if (isEditMode) {
        await CategoryAdminService.update(route.params.id, form);
        alert('Category updated successfully!');
      } else {
        await CategoryAdminService.create(form);
        alert('Category created successfully!');
      }
      router.push('/admin/categories');
    } catch (error) {
      console.error(error.message);
      alert('Failed to submit form!');
    }
  };
  </script>
  