// src/services/CategoryAdminService.js
import http from './httpService';

class CategoryAdminService {

  async getAll(params) {
    const url = http.getUrlWithParams("/admin/categories", params);
    return await http.get(url);
  }

  async getById(id) {
    const url = `/admin/categories/${id}`;
    return await http.get(url);
  }

  async create(category) {
    const url = "/admin/categories";
    return await http.post(url, category);
  }

  async update(id, category) {
    const url = `/admin/categories/${id}`;
    return await http.put(url, category);
  }

  async remove(id) {
    const url = `/admin/categories/${id}`;
    return await http.delete(url);
  }
}

export default new CategoryAdminService();
