<template>
  <div style="margin-top: 20px;" class="x-page-container">
    <div style="width: 80%;">
      <div class="x-header color-primary">
        <img src="/images/maker-cart-guide/maker-cart-guide.svg" height="45px" alt="MakerCart Educator's Guide Header">
        <h1>MakerCart Educator's Guide</h1>
      </div>
      <p class="fs-12 margin-10">
        Congratulations on the purchase of your new MakerCart! This guide will get you started on the ethos behind the
        MakerCart, and how you can use it to transform learning in your classroom. Get started below - we can't wait to
        see what you'll accomplish with your new Cart!
      </p>

      <div style="border-bottom: 1px solid #e0e0e0;margin-top:20px;margin-bottom: 30px;"></div>
    </div>

    <div class="x-image-overlay-section">
      <img class="x-full-width-image" src="/images/maker-cart-guide/rectangle-4690.png" alt="Rectangle 4690">
      <div class="x-overlay-text">
        <h1>Maker Activity Lesson Flow</h1>
      </div>
    </div>

    <FloatingMenu />

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>

    <div style="display: flex; gap: 10px; width: 80%;">
      <div class="x-header color-primary">
        <h1>General Maker Activity Lesson Structure</h1>
      </div>
    </div>

    <div style="position: relative;" class="x-content-card x-content-flex color-primary">
      <img style="position: absolute;z-index: 0;top: 0;left: 0;" src="/images/maker-cart-guide/1.svg" height="150px" alt="" srcset="">
      <div style="z-index: 1" class="x-content-text">
        <div class="x-header">
          <h1>Hook</h1>
        </div>
        <p class="fs-12 margin-10">'A Hook' is the activity that builds context and piques interest. It could be a probing question or summary that helps students to reflect on their present knowledge and prepares them for the new knowledge.</p>
      </div>
    </div>

    <div style="position: relative;" class="x-content-card x-content-flex color-primary">
      <img style="position: absolute;z-index: 0;top: 0;left: 0;" src="/images/maker-cart-guide/2.svg" height="150px" alt="" srcset="">
      <div style="z-index: 1" class="x-content-text">
        <div class="x-header">
          <h1>Maker element</h1>
        </div>
        <p class="fs-12 margin-10">The 'maker element' can be described as an activity in one of the following ways:</p>
        <ol>
          <li class="fs-12">Helps to discover a new concept through tinkering with materials (Tinker to Discover approach) or Provides them with an opportunity to demonstrate their subject knowledge by working on a project, and applying what they have learned (Application Project approach)</li>
          <li class="fs-12">Builds on their existing subject knowledge through making to cement or deepen their understanding (Make to Learn approach) or</li>
        </ol>
      </div>
    </div> 

    <div style="position: relative;" class="x-content-card x-content-flex color-primary">
      <img style="position: absolute;z-index: 0;top: 0;left: 0;" src="/images/maker-cart-guide/3.svg" height="150px" alt="" srcset="">
      <div style="z-index: 1" class="x-content-text">
        <div class="x-header">
          <h1>Sharing activity</h1>
        </div>
        <p class="fs-12 margin-10">This is the 'show and tell' activity that helps students to present what they have created, share their learnings and take feedback from others.</p>
      </div>
    </div> 

    <div style="position: relative;" class="x-content-card x-content-flex color-primary">
      <img style="position: absolute;z-index: 0;top: 0;left: 0;" src="/images/maker-cart-guide/4.svg" height="150px" alt="" srcset="">
      <div style="z-index: 1" class="x-content-text">
        <div class="x-header">
          <h1>Reflection</h1>
        </div>
        <p class="fs-12 margin-10">This helps learners to connect the concepts they have learned to practice. They bring focus back to the most important learning goals through the lesson.</p>
      </div>
    </div> 

    <div style="position: relative;" class="x-content-card x-content-flex color-primary">
      <img style="position: absolute;z-index: 0;top: 0;left: 0;" src="/images/maker-cart-guide/5.svg" height="150px" alt="" srcset="">
      <div style="z-index: 1" class="x-content-text">
        <div class="x-header">
          <h1>Exit ticket</h1>
        </div>
        <p class="fs-12 margin-10">'Exit ticket' helps educators assess informally how well learners have learned the topic.</p>
      </div>
    </div> 

    <div class="margin-10" style="display: flex; justify-content: end; gap: 10px; width: 80%;">
      <button class="x-guide-button x-guide-button--strip-green" @click="goToPreviousPage">Previous</button>
      <button class="x-guide-button x-guide-button--green" @click="goToNextPage">Next Page</button>
    </div>
  </div>
</template>

<script setup>
import FloatingMenu from '@/components/maker-cart/FloatingMenu.vue';
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToPreviousPage = () => {
  router.push('/makercart-guide/3');
};

const goToNextPage = () => {
  router.push('/makercart-guide/5');
};
</script>

