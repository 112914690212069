<template>
  <div class="custom-select-wrapper" @click.stop="toggleDropdown">
    <div class="select-selected">
      <span v-html="modelValue"></span> 
      <img v-if="icon" :src="icon" height="10" class="icon" alt="icon"/>
    </div>
    <div v-show="showDropdown" class="select-items">
      <div
        v-for="option in props.options"
        :key="option"
        @click.stop="selectOption(option)"
        :class="{ 'same-as-selected': option === modelValue }"
      >
        <span v-html="option"></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, defineEmits } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  icon: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    required: true
  }
});

const emits = defineEmits(['update:modelValue', 'change']);

const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const selectOption = (option) => {
  emits('update:modelValue', option);
  emits('change', option);
  showDropdown.value = false;
};

const handleClickOutside = (e) => {
  const wrapper = document.querySelector('.custom-select-wrapper');
  if (!wrapper.contains(e.target)) {
    showDropdown.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 200px;
}

.select-selected {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 7px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
}

.select-selected:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: transparent;
  transform: translateY(-50%);
}

.select-items {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-items div {
  padding: 10px;
  cursor: pointer;
}

.select-items div:hover {
  background-color: #f1f1f1;
}

.same-as-selected {
  background-color: #ddd;
}
</style>
