<template>
  <!-- Top section spanning the entire width -->
  <TopSectionSpanning :topSectionHeight="topSectionHeight" />
  <div :style="{ paddingTop: topSectionHeight }" class="admin-layout">
    <AdminSidebar class="sidebar" :style="{ marginTop: topSectionHeight }" :mobileMenuOpen="mobileMenuOpen" @toggle-mobile-menu="toggleMobileMenu" />
    <div class="main-content-admin" :class="{ 'mobile-open': mobileMenuOpen }">
      <AdminHeader @toggle-mobile-menu="toggleMobileMenu" />
      <main class="content-admin">
        <router-view />
      </main>
      <AdminFooter class="footer" />
    </div>
    <div class="overlay" v-if="mobileMenuOpen" @click="toggleMobileMenu"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AdminHeader from '@/components/admin/AdminHeader.vue';
import AdminSidebar from '@/components/admin/AdminSidebar.vue';
import AdminFooter from '@/components/admin/AdminFooter.vue';
import TopSectionSpanning from '@/components/common/TopSectionSpanning.vue';

const mobileMenuOpen = ref(false);
const topSectionHeight = ref('40px');

function toggleMobileMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
}

// eslint-disable-next-line
function toggleInfoHeader() {
  if (topSectionHeight.value === '40px') {
    topSectionHeight.value = '0px';
  } else {
    topSectionHeight.value = '40px';
  }
}
</script>

<style scoped>
.admin-layout {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.main-content-admin {
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  position: relative;
}

.content-admin {
  flex: 1;
  overflow-y: auto;
  background-color: white;
}

.footer {
  flex: 0 0 auto;
}

/* Media Queries for Mobile */
@media (max-width: 991px) {
  .admin-layout {
    flex-direction: column;
  }
  
  .sidebar {
    width: 250px;
    height: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.mobile-open {
    transform: translateX(0);
  }

  .main-content-admin {
    margin-left: 0;
    transition: transform 0.3s ease-in-out;
  }

  .main-content-admin.mobile-open {
    transform: translateX(250px);
  }

  .hamburger-menu {
    display: block;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
</style>
