<template>
    <div class="border-container-unique38 mb-4">
        <div class="admin-content-layout">
            <div class="admin-main-content" style="padding-top: 0px;max-width: 100%;padding-right: 0px;overflow-x: scroll;">
                <div class="d-flex justify-space-between">
                    <div class="admin-header">
                        <h1><img src="/images/icon/document-normal.svg" class="filter-primary" height="20" alt=""> Resource View</h1>
                    </div>
                </div>
                <div class="tabs">
                    <span 
                        class="tab" 
                        :class="{ 'tab-active-primary': activeTab === 'lesson' }" 
                        @click="changeTab('lesson')">
                        Online Making
                    </span>
                    <span 
                        class="tab" 
                        :class="{ 'tab-active-primary': activeTab === 'instructional_strategies' }" 
                        @click="changeTab('instructional_strategies')">
                        Professional Development
                    </span>
                    <span 
                        class="tab" 
                        :class="{ 'tab-active-primary': activeTab === 'tech_tutorials' }" 
                        @click="changeTab('tech_tutorials')">
                        Maker Resources
                    </span>
                </div>
                <div class="d-flex mb-3">
                    <div class="admin-stat" style="width: 100%;">
                        <div class="admin-table" style="margin-bottom: 0px;">
                            <div class="admin-table td" style="margin-bottom: 0px;">
                                <div class="admin-list-title color-primary">Total Views</div>
                                <div>
                                    <p class="admin-stat-mins">{{ totalTopViews }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loadingTopViews" class="loading-state">Loading...</div>

                <!-- Error State -->
                <div v-if="errorTopViews" class="error-state">{{ errorTopViews }}</div>

                <!-- Result State -->
                <div v-if="!loadingTopViews && !errorTopViews" class="admin-table-container">
                    <table class="admin-table">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Resources Title</th>
                                <th>Grade</th>
                                <th>Subject</th>
                                <th>Views</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in topViews" :key="item.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.grade || 'N/A' }}</td>
                                <td>{{ item.subject || 'N/A' }}</td>
                                <td>{{ item.view }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import DashboardService from '@/services/dashboardService.js';

const topViews = ref([]);
const totalTopViews = ref(0);
const loadingTopViews = ref(true);
const errorTopViews = ref(null);
const activeTab = ref('lesson');

const getTopViews = async (postType) => {
    loadingTopViews.value = true;
    errorTopViews.value = null;

    try {
        const param = {
            post_type: postType
        }
        const response = await DashboardService.getTopViews(param);
        if (response.status === 200) {
            topViews.value = response.data.data;
            totalTopViews.value = response.data.data.reduce((total, item) => total + item.view, 0);
        } else {
            errorTopViews.value = response.message;
        }
    } catch (error) {
        errorTopViews.value = error.message;
    } finally {
        loadingTopViews.value = false;
    }
};

const changeTab = (tab) => {
    activeTab.value = tab;
    getTopViews(tab);
};

onMounted(() => {
    getTopViews('lesson');
});
</script>

<style>
.loading-state {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #999;
}

.error-state {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: red;
}

.tab {
    cursor: pointer;
}

.tab-active-primary {
    font-weight: bold;
}
</style>
