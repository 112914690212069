<template>
  <div class="x-page-container">
    <div class="x-header-image">
      <img src="/images/maker-cart-guide/background.png" alt="Background Page" />
    </div>
    <div class="x-content-card x-homepage-margin">
      <div class="x-center-content">
        <img src="/images/maker-cart-guide/teachers-day-bro.svg" alt="Teachers Image" class="x-teachers-image" />
      </div>
      <h1 class="color-primary margin-1b">Congratulations on the purchase of your new Makercart!</h1>
      <p class="margin-1b fs-12">
        This guide will get you started on the ethos behind the Makercart, and how you can use it to transform learning in your classroom. 
        <strong>Get started below</strong> - we can't wait to see what you'll accomplish with your new Cart!
      </p>
      <p class="fs-12" style="margin-bottom: 25px;"> 
        This Educator's Guide will be most helpful for educators new to facilitating a maker activity, although more experienced educators will find useful tips as well. It includes advice on what you can do before, during, and after the maker activity, sample guiding prompts, and more. These tips are a compilation from the implementation of Maker programs in different schools, through observation and feedback. 
      </p>
      <button class="x-guide-button x-guide-button--blue" @click="goToGuide">Go to Educator's Guide</button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import '@/assets/maker-cart-guide.css';

const router = useRouter();

const goToGuide = () => {
  router.push('/makercart-guide/1');
};
</script>
