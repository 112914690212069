import { createRouter, createWebHistory } from "vue-router";
import { isAdmin } from "./middlewares";
import LoginPage from "../views/LoginPage.vue";
import HomePage from "../views/HomePage.vue";
import PreviewPage from "../views/PreviewPage.vue";
import EditPage from "../views/EditPage.vue";
import PrintPage from "../views/PrintPage.vue";
import ProfilePage from "../views/ProfilePage.vue";
import LessonPackagePage from "../views/LessonPackagePage.vue";
import cookiesService from "@/services/cookiesService"; // Import service
import AuthService from "@/services/authService"; // Import service
import ChinaUSPage from "../views/ChinaUs/ChinaUSPage.vue";
import FAQPage from "../views/ChinaUs/FAQPage.vue";
import CompetitionDetailsPage from "../views/ChinaUs/CompetitionDetailsPage.vue";
import DiscussionPage from "../views/ChinaUs/DiscussionPage.vue";
import DiscussionDetailsPage from "../views/ChinaUs/DiscussionDetailsPage.vue";
import SubmissionPage from "../views/ChinaUs/SubmissionPage.vue";
import SubmissionSubmitPage from "../views/ChinaUs/SubmissionSubmitPage.vue";
import SubmissionListPage from "../views/ChinaUs/SubmissionListPage.vue";
import SubmissionDetailPage from "../views/ChinaUs/SubmissionDetailPage.vue";
import SubmissionReviewPage from "../views/ChinaUs/SubmissionReviewPage.vue";
import ChinaUSAdminPage from "../views/Admin/ChinaUSAdminPage.vue";
import ChinaUSProjectDetailPage from "../views/Admin/ChinaUSProjectDetailPage.vue";
import MakerCartPage from "../views/MakerCart/MakerCartPage.vue";
import MakerCartGuidePage from "../views/MakerCartGuide/MakerCartGuidePage.vue";
import MakerCartGuide1Page from "../views/MakerCartGuide/MakerCartGuide1Page.vue";
import MakerCartGuide2Page from "../views/MakerCartGuide/MakerCartGuide2Page.vue";
import MakerCartGuide3Page from "../views/MakerCartGuide/MakerCartGuide3Page.vue";
import MakerCartGuide4Page from "../views/MakerCartGuide/MakerCartGuide4Page.vue";
import MakerCartGuide5Page from "../views/MakerCartGuide/MakerCartGuide5Page.vue";
import MakerCartGuide6Page from "../views/MakerCartGuide/MakerCartGuide6Page.vue";
import MakerCartGuide7Page from "../views/MakerCartGuide/MakerCartGuide7Page.vue";
import MakerCartGuide8Page from "../views/MakerCartGuide/MakerCartGuide8Page.vue";
import MakerCartGuide9Page from "../views/MakerCartGuide/MakerCartGuide9Page.vue";
import MakerCartGuide10Page from "../views/MakerCartGuide/MakerCartGuide10Page.vue";
import MakerCartGuide11Page from "../views/MakerCartGuide/MakerCartGuide11Page.vue";

import AdminDashboardPage from "../views/Admin/DashboardPage.vue"

import LessonPlansListPage from "../views/Admin/LessonPlans/ListPage.vue";
import LessonPlansFormPage from "../views/Admin/LessonPlans/FormPage.vue";

import UsersListPage from "../views/Admin/Users/ListPage.vue";
import UsersFormPage from "../views/Admin/Users/FormPage.vue";

import CategoriesListPage from "../views/Admin/Categories/ListPage.vue";
import CategoriesFormPage from "../views/Admin/Categories/FormPage.vue";

import ysaDashboardPage from "../views/Admin/YSA/DashboardPage.vue";
import ysaStudentListPage from "../views/Admin/YSA/StudentListPage.vue";
import ysaSchoolListPage from "../views/Admin/YSA/SchoolListPage.vue";
import ysaTeacherListPage from "../views/Admin/YSA/teacherListPage.vue";

import MakerCartListPage from "../views/Admin/MakerCart/ListPage.vue";
import MakerCartFormPage from "../views/Admin/MakerCart/FormPage.vue";

import coachListPage from "../views/Admin/Coach/ListPage.vue";

import TermsListPage from "../views/Admin/Term/ListPage.vue";
import TermsFormPage from "../views/Admin/Term/FormPage.vue";

import ScormListPage from "../views/Admin/ScormFiles/ListPage.vue";
import ScormFormPage from "../views/Admin/ScormFiles/FormPage.vue";

import ChangeLogFormPage from "../views/Admin/ChangeLog/FormPage.vue";

import InviteTeacherFormPage from "../views/Admin/InviteTeacher/FormPage.vue";

import RedeemListPage from "../views/Admin/Redeem/ListPage.vue";
import CommentListPage from "../views/Admin/Comments/ListPage.vue";
import UserLogListPage from "../views/Admin/UserLogs/ListPage.vue";
import PostLogListPage from "../views/Admin/PostLogs/ListPage.vue";
import FormSubmissionListPage from "../views/Admin/FormSubmission/ListPage.vue";


import RegisterInvitePage from "../views/RegisterInvitePage.vue";

import NoAuthPage from "../views/NoAuthPage.vue";
import AdminLayout from "@/layouts/AdminLayout.vue"; // Import AdminLayout
import DefaultLayout from "@/layouts/DefaultLayout.vue"; // Import DefaultLayout
import EmptyLayout from "@/layouts/EmptyLayout.vue"; // Import EmptyLayout
import { RouterView } from 'vue-router'; // Import RouterView
import StemStudioService from '@/services/stemStudioService';
import EncryptionService from "@/utils/EncryptionService";

const routes = [
  {
    path: "/login",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "LoginPage",
        component: LoginPage,
        meta: { requiresAuth: false, title: "Login" },
      },
      {
        path: "/verify-account",
        name: "RegisterInvitePage",
        component: RegisterInvitePage,
        meta: { requiresAuth: false, title: "Register Invite Page" },
      }
    ]
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "HomePage",
        component: HomePage,
        meta: { requiresAuth: true, title: "Home" },
      },
      {
        path: "preview",
        name: "PreviewPage",
        component: PreviewPage,
        meta: { requiresAuth: true, page: "preview", title: "Preview" },
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: ProfilePage,
        meta: { requiresAuth: true, title: "Profile" },
      },
      {
        path: "lesson-packages/:id",
        name: "LessonPackagePage",
        component: LessonPackagePage,
        meta: { requiresAuth: true, page: "preview", title: "Lesson Package" },
        props: true,
      },
      {
        path: "edit-packages/:id",
        name: "EditPage",
        component: EditPage,
        meta: { requiresAuth: true, page: "edit", title: "Edit Package" },
      }
    ]
  },
  {
    path: "/print/:id",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "PrintPage",
        component: PrintPage,
        meta: {
          requiresAuth: false,
          header: "hidden",
          footer: "hidden",
          page: "print",
          title: "Print",
        },
      }
    ]
  },
  {
    path: "/admin",
    component: AdminLayout,
    meta: { requiresAuth: true },
    beforeEnter: async () => {
      const allowed = await isAdmin();

      if (!allowed) {
        return { path: "/" };
      }
    },
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardPage,
        meta: { requiresAuth: true, title: "Dashboard Admin" },
      },
      {
        path: 'china-us',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'ChinaUSAdminPage',
            component: ChinaUSAdminPage,
            meta: { requiresAuth: true, title: "China US Admin" },
          },
          {
            path: 'projects/:id',
            name: 'ChinaUSProjectDetailPage',
            component: ChinaUSProjectDetailPage,
            meta: { requiresAuth: true, title: "China US Project Detail" },
          },
        ]
      },
      {
        path: 'lesson-plans',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'LessonPlansListPage',
            component: LessonPlansListPage,
            meta: { requiresAuth: true, title: "Lesson Plan" },
          },
          {
            path: 'form',
            name: 'LessonPlansFormPage',
            component: LessonPlansFormPage,
            meta: { requiresAuth: true, title: "Form Lesson Plan" },
          },
        ]
      },
      {
        path: 'users',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'UsersListPage',
            component: UsersListPage,
            meta: { requiresAuth: true, title: "Users" },
          },
          {
            path: 'form',
            name: 'UsersFormPage',
            component: UsersFormPage,
            meta: { requiresAuth: true, title: "Form Users" },
          },
          {
            path: 'form/:id',
            name: 'UsersEditPage',
            component: UsersFormPage,
            meta: { requiresAuth: true, title: "Edit Users" },
          },
        ]
      },
      {
        path: 'categories',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'CategoriesListPage',
            component: CategoriesListPage,
            meta: { requiresAuth: true, title: "Category" },
          },
          {
            path: 'form',
            name: 'CategoriesFormPage',
            component: CategoriesFormPage,
            meta: { requiresAuth: true, title: "Form Category" },
          },
          {
            path: 'form/:id',
            name: 'CategoriesEditPage',
            component: CategoriesFormPage,
            meta: { requiresAuth: true, title: "Edit Category" },
          },
        ]
      },
      {
        path: 'terms',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: ':id',
            name: 'TermsListPage',
            component: TermsListPage,
            meta: { requiresAuth: true, title: "Term" },
          },
          {
            path: 'form/:submissionId',
            name: 'TermsFormPage',
            component: TermsFormPage,
            meta: { requiresAuth: true, title: "Form Term" },
          },
          {
            path: 'form/:submissionId/:id',
            name: 'TermsEditPage',
            component: TermsFormPage,
            meta: { requiresAuth: true, title: "Edit Term" },
          },
        ]
      },
      {
        path: 'redeem',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'redeemListPage',
            component: RedeemListPage,
            meta: { requiresAuth: true, title: "Redeem" },
          },
        ]
      },
      {
        path: 'comment',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'commentListPage',
            component: CommentListPage,
            meta: { requiresAuth: true, title: "Comment" },
          },
        ]
      },
      {
        path: 'userlog',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'userLogListPage',
            component: UserLogListPage,
            meta: { requiresAuth: true, title: "User Log" },
          },
        ]
      },
      {
        path: 'contentLog',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'contentLogListPage',
            component: PostLogListPage,
            meta: { requiresAuth: true, title: "Content Log" },
          },
        ]
      },
      {
        path: 'formSubmission',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'formSubmissionListPage',
            component: FormSubmissionListPage,
            meta: { requiresAuth: true, title: "Form Submission" },
          },
        ]
      },
      {
        path: 'ysa',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          // ysaDashboardPage
          {
            path: 'dashboard',
            name: 'ysaDashboardPage',
            component: ysaDashboardPage,
            meta: { requiresAuth: true, title: "Dashboard Page" },
          },
          {
            path: 'ysaTeacherListPage',
            name: 'ysaTeacherListPage',
            component: ysaTeacherListPage,
            meta: { requiresAuth: true, title: "Teacher List Page" },
          },
          {
            path: 'ysaStudentListPage',
            name: 'ysaStudentListPage',
            component: ysaStudentListPage,
            meta: { requiresAuth: true, title: "Student List Page" },
          }, //SchoolListPage.vue
          {
            path: 'ysaSchoolListPage',
            name: 'ysaSchoolListPage',
            component: ysaSchoolListPage,
            meta: { requiresAuth: true, title: "School List Page" },
          },
        ]
      },
      {
        path: 'coach',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'coachListPage',
            component: coachListPage,
            meta: { requiresAuth: true, title: "Coach Page" },
          },
        ]
      },
      {
        path: 'scorm',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'ScormListPage',
            component: ScormListPage,
            meta: { requiresAuth: true, title: "Category" },
          },
          {
            path: 'form',
            name: 'ScormCreatePage',
            component: ScormFormPage,
            meta: { requiresAuth: true, title: "Form Category" },
          },
          {
            path: 'form/:id',
            name: 'ScormEditPage',
            component: ScormFormPage,
            meta: { requiresAuth: true, title: "Edit Category" },
          },
        ]
      },
      {
        path: 'change-log',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'ChangeLogFormPage',
            component: ChangeLogFormPage,
            meta: { requiresAuth: true, title: "Form Change Log" },
          },
        ]
      },
      {
        path: 'invite-teacher',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'InviteTeacherFormPage',
            component: InviteTeacherFormPage,
            meta: { requiresAuth: true, title: "Form Invite Teacher" },
          },
        ]
      },
      {
        path: 'maker-cart',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'MakerCartListPage',
            component: MakerCartListPage,
            meta: { requiresAuth: true, title: "Stem Order Page" },
          },
          {
            path: 'details/:id',
            name: 'MakerCartFormPage',
            component: MakerCartFormPage,
            meta: { requiresAuth: true, title: "Stem Order Details Page" },
          },
        ]
      },
    ]
  },
  {
    path: "/china-us",
    component: DefaultLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: "ChinaUSPage",
        component: ChinaUSPage,
        meta: { requiresAuth: false, title: "China US" },
      },
      {
        path: 'faq',
        name: 'ChinaUSFAQPage',
        component: FAQPage,
        meta: { requiresAuth: false, title: "FAQ" },
      },
      {
        path: 'competition-details',
        name: 'CompetitionDetailsPage',
        component: CompetitionDetailsPage,
        meta: { requiresAuth: false, title: "Competition Details" },
      },
      {
        path: 'discussion',
        name: 'DiscussionPage',
        component: DiscussionPage,
        meta: { requiresAuth: true, title: "Discussion" },
      },
      {
        path: 'discussion/:id',
        name: 'DiscussionDetailsPage',
        component: DiscussionDetailsPage,
        meta: { requiresAuth: true, title: "Discussion Details" },
      },
      {
        path: 'submission',
        name: 'SubmissionPage',
        component: SubmissionPage,
        meta: { requiresAuth: false, title: "Submission" },
      },
      {
        path: 'submission-submit',
        name: 'SubmissionSubmitPage',
        component: SubmissionSubmitPage,
        meta: { requiresAuth: true, title: "Submit Submission" },
      },
      {
        path: 'submission-list',
        name: 'SubmissionListPage',
        component: SubmissionListPage,
        meta: { requiresAuth: false, title: "Submission List" },
      },
      {
        path: 'submission-detail/:id',
        name: 'SubmissionDetailPage',
        component: SubmissionDetailPage,
        meta: { requiresAuth: false, title: "Submission Detail" },
      },
      {
        path: 'submission-review/:id',
        name: 'SubmissionReviewPage',
        component: SubmissionReviewPage,
        meta: { requiresAuth: false, title: "Submission Review" },
      }
    ]
  },
  {
    path: "/makercart-studio",
    component: DefaultLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: "MakerCartPage",
        component: MakerCartPage,
        meta: { requiresAuth: false, title: "Makercart" },
      }
    ]
  },
  {
    path: "/makercart-guide",
    component: DefaultLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: "MakerCartGuidePage",
        component: MakerCartGuidePage,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '1',
        name: "MakerCartGuide1Page",
        component: MakerCartGuide1Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '2',
        name: "MakerCartGuide2Page",
        component: MakerCartGuide2Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '3',
        name: "MakerCartGuide3Page",
        component: MakerCartGuide3Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '4',
        name: "MakerCartGuide4Page",
        component: MakerCartGuide4Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '5',
        name: "MakerCartGuide5Page",
        component: MakerCartGuide5Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '6',
        name: "MakerCartGuide6Page",
        component: MakerCartGuide6Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '7',
        name: "MakerCartGuide7Page",
        component: MakerCartGuide7Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '8',
        name: "MakerCartGuide8Page",
        component: MakerCartGuide8Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '9',
        name: "MakerCartGuide9Page",
        component: MakerCartGuide9Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '10',
        name: "MakerCartGuide10Page",
        component: MakerCartGuide10Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
      {
        path: '11',
        name: "MakerCartGuide11Page",
        component: MakerCartGuide11Page,
        meta: { requiresAuth: false, title: "Makercart Guide", requiresEducatorsGuide: true },
      },
    ]
  },
  {
    path: "/403",
    name: "NoAuthPage",
    component: NoAuthPage,
    meta: { requiresAuth: false, title: "No Authorization" },
  },
  // ... add another route here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line
async function checkEducatorsGuide() {
  try {
    const response = await StemStudioService.checkEducatorsGuide();
    if (response.status === 200) {
      return response.data.data.showEducatorsGuide;
    } else {
      return false;
    }
  } catch (err) {
    console.error('Error getting Educators Guide status:', err.message);
    return false;
  }
}

let tokenValidationTimeout;

async function validateTokenPeriodically() {
  clearTimeout(tokenValidationTimeout);

  try {
    const token = await cookiesService.getCookie("authToken");
    if (!token) {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
      return;
    }

    const validationResponse = await AuthService.validateToken(token.token);

    if (!validationResponse) {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
    } else {
      tokenValidationTimeout = setTimeout(validateTokenPeriodically, 30000);
    }
  } catch (error) {
    console.error("Error during token validation:", error);
  }
}

router.beforeEach(async (to, from, next) => {
  const authToken = await cookiesService.getCookie("authToken");

  if (to.name === "SubmissionSubmitPage") {
    const ymc_is_open = true;

    if (!ymc_is_open) {
      next({ name: "SubmissionPage" });
    }
  }

  if (to.meta.requiresAuth && !authToken) {
    await checkAuthentication(next(), authToken);
  } else {
    if (to.name === 'SubmissionPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if (to.name === 'MakerCartPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if (to.name === 'SubmissionListPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if(to.name === 'SubmissionSubmitPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    }

    if (to.meta.requiresEducatorsGuide) {
      const isGuideAvailable = await checkEducatorsGuide();
      if (!isGuideAvailable) {
        const encryptedMessage = EncryptionService.encrypt("You need to have an makercart order confirmed to access this page");
        return next({
          name: "HomePage",
          query: { message: encryptedMessage }
        });
      }
    }    

    next();
  }
});

// eslint-disable-next-line 
async function checkAuthentication(next, authToken) {
  if (window.location.search) {
    const url = new URL(window.location);

    if (url.searchParams.has("token")) {
      const token = url.searchParams.get("token");
      try {
        const result = await AuthService.validateToken(token);
        if (result.data) {
          result.data.token = token;
          const actualToken = result.data;
          await cookiesService.setCookie("authToken", actualToken, {
            /* options */
          });
          next();
        } else {
          window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
        }
      } catch (error) {
        console.error("Terjadi kesalahan saat validasi token:", error);
      }
    } else if (url.searchParams.has("key")) {
      // eslint-disable-next-line
    } else {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
    }

    url.search = "";
    window.history.pushState({}, "", url);

    if (typeof tokenValidationTimeout === 'undefined') {
      validateTokenPeriodically();
    }
  }
}

router.afterEach((to) => {
  const defaultTitle = "Builder - the Educator's Buddy";
  document.title = `${to.meta.title} - the Educator's Buddy` || defaultTitle;

  const url = new URL(window.location.href);
  const encryptedMessage = url.searchParams.get("message");

  if (encryptedMessage && EncryptionService.isValidEncryptedText(encryptedMessage)) {
    try {
      const decryptedMessage = EncryptionService.decrypt(encryptedMessage);
      alert(decryptedMessage);
      url.searchParams.delete("message");
      window.history.replaceState({}, "", url);
    } catch (error) {
      console.error("Failed to decrypt message:", error);
    }
  }
});

export default router;

