// src/services/CommentAdminService.js

import http from "./httpService";

class UserLogAdminService {
  async getAll(params) {
    const url = http.getUrlWithParams("/admin/useractivity", params);
    return await http.get(url);
  }
}

export default new UserLogAdminService();
