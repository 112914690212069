// src/services/redeemAdminService.js

import http from "./httpService";

class redeemAdminService {
  async getAll(params) {
    const url = http.getUrlWithParams("/admin/redeem", params);
    return await http.get(url);
  }
}

export default new redeemAdminService();
