import axios from 'axios';

const httpExternal = axios.create({
  baseURL: '',
  headers: {
    'Content-type': 'application/json',
  },
});

// Tambahkan metode untuk membangun URL dengan query params
httpExternal.getUrlWithParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }
  
  const queryString = Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
  
  return `${url}?${queryString}`;
};

export default httpExternal;
